'use strict';
import React, { useEffect, useState } from 'react';
import AppState from '../../state';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { Utils } from '../../services';

const hourList = [
  { display: '8:00', value: 8 },
  { display: '7:30', value: 7.5 },
  { display: '7:00', value: 7 },
  { display: '6:30', value: 6.5 },
  { display: '6:00', value: 6 },
  { display: '5:30', value: 5.5 },
  { display: '5:00', value: 5 },
  { display: '4:30', value: 4.5 },
  { display: '4:00', value: 4 },
  { display: '3:30', value: 3.5 },
  { display: '3:00', value: 3 },
  { display: '2:30', value: 2.5 },
  { display: '2:00', value: 2 },
  { display: '1:30', value: 1.5 },
  { display: '1:00', value: 1 },
  { display: '0:30', value: 0.5 },
];
const useTimeSheets = () => {
  const todayDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .slice(0, 10);

  const [projectList, setProjectList] = useState([]);
  const [timesheet, setTimesheet] = useState([]);
  const [userList, setUserList] = useState([]);
  const [missingHours, setMissingHours] = useState(0);
  const [hours, setHours] = useState('');
  const [missingHourList, setMissingHourList] = useState([]);
  const [project, setProject] = useState('');
  const [professional, setProfessional] = useState(
    AppState.userInfo.cpf_usuario
  );
  const [isMasterUser, setIsMasterUser] = useState(false);
  const [isCoordinatorUser, setIsCoordinatorUser] = useState(false);
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [professionalError, setProfessionalError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [projectError, setProjectError] = useState(false);
  const [hoursErro, setHoursErro] = useState(false);

  useEffect(() => {
    getCoordinatorUsers();
  }, []);

  const getPermission = () => {
    const permission = JSON.parse(localStorage.getItem('Menu'));

    const func = permission.views.filter(
      (item) => item.id_view === 'timesheet'
    );

    let result = func[0].funcionalidades.find(function (item) {
      return item.id_funcionalidade === 'editar_timesheet_todos';
    });
    setIsMasterUser(!!result?.id_funcionalidade ? true : isCoordinatorUser);
  };

  const setUser = () => {
    var temp = userList.find((x) => x.value === AppState.userInfo.cpf_usuario);
    setProfessional(temp.value);
  };

  const loadScreen = (is_coordenador) => {
    if (!is_coordenador) {
      getUsers();
    }
    getMissingTime();
    getProjetos();
    getTableData();
    getPermission();
    setUser();
  };

  const getUsers = () => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }
    fetch(
      `${AppState.serverAddr}/usuarios/GetUsersList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        const users = response.request.map((suggestion) => ({
          value: suggestion.cpf_usuario,
          label: suggestion.nome_usuario,
        }));
        setUserList(users);
        AppState.isLoading = false;
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const getProjectByCoordinator = (professional) => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }
    fetch(
      `${AppState.serverAddr}/projetos/GetProjectsByCoodinator.php?json={"coordenador":"${AppState.userInfo.cpf_usuario}", "usuario":"${professional}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        setProjectList(response.request);
        AppState.isLoading = false;
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const getCoordinatorUsers = () => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }
    fetch(
      `${AppState.serverAddr}/projetos/GetUserByCoordinator.php?json={"coordenador":"${AppState.userInfo.cpf_usuario}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          AppState.isLoading = false;
          return res;
        }
      })
      .then((response) => {
        const users = response.request.map((suggestion) => ({
          value: suggestion.cpf_usuario,
          label: suggestion.nome_usuario,
        }));
        setUserList(users);

        const is_coordenador = users.length > 1;
        setIsCoordinatorUser(is_coordenador);

        loadScreen(is_coordenador);
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const getProjetos = (_professional) => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }
    fetch(
      `${AppState.serverAddr}/projetos/GetUserProjects.php?json={"usuario":"${_professional || professional}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        if (response.request.length === 1) {
          setProject(response.request[0].id_projeto);
        }
        setProjectList(response.request);

        AppState.isLoading = false;
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const getMissingTime = (_professional, _isCoordinatorUser) => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }

    const { id_perfil } = JSON.parse(localStorage.getItem('userInfo'));
    fetch(
      `${AppState.serverAddr}/timesheet/GetMissingTime.php?json={"usuario":"${_professional || professional}", "data":"${selectedDate}", "idPerfil":"${id_perfil}", "isCoordinatorUser":"${_isCoordinatorUser}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {

        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setMissingHours(data.request[0].falta_apontar);
        renderHoursCollection(data.request[0].falta_apontar);
        AppState.isLoading = false;
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const getTableData = () => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }
    fetch(
      `${AppState.serverAddr}/timesheet/GetTimesheet.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        setTimesheet(response.request);
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const saveOnClick = () => {
    AppState.isLoading = true;

    //Pega data apontada e a hora atual(isso é necessário pois a data compõe a PK da tabela Timesheet)
    const date = Utils.dateToEN(selectedDate);
    const hour = moment().format().split('T')[1].split('-', 1);

    //monta a data para o lançamento
    const dataLancamento = date + ' ' + hour;

    const data = JSON.stringify({
      qtd_horas: hours,
      data: dataLancamento,
      id_projeto: project,
      usuario: professional,
      is_coordenador: isCoordinatorUser,
    });

    fetch(
      `${AppState.serverAddr}/timesheet/AddAppointment.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        AppState.isLoading = false;
        return res;
      })
      .then((response) => {
        getTableData();
        getMissingTime();
        clearFields();
        AppState.isLoading = false;
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40002') {
            console.log(error);

            console.log('40002 Horas apontadas maior que o permitido');
            alert('As horas apontadas são maiores que o permitido');
          } else if (ret === '40003') {
            console.log('40003 Usuario não habiltiado para apontar horas');
            alert('Usuário não habilitado para o apontamento de horas.');
          } else if (ret === '40005') {
            console.log(
              '40005 Usuario não habiltiado para apontar horas para terceiros'
            );
            alert('Usuário não habilitado para apontar horas para terceiros.');
          } else if (ret === '40006') {
            console.log(
              '40006 O usuário não pode lançar apontamentos no fim de semana.'
            );
            alert('O usuário não pode lançar apontamentos no fim de semana.');
          } else if (ret === '40007') {
            console.log(
              '40007 Usuário não pode lançar apontamentos no futuro.'
            );
            alert('40007 Usuário não pode lançar apontamentos no futuro.');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const deleteAppointment = (idProjeto, usuario, dataApontamento) => {
    if (AppState.isLoading === false) {
      AppState.isLoading = true;
    }

    const data = JSON.stringify({
      id_projeto: idProjeto,
      cpf_usuario: usuario,
      timestamp: dataApontamento,
    });

    fetch(
      `${AppState.serverAddr}/timesheet/DeleteAppointment.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        getTableData();
        getMissingTime();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002' || ret === '40004') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log(
              '40003 - Não é possivel deletar apontamentos anteriores à data atual'
            );
            alert(
              'Não é permitido deletar apontamentos anteriores à data atual.'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  const professionalHandleChange = (value) => {
    if (value === null) {
      setProfessional('');
    } else {
      setProjectList([]);
      setProfessional(value.value);
      //Caso usuário logado seja Coordenador e profissional selecionado diferente do coordenador logado.
      if (isCoordinatorUser && value.value != AppState.userInfo.cpf_usuario) {
        //Pegar os projetos em que o usuário logado é coordenador
        getProjectByCoordinator(value.value);
        //Pegar Horas restantes à apontar
        getMissingTime(value.value, isCoordinatorUser);
      } else {
        getProjetos(value.value);
        getMissingTime(value.value);
      }
    }
  };

  const renderHoursCollection = (missingHours) => {
    let retorno = [];

    hourList.forEach((item) => {
      if (item.value <= missingHours) {
        retorno.push({ display: item.display, value: item.value });
      }
    });

    setMissingHourList(retorno);
  };

  const validaFields = () => {
    const isProfessionalValid = () => {
      if (professional === '') {
        setProfessionalError(true);
        return false;
      }
      setProfessionalError(false);
      return true;
    };

    const isSelectedDateValid = () => {
      if (selectedDate === '') {
        setDateError(true);
        return false;
      }

      const data = moment(Utils.dateToEN(selectedDate));
      if (!data.isValid()) {
        setDateError(true);
        return false;
      }

      // Se a data contém um hífen, faça a verificação de mês
      if (selectedDate.includes('-')) {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // January é 0, então somamos 1
        const selectedMonth = parseInt(selectedDate.split('-')[1], 10);

        if (currentMonth !== selectedMonth) {
          setDateError(true);
          return false;
        }
      }

      setDateError(false);
      return true;
    };

    const isProjectValid = () => {
      if (project === '') {
        setProjectError(true);
        return false;
      }
      setProjectError(false);
      return true;
    };

    const isHoursValid = () => {
      if (hours === '') {
        setHoursErro(true);
        return false;
      }
      setHoursErro(false);
      return true;
    };

    // Validação de todos os campos
    const formValido =
      isProfessionalValid() &&
      isSelectedDateValid() &&
      isProjectValid() &&
      isHoursValid();

    // Se o formulário estiver válido, execute o salvamento
    if (formValido) {
      saveOnClick();
    }
  };


  const clearFields = () => {
    setHours('');
    setProject('');
    setProfessionalError(false);
    setDateError(false);
    setProjectError(false);
    setHoursErro(false);
  };

  return {
    missingHours,
    isMasterUser,
    isCoordinatorUser,
    userList,
    professionalHandleChange,
    professionalError,
    professional,
    loadScreen,
    selectedDate,
    dateError,
    setSelectedDate,
    getMissingTime,
    project,
    projectError,
    projectList,
    hours,
    hoursErro,
    missingHourList,
    validaFields,
    timesheet,
    deleteAppointment,
    setProject,
    setHours,
  };
};

export default useTimeSheets;
