import React from "react";
import AppState from "../../../state";
import moment from "moment";
import { Utils } from "../../../services";
import { browserHistory } from "react-router";

import { get_indicators_data, safe_division } from "../../../api/dashboard";
import Tooltip from "../../../components/lib/Tooltip";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from "@material-ui/core/";

import { VictoryTooltip,
  VictoryLine,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLegend,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryStack
} from "victory";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
  "Jul", "Ago", "Set", "Out", "Nov", "Dez"
];

const chartColors = ["#264478", "#c00000", "#ff0000", "#fefb85", "#294de7"]
const chartKPIs = ["BDI", "MC", "TDD", "TDI", "Resultado"]

class GraficoProjetos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: 500,
      fin_chart_data: [[], [], [], [], []],
      eco_chart_data: [[], [], [], [], []],
      fin_maxima: [0, 0, 0, 0, 0],
      eco_maxima: [0, 0, 0, 0, 0],
      fin_minima: [0, 0, 0, 0, 0],
      eco_minima: [0, 0, 0, 0, 0],
      projects: [],
      selectedProject: "todos",
      dt_inicial: moment().subtract(12, 'months').format('YYYY-MM'),
      dt_final: moment().subtract(1, 'months').format('YYYY-MM'),
      chart_filter: chartKPIs
    };

  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.getChartData()
    this.getProjects()
  }

  getChartData = async (updateProjectDate = true) => {
    if (updateProjectDate) {
      await this.getProjectData();
    }

    AppState.isLoading = true;
    try {
      console.time('Extração de Dados')
      let res = await get_indicators_data(this.state.dt_inicial, this.state.dt_final, true, 'detalhado', true,
                                          this.state.selectedProject == "todos" ? null : this.state.selectedProject);
      console.timeEnd('Extração de Dados')
      console.log(res);
      let fin_arr = [res.financeiro.map((el) => ({'x': el.dt_fim, 'y': el.receitaTdd})),
                res.financeiro.map((el) => ({'x': el.dt_fim, 'y': el.mc})),
                res.financeiro.map((el) => ({'x': el.dt_fim, 'y': el.tdd})),
                res.financeiro.map((el) => ({'x': el.dt_fim, 'y': el.tdi})),
                res.financeiro.map((el) => ({'x': el.dt_fim, 'y': el.resultado})),
                res.financeiro.map((el) => ({'x': el.dt_fim, 'y': el.receita}))]
      let eco_arr = [res.economico.map((el) => ({'x': el.dt_fim, 'y': el.receitaTdd})),
                res.economico.map((el) => ({'x': el.dt_fim, 'y': el.mc})),
                res.economico.map((el) => ({'x': el.dt_fim, 'y': el.tdd})),
                res.economico.map((el) => ({'x': el.dt_fim, 'y': el.tdi})),
                res.economico.map((el) => ({'x': el.dt_fim, 'y': el.resultado})),
                res.economico.map((el) => ({'x': el.dt_fim, 'y': el.receita}))]
      this.setState({
        fin_chart_data: fin_arr,
        eco_chart_data: eco_arr,
        fin_maxima: fin_arr.map((dataset) => Math.max(...dataset.map((d) => d.y))),
        eco_maxima: eco_arr.map((dataset) => Math.max(...dataset.map((d) => d.y))),
        fin_minima: fin_arr.map((dataset) => Math.min(...dataset.map((d) => d.y))),
        eco_minima: eco_arr.map((dataset) => Math.min(...dataset.map((d) => d.y)))
      }, () => {
        AppState.isLoading = false;
      })
    } catch (err){
      alert('Erro na atualização dos dados');
      console.log(err);
      AppState.isLoading = false;
    }
  }

  getProjects = () => {
    return fetch(
      `${AppState.serverAddr}/projetos/GetProjectList.php`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (response.status === 401) {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          console.log('Unauthorized');
          return;
        }
        const res = response.json();
        return res;
      })
      .then(response => {
        this.setState({
          projects: response.request.filter((el) => el.id_projeto != '0')
        })
      })
      .catch(function(error) {
        alert('Erro na atualização dos dados');
        console.log(error);
        AppState.isLoading = false;
      });
  };

  getProjectData = () => {
    if (this.state.selectedProject == "todos") {
      this.setState({
        dt_inicial: moment().subtract(12, 'months').format('YYYY-MM')
      })
    } else {
      const json = JSON.stringify({id_projeto: this.state.selectedProject});

      return fetch(
        `${AppState.serverAddr}/projetos/GetProject.php?json=${json}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: AppState.token
          }
        }
      )
        .then(response => {
          if (response.status === 401) {
            AppState.isLoading = false;
            browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            console.log('Unauthorized');
            return;
          }
          const res = response.json();
          return res;
        })
        .then(response => {
          this.setState({
            dt_inicial: moment(response.request[0].dt_inicio).format('YYYY-MM')
          })
        })
        .catch(function(error) {
          alert('Erro na atualização dos dados');
          console.log(error);
        });
    }
  };

  getProjectData2 = () => {
    if (this.state.selectedProject == "todos") {
      this.setState({
        dt_inicial: moment().subtract(12, 'months').format('YYYY-MM')
      })
    } else {
      const json = JSON.stringify({id_projeto: this.state.selectedProject});

      return fetch(
          `${AppState.serverAddr}/projetos/GetProject.php?json=${json}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: AppState.token
            }
          }
      )
          .then(response => {
            if (response.status === 401) {
              AppState.isLoading = false;
              browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
              console.log('Unauthorized');
              return;
            }
            const res = response.json();
            return res;
          })
          .then(response => {

          })
          .catch(function(error) {
            alert('Erro na atualização dos dados');
            console.log(error);
          });
    }
  };

  projectHandleChange = event => {
    this.setState({
      selectedProject: event.target.value,
      fin_chart_data: [[], [], [], [], []],
      eco_chart_data: [[], [], [], [], []]
    }, () => {
      this.getChartData(true);
    })
  }

  dateHandleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      fin_chart_data: [[], [], [], [], []],
      eco_chart_data: [[], [], [], [], []]
    }, () => {
      this.getProjectData2(false);
    })
  }

  getBarChartMaxValue = (maxima, minima) => {
    let max_val = Math.max(0
                  , maxima[1], Math.abs(minima[1])
                  , maxima[2] + maxima[3] + maxima[4]
                  , Math.abs(minima[2]) + Math.abs(minima[3]) + Math.abs(minima[4]))
    if (max_val) {
      return max_val
    }
    return 0
  }

  renderChart = (chart_data, maxima, minima, title) => {
    return (
      <Grid item sm={5} style={{textAlign:"center"}}>
      <h3>{title}</h3>
        <VictoryChart
          animate={{duration: this.state.animation}}
          domainPadding={{x: [20, 20]}}
          padding={{left: 80, right: 50, top: 50, bottom: 30}}
          theme={VictoryTheme.material}
          containerComponent={
              <VictoryVoronoiContainer
              labelComponent={<VictoryTooltip flyoutComponent={<Tooltip/>} />}
              labels={() => " "}
           />
          }
        >
          {this.renderLegend()}
          <VictoryAxis
            style={{
              tickLabels: {padding: 30, angle: 90}
            }}
          />
          <VictoryAxis dependentAxis
            key={0}
            orientation="left"
            tickValues={[-0.5, 0, 0.5, 1]}
            tickFormat={(t) => {
              let max_val = this.getBarChartMaxValue(maxima, minima)
              return Math.round(max_val * t / 100000)/10 + `MM`
            }}
            />
          <VictoryAxis dependentAxis
            key={1}
            orientation="right"
            tickValues={[-0.5, 0, 0.5, 1]}
            tickFormat={(t) => {
              let max_val = Math.max(maxima[0], Math.abs(minima[0]))
              return Math.round(max_val * t)  + `%`
            }}
          />
            <VictoryStack>
            {this.state.chart_filter.includes(chartKPIs[2]) &&
            <VictoryBar
              key={2}
              data={chart_data[2].map(val => {
                return {
                  'x': moment(val.x).format('MM-YYYY'),
                  'y': val.y,
                  'yLabel': `R$${Utils.float2moeda(val.y).slice(0, -3)}`,
                  'subtitle': chartKPIs[2] + '\n' + monthNames[parseInt(moment(val.x).format('MM'))-1] + moment(val.x).format('/YYYY')
                }})}
                y={(datum) => {
                  let max_val = this.getBarChartMaxValue(maxima, minima)
                  return safe_division(datum.y, max_val)
                }}
                style={{ data: { fill: chartColors[2] } }}
            />
            }
            {this.state.chart_filter.includes(chartKPIs[3]) &&
            <VictoryBar
              key={3}
              data={chart_data[3].map(val => {
                return {
                  'x': moment(val.x).format('MM-YYYY'),
                  'y': val.y,
                  'yLabel': `R$${Utils.float2moeda(val.y).slice(0, -3)}`,
                  'subtitle': chartKPIs[3] + '\n' + monthNames[parseInt(moment(val.x).format('MM'))-1] + moment(val.x).format('/YYYY')
                }})}
                y={(datum) => {
                  let max_val = this.getBarChartMaxValue(maxima, minima)
                  return safe_division(datum.y, max_val)
                }}
                style={{ data: { fill: chartColors[3] } }}
            />
            }
            {this.state.chart_filter.includes(chartKPIs[4]) &&
            <VictoryBar
              key={4}
              data={chart_data[4].map(val => {
                return {
                  'x': moment(val.x).format('MM-YYYY'),
                  'y': val.y,
                  'yLabel': `R$${Utils.float2moeda(val.y).slice(0, -3)}`,
                  'subtitle': chartKPIs[4] + '\n' + monthNames[parseInt(moment(val.x).format('MM'))-1] + moment(val.x).format('/YYYY')
                }})}
                y={(datum) => {
                  let max_val = this.getBarChartMaxValue(maxima, minima)
                  return safe_division(datum.y, max_val)
                }}
                style={{ data: { fill: chartColors[4] } }}
            />
            }
          </VictoryStack>
          {this.state.chart_filter.includes(chartKPIs[0]) &&
          <VictoryLine
            key={0}
            data={chart_data[0].map(val => {
              return {
                'x': moment(val.x).format('MM-YYYY'),
                'y': val.y,
                'yLabel': `${Math.round(val.y)}%`,
                'subtitle': chartKPIs[0] + '\n' + monthNames[parseInt(moment(val.x).format('MM'))-1] + moment(val.x).format('/YYYY')
              }})}
              style={{ data: { stroke: chartColors[0] } }}
              y={(datum) => {
                let max_val = Math.max(maxima[0], Math.abs(minima[0]))
                return safe_division(datum.y, max_val)
              }}
          />
          }
          {this.state.chart_filter.includes(chartKPIs[1]) &&
          <VictoryLine
            key={1}
            data={chart_data[1].map(val => {
              return {
                'x': moment(val.x).format('MM-YYYY'),
                'y': val.y,
                'yLabel': `R$${Utils.float2moeda(val.y).slice(0, -3)}`,
                'subtitle': chartKPIs[1] + '\n' + monthNames[parseInt(moment(val.x).format('MM'))-1] + moment(val.x).format('/YYYY')
              }})}
              style={{ data: { stroke: chartColors[1] } }}
              y={(datum) => {
                let max_val = this.getBarChartMaxValue(maxima, minima)
                return safe_division(datum.y, max_val)
              }}
          />
          }
        </VictoryChart>
      </Grid>
    )
  }

  renderLegend = () => {
    return (
    <VictoryLegend
      orientation="horizontal"
      gutter={20}
      data={chartKPIs.map((val) => ({'name': val}))}
      colorScale={chartColors}
      events={[{
        target: "data",
        eventHandlers: {
          onClick: () => {
            return [
              {
                target: "data",
                mutation: (props) => {
                  let option, new_filter
                  option = props.datum.name;
                  if (this.state.chart_filter.includes(option)) {
                    new_filter = this.state.chart_filter.filter(item => item !== option)
                  } else {
                    new_filter = [...this.state.chart_filter, option]
                  }
                  this.setState({
                    chart_filter: new_filter
                  })
                }
              }
            ];
          }
        }
      }]}
    />)
  }

  preparaDado = dados => {
    if (dados == null || dados[0].length < 1) return [];
    let ret = dados[0].slice(0);
    for (let i = 0; i < dados[0].length; i++) {
      ret[i].data = moment(dados[0][i].x).format('MM-YYYY');
      ret[i].bdi = Utils.float2moeda(dados[0][i].y) + '%';
      ret[i].mc = Utils.float2moeda(dados[1][i].y);
      ret[i].tdd = Utils.float2moeda(dados[2][i].y);
      ret[i].tdi = Utils.float2moeda(dados[3][i].y);
      ret[i].resultado = Utils.float2moeda(dados[4][i].y);
      ret[i].receita = Utils.float2moeda(dados[5][i].y);
      ret[i].despesa_total = Utils.float2moeda(dados[2][i].y + dados[3][i].y);
    }

    return ret;
  };

  render() {
    return (
      <Grid id={this.constructor.name} style={{ marginTop: "4em" }}>
        <Grid container alignItems={"center"}>
          <Grid item sm={5}>
            <h1>Gráficos Por Projeto</h1>
          </Grid>
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
              <Grid
                container
                spacing={32}
                style={{ marginTop: "15px" }}
                justify={'flex-start'}
              >
                <Grid item>
                  <FormControl>
                    <InputLabel>Projeto</InputLabel>
                    <Select
                      style={{ marginBottom: "14px", minWidth: "156px" }}
                      name={"selectedProject"}
                      value={this.state.selectedProject}
                      onChange={this.projectHandleChange}
                    >
                      <MenuItem key={0} value="todos">
                        Todos
                      </MenuItem>)
                      {this.state.projects.map((el, index) => {
                      return (
                        <MenuItem key={index+1} value={el.id_projeto}>
                          {el.display}
                        </MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl
                    component="fieldset"
                    style={{ marginBottom: "14px", minWidth: "156px" }}
                  >
                    <TextField
                      style={{
                        margin: "0px 16px 8px 32px",
                      }}
                      label="Data Inicial"
                      type="month"
                      name="dt_inicial"
                      value={this.state.dt_inicial}
                      onChange={this.dateHandleChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    style={{ marginBottom: "14px", minWidth: "156px" }}
                  >
                    <TextField
                      style={{
                        margin: "0px 16px 8px 32px",
                      }}
                      label="Data Final"
                      type="month"
                      name="dt_final"
                      value={this.state.dt_final}
                      onChange={this.dateHandleChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{'alignSelf': 'center'}}>
              <Grid container justify="flex-end">
                <ExcelFile
                  filename={"RelatorioProjeto-" + this.state.selectedProject + '-' + Utils.getTodayDate()}
                  element={
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.exportarExcel}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24"
                        height="24"
                        viewBox="0 0 192 192"
                        style={{ fill: "#000000", paddingRight: "6px" }}
                      >
                        <g
                          fill="none"
                          fillRule="nonzero"
                          stroke="none"
                          strokeWidth="1"
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          strokeMiterlimit="10"
                          strokeDasharray=""
                          strokeDashoffset="0"
                          fontFamily="none"
                          fontWeight="none"
                          fontSize="none"
                          textAnchor="none"
                          style={{ mixBlendMode: "normal" }}
                        >
                          <path d="M0,192v-192h192v192z" fill="none"></path>
                          <g fill="#ffffff">
                            <g id="surface1">
                              <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Exportar
                    </Button>
                  }
                >
                  <ExcelSheet
                    data={() => this.preparaDado(this.state.fin_chart_data)}
                    name="Financeiro"
                  >
                    <ExcelColumn
                      label="Mês/Ano"
                      value="data"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="Receita"
                      value="receita"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="Resultado"
                      value="resultado"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="TDD"
                      value="tdd"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="TDI"
                      value="tdi"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="MC"
                      value="mc"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="Despesa Total"
                      value="despesa_total"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="BDI"
                      value="bdi"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                  </ExcelSheet>
                  <ExcelSheet
                    data={() => this.preparaDado(this.state.eco_chart_data)}
                    name="Economico"
                  >
                    <ExcelColumn
                      label="Mês/Ano"
                      value="data"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="Receita"
                      value="receita"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="Resultado"
                      value="resultado"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="TDD"
                      value="tdd"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="TDI"
                      value="tdi"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="MC"
                      value="mc"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="Despesa Total"
                      value="despesa_total"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                    <ExcelColumn
                      label="BDI"
                      value="bdi"
                      style={{ font: { sz: "24", bold: true } }}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </Grid>
            </Grid>
          </Grid>
            <Grid container>
              <Grid
                container
                justify="space-between"
                style={{ marginTop: "16px", marginBottom: "8px" }}
              >
                <Grid container justify="space-around" spacing={8}>
                  {this.renderChart(this.state.fin_chart_data, this.state.fin_maxima, this.state.fin_minima, 'Financeiro')}
                  {this.renderChart(this.state.eco_chart_data, this.state.eco_maxima, this.state.eco_minima, 'Economico')}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    );
  }
}

export default GraficoProjetos;
