import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  Chip,
} from '@material-ui/core';
import AppState from '../../state';
import { browserHistory } from 'react-router';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CPFMaskCustomEdit = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\w/,
        /\w/,
        /\w/,
        /\w/,
        /\w/,
      ]}
      guide={false}
    />
  );
};

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TelMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

TelMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edicao: false,
      usuario: [],
      cpf_usuario: '',
      cpf_usuarioErro: false,
      cpf_selecionadoErro: false,
      cpf_selecionado: '',
      nome: '',
      nomeErro: false,
      email: '',
      emailErro: false,
      celular: '',
      tipo: '',
      tipoErro: false,
      perfil: '',
      perfilErro: false,
      perfis: [],
      exclusivo: '',
      projectList: [],
      project: '',
      projetos: [],
    };
  }

  componentDidMount() {
    this.getPerfis();
    this.getProjetos();
  }

  _getUser = (cpf) => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/usuarios/GetUser.php?json={"cpf":"${cpf}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;

        let projetos = response.request.projetos;
        let project = '';
        let projectList = [];

        if (projetos.length === 1) {
          project = projetos[0].id_projeto;
          projetos = [];
        } else {
          projectList = this.state.projectList.filter((item) => {
            if (
              projetos.find((projeto) => projeto.id_projeto === item.id_projeto)
            ) {
              return true;
            }
            return false;
          });
        }

        this.setState({
          cpf_usuario: response.request[0].cpf_usuario,
          nome: response.request[0].nome_usuario,
          email: response.request[0].email_usuario,
          tipo: response.request[0].tipo,
          perfil: response.request[0].id_perfil,
          celular: response.request[0].celular,
          exclusivo: response.request.projetos.length > 1 ? 'false' : 'true',
          projetos: projectList,
          project,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPerfis = () => {
    fetch(
      `${AppState.serverAddr}/comum/GetPerfisAcesso.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState({
          perfis: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 erro desconhecido');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjetos = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/projetos/GetProjects.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState(
          {
            projectList: response.request,
          },
          () => {
            if (this.props.edicao) {
              this._getUser(this.props.cpf);
            }
          }
        );
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  tipoHandleChange = (event) => {
    this.setState({
      cpf_usuario: '',
      nome: '',
      email: '',
      celular: '',
      tipo: '',
      perfil: '',
    });

    this.setState({ tipo: event.target.value }, () => {
      if (this.state.tipo === 'funcionario') {
        this.getFuncionarios();
      } else if (this.state.tipo === 'fornecedor') {
        this.getFornecedor();
      }
    });
  };

  getFuncionarios = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/funcionarios/GetFuncionarioNotUser.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          usuario: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFornecedor = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/usuarios/GetPJListNotUser.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          usuario: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  usuarioHandleChange = (event) => {
    this.setState({
      cpf_usuario: '',
      nome: '',
      email: '',
      celular: '',
      perfil: '',
    });

    this.setState(
      {
        cpf_selecionado: event.target.value,
      },
      () => {
        if (this.state.tipo === 'funcionario') {
          this.getFuncionario(this.state.cpf_selecionado);
        } else if (this.state.tipo === 'fornecedor') {
          const user = this.state.usuario.filter((value) => {
            return value.cpf_pj === this.state.cpf_selecionado;
          });
          this.setState({
            cpf_usuario: user[0].cpf_pj,
            nome: user[0].nome_pj,
          });
        }
      }
    );
  };

  getFuncionario = (cpf) => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/funcionarios/GetFuncionario.php?json={"cpf_funcionario":"${cpf}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          cpf_usuario: response.request[0].cpf_funcionario,
          nome: response.request[0].nome_funcionario,
          email: response.request[0].email_profissional,
          celular: response.request[0].celular,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  handleSave = () => {
    if (this.props.edicao) {
      this.editUser();
    } else if (!this.props.edicao) {
      this.addNewUser();
    }
  };

  addNewUser = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf: this.state.cpf_usuario,
      nome: this.state.nome,
      email: this.state.email,
      cel: this.state.celular,
      perfil: this.state.perfil,
      ativo: true,
      projetos:
        this.state.projetos.length < 1
          ? [{ id_projeto: this.state.project }]
          : this.state.projetos,
      exclusivo: this.state.exclusivo,
    });

    fetch(
      `${AppState.serverAddr}/usuarios/AddUser.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002' || ret === '40004') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('Usuário já existem');
            alert(
              'Já exite um usuário cadastrado com este CPF. Por favor revise os dados.'
            );
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  editUser = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf: this.state.cpf_usuario,
      nome: this.state.nome,
      email: this.state.email,
      cel: this.state.celular,
      perfil: this.state.perfil,
      projetos:
        this.state.projetos.length < 1
          ? [{ id_projeto: this.state.project }]
          : this.state.projetos,
      exclusivo: this.state.exclusivo,
    });

    fetch(
      `${AppState.serverAddr}/usuarios/EditUser.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (
            ret === '40001' ||
            ret === '40002' ||
            ret === '40003' ||
            ret === '40004'
          ) {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.props.closeDialog();
    this.setState({
      edicao: false,
      usuario: [],
      cpf_usuario: '',
      cpf_usuarioErro: false,
      cpf_selecionado: '',
      cpf_selecionadoErro: false,
      nome: '',
      nomeErro: false,
      email: '',
      emailErro: false,
      celular: '',
      celularErro: false,
      tipo: '',
      tipoErro: false,
      perfilErro: false,
      perfil: '',
      exclusivo: '',
      project: '',
      projetos: [],
    });
  };

  validaForm = () => {
    let validForm = true;

    if (
      this.state.nome === '' ||
      this.state.nome === ' ' ||
      this.state.nome.length > 512
    ) {
      this.setState({
        nomeErro: true,
      });
      validForm = false;
    }

    if (this.state.email === '') {
      this.setState({
        emailErro: true,
      });
      validForm = false;
    }

    if (this.state.tipo === '') {
      this.setState({
        tipoErro: true,
      });
      validForm = false;
    }

    if (
      !this.state.cpf_usuario ||
      this.state.cpf_usuario === '' ||
      this.state.cpf_usuario.length < 14
    ) {
      this.setState({
        cpf_usuarioErro: true,
      });
      validForm = false;
    }

    if (this.state.perfil === '') {
      this.setState({
        perfilErro: true,
      });
      validForm = false;
    }

    if (!this.props.edicao && this.state.tipo !== 'outros') {
      if (this.state.cpf_selecionado === '') {
        this.setState({
          cpf_selecionadoErro: true,
        });
        validForm = false;
      }
    }

    if (this.state.exclusivo === '') {
      this.setState({
        exclusivoErro: true,
      });
      validForm = false;
    }

    if (this.state.exclusivo === 'false') {
      if (this.state.projetos.length === 0) {
        this.setState({
          projetosErro: true,
        });
        validForm = false;
      }
    }

    if (this.state.exclusivo === 'true') {
      if (this.state.project === '') {
        this.setState({
          projectErro: true,
        });
        validForm = false;
      }
    }

    if (validForm === true) {
      this.handleSave();
    } else {
      console.log(this.state, validForm);
    }
  };

  exclusivoHandleChange = (event) => {
    this.setState({
      project: '',
      projetos: [],
      exclusivo: event.target.value,
    });
  };

  projetosHandleChange = (event) => {
    if (event.target.value && event.target.value.length > 0) {
      this.setState({
        projetos: event.target.value,
      });
    }
  };

  projectHandleChange = (event) => {
    this.setState({
      project: event.target.value,
    });
  };

  perfilHandleChange = (event) => {
    this.setState(
      {
        perfil: event.target.value,
      },
      () => {
        if (this.state.perfil === 'usr_no_access') {
          this.setState({
            exclusivo: 'true',
          });
        }
      }
    );
  };

  render() {
    const { cpf_usuario, nome, email, tipo, perfil, celular, exclusivo } =
      this.state;

    return (
      <Dialog
        open={this.props.openDialog}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <div style={{ padding: '2em' }}>
          <Grid container>
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4" gutterBottom>
                Cadastro de Usuário
              </Typography>
              <Icon onClick={this.closeDialog}>close</Icon>
            </Grid>
          </Grid>
          <br />
          <Grid container justify="center">
            <Grid item sm={6}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="type_of_link">Tipo de Vínculo</InputLabel>
                <Select
                  error={this.state.tipoErro}
                  value={tipo}
                  inputProps={{
                    name: 'vinculo',
                    id: 'vinculo',
                    readOnly: this.props.edicao ? true : false,
                  }}
                  onChange={this.tipoHandleChange}
                >
                  <MenuItem value={'funcionario'}>Funcionário</MenuItem>
                  <MenuItem value={'fornecedor'}>Fornecedor</MenuItem>
                  <MenuItem value={'outros'}>Outros</MenuItem>
                </Select>
              </FormControl>

              {!this.props.edicao && tipo !== 'outros' && (
                <FormControl id={'provider_or_employee-place'} fullWidth={true}>
                  <InputLabel htmlFor="provider_or_employee">
                    Selecione o {tipo ? tipo : 'usuário'}{' '}
                  </InputLabel>
                  <Select
                    value={this.state.cpf_selecionado}
                    error={this.state.cpf_selecionadoErro}
                    style={{
                      visibility: sessionStorage.getItem(
                        'provider_or_employee.choise'
                      ),
                    }}
                    onChange={this.usuarioHandleChange}
                  >
                    {this.state.usuario.map((item, index) =>
                      tipo === 'funcionario' ? (
                        <MenuItem key={index} value={item.cpf_funcionario}>
                          {item.nome_funcionario}
                        </MenuItem>
                      ) : (
                        <MenuItem key={index} value={item.cpf_pj}>
                          {item.nome_pj}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

              {(tipo === 'outros' || this.props.edicao) && (
                <FormControl fullWidth={true}>
                  <TextField
                    id="name"
                    type="text"
                    label="Nome"
                    name="nome_usuario"
                    error={this.state.nomeErro}
                    value={nome}
                    onChange={this.handleChange('nome')}
                  />
                </FormControl>
              )}

              <TextField
                fullWidth={true}
                id="cpf"
                value={cpf_usuario}
                label="CPF"
                error={this.state.cpf_usuarioErro}
                onChange={this.handleChange('cpf_usuario')}
                InputProps={{
                  readOnly:
                    this.props.edicao || tipo !== 'outros' ? true : false,
                  inputComponent:
                    this.state.cpf_usuario.length > 14
                      ? CPFMaskCustomEdit
                      : CPFMaskCustom,
                }}
              />

              <FormControl fullWidth={true}>
                <TextField
                  id="cellphone"
                  type="text"
                  label="Celular"
                  value={celular}
                  name="celular"
                  onChange={this.handleChange('celular')}
                  InputProps={{
                    inputComponent: TelMaskCustom,
                  }}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  id="email"
                  type="email"
                  label="E-mail"
                  name="email_usuario"
                  error={this.state.emailErro}
                  value={email}
                  onChange={this.handleChange('email')}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <InputLabel>Perfil</InputLabel>
                <Select
                  displayEmpty={true}
                  value={perfil}
                  error={this.state.perfilErro}
                  inputProps={{
                    name: 'id_perfil',
                    id: 'perfil',
                  }}
                  onChange={this.perfilHandleChange}
                >
                  {this.state.perfis.map((item, index) => (
                    <MenuItem key={index} value={item.id_perfil}>
                      {item.desc_perfil}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                <FormLabel>Usuário exclusivo de um projeto?</FormLabel>
                <RadioGroup
                  name="exclusivo"
                  row={true}
                  value={exclusivo}
                  onChange={this.exclusivoHandleChange}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio color={'primary'} />}
                    disabled={this.state.perfil === 'usr_no_access'}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio color={'primary'} />}
                    disabled={this.state.perfil === 'usr_no_access'}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>

              {this.state.exclusivo === 'true' && (
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="projetos">
                    Selecione o Projeto
                  </InputLabel>
                  <Select
                    error={this.state.projectErro}
                    value={this.state.project}
                    inputProps={{
                      name: 'project',
                    }}
                    onChange={this.projectHandleChange}
                  >
                    {this.props.edicao
                      ? this.state.projectList.map((item) => (
                          <MenuItem
                            key={item.id_projeto}
                            value={item.id_projeto}
                          >
                            {item.dt_encerramento
                              ? `${item.display} (Encerrado)`
                              : item.display}
                          </MenuItem>
                        ))
                      : this.state.projectList
                          .filter((x) => x.dt_encerramento === null)
                          .map((item) => (
                            <MenuItem
                              key={item.id_projeto}
                              value={item.id_projeto}
                            >
                              {item.display}
                            </MenuItem>
                          ))}
                  </Select>
                </FormControl>
              )}

              {this.state.exclusivo === 'false' && (
                <FormControl fullWidth>
                  <InputLabel>Selecione os Projetos</InputLabel>
                  <Select
                    multiple
                    error={this.state.projetosErro}
                    value={this.state.projetos}
                    onChange={this.projetosHandleChange}
                    input={<Input id="select-multiple-chip" />}
                    style={{ wordWrap: 'break-word' }}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value, index) => (
                          <Chip
                            key={index}
                            label={
                              value.dt_encerramento !== null
                                ? value.desc_curta + ' (Encerrado)'
                                : value.desc_curta
                            }
                            style={{
                              margin: '3px 3px',
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          ></Chip>
                        ))}
                      </div>
                    )}
                  >
                    {this.props.edicao
                      ? this.state.projectList.map((item) => (
                          <MenuItem key={item.id_projeto} value={item}>
                            {item.dt_encerramento
                              ? `${item.display} (Encerrado)`
                              : item.display}
                          </MenuItem>
                        ))
                      : this.state.projectList
                          .filter((x) => x.dt_encerramento === null)
                          .map((item) => (
                            <MenuItem key={item.id_projeto} value={item}>
                              {item.display}
                            </MenuItem>
                          ))}
                  </Select>
                </FormControl>
              )}
              <br />
              <br />
              <br />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.validaForm}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    );
  }
}

export default Form;
