import React from "react";
import {Grid, 
        Paper,
        Button,
        TextField,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import ReactTable from "react-table";
import AppState from "../../../../state";
import {browserHistory} from "react-router";
import AutoSelect from '../../../../components/inputs/AutoSelect'
import DeleteIcon from "@material-ui/icons/DeleteForever";

const CustomNoDataComponent = () => {
    return <div className="rt-noData">Selecione um Grupo</div>
}

class GruposUsuarios extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            groups: [],
            selectedGroupId: '',
            selectedGroupData: [],
            users: [],
            selected: '',
            dialog: false,
            newItem: false,
            newItemValue: ''
        };
    }

    componentDidMount() {
        this.getUserGroups();
        this.getUsers();
    }

    closeDialog = () => {
        this.setState({ dialog: false,  edicao: false, selectedGroupData: [], selectedGroupId: '', selected: '', newItem: false, newItemValue: '' }, () => this.getUserGroups());
    };

    openDialog = (edicao = false) => {
        this.setState({
            edicao: edicao,
            dialog: true
        });
    };

    getUserGroups = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/GetGruposUsuarios.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                groups: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getUsers = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/GetUsuarios.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                users: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getGroupData = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/GetGrupoUsuario.php?json={"id_grupo":${this.state.selectedGroupId}}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                selectedGroupData: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    addUsuarioGrupo = () =>{
        if (!this.state.newItemValue) {
            return
        }
        AppState.isLoading = true

        const data = JSON.stringify({
            id_grupo: this.state.selectedGroupId,
            cpf_usuario: this.state.newItemValue
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/AddUsuarioGrupo.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                AppState.isLoading = false;
                this.getGroupData();
                this.setState({
                    newItemValue: '',
                })

            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    addGrupo = () =>{
        if (!this.state.newItemValue) {
            return
        }
        AppState.isLoading = true

        const data = JSON.stringify({
            nome: this.state.newItemValue
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/AddGrupoUsuario.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                AppState.isLoading = false;
                this.getUserGroups();
                this.setState({
                    newItemValue: '',
                    newItem: false
                })

            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    updateGrupo = (group_status) =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_grupo: this.state.selectedGroupId,
            nome: this.state.selectedGroupData.nome,
            status: group_status
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/UpdateGrupoUsuario.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                this.getGroupData();
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    deleteUser = (id_grupo_usuario) =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_grupo_usuario: id_grupo_usuario
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/DeleteUsuarioGrupo.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                AppState.isLoading = false;
                this.getGroupData();
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    userHandleChange = event => {
        this.setState({
            newItemValue: event ? event.value : '',
        }, () => {
            this.addUsuarioGrupo();
        });
    };
    
    groupHandleChange = event => {
        this.setState({
            selectedGroupId: event ? event.value : '',
        }, () => {
            if (event) {
                return this.getGroupData();
            } else {
                this.setState ({
                    selectedGroupData: []
                })
            }
        });
    };

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Admin Grupos de Usuarios</h1>

                <Grid 
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid xs={8}
                        item
                    >
                        <Grid 
                            container
                            justify="space-between"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Grid>
                                <h4>Selecionar Grupo</h4>
                            </Grid>
                            {!this.state.newItem ? (
                                <Grid style={{minWidth: "25rem"}}>
                                    {this.state.groups.length > 0 && 
                                    <AutoSelect
                                        suggestions={this.state.groups.map((val) => {return {'label': val.display, 'value': val.id_grupo}})}
                                        placeholder='Grupos'
                                        multi={false}
                                        value={this.state.selectedGroupId}
                                        initialValue={this.state.selectedGroupId}
                                        handleChange2={this.groupHandleChange}
                                        key={0}
                                    />}
                                </Grid>
                            ) : (
                                <Grid style={{minWidth: "25rem"}}>
                                    {this.state.newItem == 'grupo' ? (
                                        <TextField
                                            value={this.state.newItemValue}
                                            label={'Novo Grupo'}
                                            name={"newItemValue"}
                                            fullWidth
                                            onChange={(e) => this.handleChange(e)}
                                        />
                                    ) : (
                                        <AutoSelect
                                            suggestions={this.state.users.filter((val) => !this.state.selectedGroupData.usuarios.map((x) => x.cpf_usuario).includes(val.cpf_usuario)).map((val) => {return {'label': val.nome_usuario, 'value': val.cpf_usuario}})}
                                            placeholder='Usuários'
                                            multi={false}
                                            value={this.state.newItemValue}
                                            handleChange2={(e) => this.userHandleChange(e)}
                                            initialValue={''}
                                            key={1}
                                        />
                                    )}
                                </Grid>
                            )}
                            {this.state.newItem ? (
                                <Grid>
                                    {this.state.newItem == 'grupo' &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.addGrupo()}
                                        >
                                            Salvar
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.setState({newItem: false})}
                                        style={{marginLeft: 15}}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.setState({newItem: 'grupo', selectedGroupData: [], selectedGroupId: ''})}
                                    >
                                        <AddIcon />
                                        Grupo
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.setState({newItem: 'usuario'})}
                                        disabled={this.state.selectedGroupId == ''}
                                        style={{marginLeft: 15}}
                                    >
                                        <AddIcon />
                                        Participante
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={this.state.selectedGroupData.status == 't' ? "error" : "primary"}
                                        onClick={() => this.updateGrupo(this.state.selectedGroupData.status == 't' ? false : true)}
                                        disabled={this.state.selectedGroupId == ''}
                                        style={{marginLeft: 15}}
                                    >
                                        {this.state.selectedGroupData.status == 't' ? "Desativar" : "Ativar"}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.selectedGroupData.usuarios}
                        pageSize={10}
                        className="-striped -highlight"
                        NoDataComponent={CustomNoDataComponent}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        columns={[
                            {
                                Header: 'Nome',
                                accessor: "nome_usuario",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: 'CPF',
                                accessor: "cpf_usuario",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: 'Ação',
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    >
                                        <DeleteIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.deleteUser(row.original["id_grupo_usuario"])}
                                        />
                                    </div> )
                            }   
                        ]}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default GruposUsuarios;