import React from 'react';
import ReactTable from 'react-table';
import AppState from '../../state';
import { Grid } from '@material-ui/core';

class PlanoDeContas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdc_data: [],
    };
  }

  componentDidMount() {
    this.carrega_relatorio();
  }

  format = (data) => {
    return data.sort(function (a, b) {
      let a_split = a.id_conta.split('.');
      let b_split = b.id_conta.split('.');
      if (a_split[0] == b_split[0]) {
        if (a_split[1] == b_split[1]) {
          return parseInt(a_split[2]) - parseInt(b_split[2]);
        }
        return parseInt(a_split[1]) - parseInt(b_split[1]);
      }
      return parseInt(a_split[0]) - parseInt(b_split[0]);
    });
  };

  carrega_relatorio = () => {
    AppState.isLoading = true;

    fetch(
      `${AppState.serverAddr}/relatorios/GetPlanoDeContas.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          pdc_data: this.format(response.request),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        console.log('Erro de api!', error);
      });
  };

  render() {
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Plano de Contas</h1>
        <Grid
          container
          direction="row"
          justify="space-around"
          style={{ marginTop: '1em', margin: 'auto' }}
        >
          <Grid item xs md={6}>
            <ReactTable
              data={this.state.pdc_data}
              pageSize={this.state.pdc_data.length}
              showPagination={false}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
              }
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id_conta',
                  width: 90,
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'descricao',
                  Cell: (row) => (
                    <div
                      style={{
                        height: '22px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
              ]}
              className="-striped -highlight"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PlanoDeContas;
