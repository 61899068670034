import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Switch,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Icon,
  Checkbox,
  Chip,
  InputLabel,
  withStyles,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import NewSelect from 'react-select';
import StarRating from 'react-svg-star-rating';

import { Utils } from '../../../services';
import * as fornecedorAPI from '../../../api/fornecedores';

const styles = {
  halfGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
  },
};

const DateMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};
DateMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};
CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CNPJMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};
CNPJMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TelMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={true}
    />
  );
};
TelMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CEPMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};
CEPMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const BigChip = withStyles({
  deleteIcon: {
    position: 'absolute',
    right: '0',
  },
})(Chip);

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.curriculumInput = React.createRef();
    this.contratoInput = React.createRef();

    this.state = {
      isActive: true,
      edicao: false,
      natureza: 'PJ',
      nomeFantasia: '',
      nomeRazaoSocial: '',
      cpf_cnpj: '',
      cep: '',
      endereco: '',
      numeroEndereco: '',
      complemento: '',
      cidade: '',
      uf: '',
      possuiISO: 'false',
      emailComercial: '',
      telComercial: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      bairro: '',
      bairroErro: false,
      servicoTecnico: 'true',
      criteriosSelecaoList: [],
      criteriosSelecao: [],
      especialidadesList: [],
      especialidade: [],
      aprovadoresList: [],
      aprovadores: [],
      isAprovado: true,
      curriculum: [],
      contratoSocial: [],
      curriculumLink: null,
      contratoLink: null,
      dtAprovacao: null,
      aprovador: null,
      workflow: null,
      reenviarEmail: false,
      avaliacao: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getEspecialidades();
    this.getFornecedorAprovadores();
    this.getCriterioSelecao();

    if (this.props.edicao) {
      this.getFornecedorData();
      this.getEspecialidadeFornecedor();
      this.getCriteriosFornecedor();
      this.getAprovadoresFornecedor();
    }
  }

  getFornecedorData = () => {
    AppState.isLoading = true;
    fornecedorAPI
      .getFornecedor(this.props.fornecedor)
      .then((response) => {
        this.setState(
          {
            natureza:
              response.request[0].cpf_cnpj_fornecedor.length === 18
                ? 'PJ'
                : 'PF',
            nomeFantasia: response.request[0].nome_fantasia
              ? response.request[0].nome_fantasia
              : '',
            nomeRazaoSocial: response.request[0].razao_social
              ? response.request[0].razao_social
              : '',
            cpf_cnpj: response.request[0].cpf_cnpj_fornecedor
              ? response.request[0].cpf_cnpj_fornecedor
              : '',
            cep: response.request[0].cep_comercial
              ? response.request[0].cep_comercial
              : '',
            endereco: response.request[0].end_comercial
              ? response.request[0].end_comercial
              : '',
            numeroEndereco: response.request[0].numero_comercial
              ? response.request[0].numero_comercial
              : '',
            complemento: response.request[0].complemento_comercial
              ? response.request[0].complemento_comercial
              : '',
            cidade: response.request[0].cidade_comercial
              ? response.request[0].cidade_comercial
              : '',
            uf: response.request[0].uf_comercial
              ? response.request[0].uf_comercial
              : '',
            bairro: response.request[0].bairro_comercial
              ? response.request[0].bairro_comercial
              : '',
            possuiISO:
              response.request[0].possui_iso === 't' ? 'true' : 'false',
            emailComercial: response.request[0].email
              ? response.request[0].email
              : '',
            telComercial: response.request[0].telefone_comercial
              ? response.request[0].telefone_comercial
              : '',
            inscricaoEstadual: response.request[0].inscricao_estadual
              ? response.request[0].inscricao_estadual
              : '',
            inscricaoMunicipal: response.request[0].inscricao_municipal
              ? response.request[0].inscricao_municipal
              : '',
            isActive: response.request[0].ativo === 't' ? true : false,
            wasActive: response.request[0].ativo === 't' ? true : false,
            fl_contrato_ativo:
              response.request[0].fl_contrato_ativo === 't' ? true : false,
            servicoTecnico:
              response.request[0].servico_tecnico === 't' ? 'true' : 'false',
            curriculumLink: response.request[0].link_curriculum
              ? response.request[0].link_curriculum
              : '',
            contratoLink: response.request[0].link_contrato
              ? response.request[0].link_contrato
              : '',
            dtAprovacao: Utils.dateToPT(response.request[0].dt_aprovacao),
            workflow: response.request[0].aprovacao_workflow
              ? response.request[0].aprovacao_workflow
              : '',
            aprovador: response.request[0].nome_aprovador
              ? response.request[0].nome_aprovador
              : '',
            avaliacao: response.request[0].avaliacao
              ? parseFloat(response.request[0].avaliacao).toFixed(2)
              : '--',
          },
          () => {
            AppState.isLoading = false;
            if (this.state.curriculumLink) {
              this.setState({
                curriculum: [{ name: 'Curriculum Anexado' }],
              });
            }
            if (this.state.contratoLink) {
              this.setState({
                contratoSocial: [{ name: 'Contrato Anexado' }],
              });
            }
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getEspecialidadeFornecedor = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/fornecedores/GetEspecialidadesByFornecedor.php?json={"cnpj_fornecedor":"${this.props.fornecedor}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        let result = response.request.map((item) => ({
          value: item.especialidade,
          label: item.especialidade,
        }));

        this.setState(
          {
            especialidade: result,
          },
          () => (AppState.isLoading = false)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getAprovadoresFornecedor = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/fornecedores/GetAprovadoresByFornecedor.php?json={"cnpj_fornecedor":"${this.props.fornecedor}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        let result = response.request.map((item) => ({
          value: item.aprovador,
          label: item.nome_usuario,
        }));

        this.setState(
          {
            aprovadores: result,
          },
          () => (AppState.isLoading = false)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getCriteriosFornecedor = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/fornecedores/getCriteriosSelecaoByFornecedor.php?json={"cnpj_fornecedor":"${this.props.fornecedor}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        let result = response.request.map((item) => ({
          value: item.criterio,
          label: item.criterio,
        }));

        this.setState(
          {
            criteriosSelecao: result,
          },
          () => (AppState.isLoading = false)
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  addFornecedor = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf: this.state.cpf_cnpj,
      nome: this.state.nomeRazaoSocial,
      nome_fantasia: this.state.nomeFantasia,
      tel_com: this.state.telComercial,
      email_com: this.state.emailComercial,
      cep: this.state.cep,
      endereco: this.state.endereco,
      end_numero: this.state.numeroEndereco,
      end_complemento: this.state.complemento,
      end_bairro: this.state.bairro,
      cidade: this.state.cidade,
      uf: this.state.uf,
      iso: this.state.possuiISO,
      inscricao_municipal: this.state.inscricaoMunicipal,
      inscricao_estadual: this.state.inscricaoEstadual,
      ativo: 'false',
      aprovadores: this.state.aprovadores,
      especialidade: this.state.especialidade,
      criterio_selecao: this.state.criteriosSelecao,
      servico_tecnico: this.state.servicoTecnico,
    });

    let fornecedorData = new FormData();
    fornecedorData.append('json', data);
    fornecedorData.append('curriculum', this.state.curriculum[0]);
    fornecedorData.append('contratoSocial', this.state.contratoSocial[0]);

    fornecedorAPI
      .addFornecedor(fornecedorData)
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('40003 - Fornecedor já existente');
            alert('Já existe um fornecedor cadastrado com este CPF/CNPJ.');
          } else if (ret === '40004') {
            console.log('40004 - Ocorreu um erro ao cadastrar este fornecedor');
            alert('Ocorreu um erro ao cadastrar este fornecedor.');
          } else if (ret === '40005') {
            console.log(
              '40005 - O fornecedor foi criado porém ocorreu um erro ao inserir as suas especialidades'
            );
            alert(
              'O fornecedor foi criado porém ocorreu um erro ao inserir as suas especialidades.'
            );
          } else if (ret === '40006') {
            console.log(
              '40006 - O fornecedor foi criado porém ocorreu um erro ao inserir os critérios de seleção '
            );
            alert(
              'O fornecedor foi criado porém ocorreu um erro ao inserir os critérios de seleção.'
            );
          } else if (ret === '40007') {
            console.log(
              '40007 - O fornecedor foi criado porém ocorreu um erro ao enviar para aprovação'
            );
            alert(
              'O fornecedor foi criado porém ocorreu um erro ao enviar para aprovação.'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  editFornecedor = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf: this.state.cpf_cnpj,
      nome: this.state.nomeRazaoSocial,
      nome_fantasia: this.state.nomeFantasia,
      tel_com: this.state.telComercial,
      email_com: this.state.emailComercial,
      cep: this.state.cep,
      endereco: this.state.endereco,
      end_numero: this.state.numeroEndereco,
      end_complemento: this.state.complemento,
      cidade: this.state.cidade,
      uf: this.state.uf,
      end_bairro: this.state.bairro,
      iso: this.state.possuiISO,
      inscricao_municipal: this.state.inscricaoMunicipal,
      inscricao_estadual: this.state.inscricaoEstadual,
      ativo: this.state.isActive ? 'true' : 'false',
      especialidade: this.state.especialidade,
      criterio_selecao: this.state.criteriosSelecao,
      servico_tecnico: this.state.servicoTecnico,
      link_curriculum: this.state.curriculumLink,
      link_contrato: this.state.contratoLink,
      reenviaEmail: this.state.reenviarEmail,
      aprovadores: this.state.aprovadores,
    });

    let fornecedorData = new FormData();
    fornecedorData.append('json', data);
    fornecedorData.append('curriculum', this.state.curriculum[0]);
    fornecedorData.append('contratoSocial', this.state.contratoSocial[0]);

    fornecedorAPI
      .editFornecedor(fornecedorData)
      .then(() => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('40003 - Erro ao Editar Fornecedor');
            alert('Erro ao Editar o Fornecedor');
          } else if (ret === '40004') {
            console.log(
              '40004 - O fornecedor foi alterado porém ocorreu um erro ao inserir as suas especialidades'
            );
            alert(
              'O fornecedor foi alterado porém ocorreu um erro ao inserir as suas especialidades'
            );
          } else if (ret === '40005') {
            console.log(
              '40005 - O fornecedor foi alterado porém ocorreu um erro ao inserir os critérios de seleção'
            );
            alert(
              'O fornecedor foi alterado porém ocorreu um erro ao inserir os critérios de seleção'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  natureHandleChange = (event) => {
    this.setState({
      natureza: event.target.value,
      contratoSocial: [],
      curriculum: [],
      curriculumLink: null,
      contratoLink: null,
    });
  };

  reenviaEmailHandleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  closeDialog = () => {
    this.setState(
      {
        isActive: true,
        edicao: false,
        natureza: 'PJ',
        nomeFantasia: '',
        nomeFantasiaErro: false,
        nomeRazaoSocial: '',
        nomeRazaoSocialErro: false,
        cpf_cnpj: '',
        cpf_cnpjErro: false,
        cep: '',
        cepErro: false,
        endereco: '',
        enderecoErro: false,
        numeroEndereco: '',
        numeroEnderecoErro: false,
        complemento: '',
        cidade: '',
        cidadeErro: false,
        uf: '',
        ufErro: false,
        possuiISO: 'true',
        possuiISOErro: false,
        emailComercial: '',
        emailComercialErro: false,
        telComercial: '',
        telComercialErro: false,
        inscricaoEstadual: '',
        inscricaoMunicipal: '',
        bairro: '',
        bairroErro: false,
        curriculum: [],
        contratoSocial: [],
        curriculumLink: null,
        contratoLink: null,
        servicoTecnico: 'true',
        especialidade: [],
        criteriosSelecao: [],
        aprovadores: [],
        dtAprovacao: null,
        reenviarEmail: false,
        avaliacao: '',
      },
      () => this.props.close()
    );
  };

  statusHandleChange = (event) => {
    if (event.target.checked) {
      this.setState({
        isActive: event.target.checked,
      });
    } else {
      this.setState({
        isActive: event.target.checked,
      });
    }
  };

  isTamanhoAnexosPermitido = () => {
    const tamanhoCurriculumInKb =
      this.state.curriculum[0] && this.state.curriculum[0].size
        ? this.state.curriculum[0].size / 1000
        : 0;
    const tamanhoContratoInKb =
      this.state.contratoSocial[0] && this.state.contratoSocial[0].size
        ? this.state.contratoSocial[0].size / 1000
        : 0;
    const limitePermitidoInKb = 25000;

    return tamanhoContratoInKb + tamanhoCurriculumInKb <= limitePermitidoInKb;
  };

  validaForm = () => {
    let formValido = true;
    let erros = [];

    if (this.state.natureza === 'PJ') {
      if (this.state.cpf_cnpj.length < 18 || this.state.cpf_cnpj === '') {
        formValido = false;
        this.setState({
          cpf_cnpjErro: true,
        });
        erros.push('\nPor favor informe um CNPJ válido');
      }

      // if(this.state.servicoTecnico === 'true'){
      // if(!(Array.isArray(this.state.contratoSocial) && this.state.contratoSocial.length)){
      //     formValido = false
      //     erros.push('\nPor favor, valide se o Contrato Social foi anexado')
      // }

      // if(!this.state.curriculum || this.state.curriculum === ''){
      //     formValido = false
      //     erros.push('\nPor favor valide se o Curriculum foi anexado')
      // }
      // }
    } else if (this.state.natureza === 'PF') {
      if (this.state.cpf_cnpj.length < 14 || this.state.cpf_cnpj === '') {
        formValido = false;
        this.setState({
          cpf_cnpjErro: true,
        });
      }
    }

    if (this.state.servicoTecnico === 'true') {
      if (
        !(Array.isArray(this.state.curriculum) && this.state.curriculum.length)
      ) {
        formValido = false;
        erros.push('\nPor favor valide se o Curriculum foi anexado');
      }
    }

    if (
      !(
        Array.isArray(this.state.especialidade) &&
        this.state.especialidade.length
      )
    ) {
      formValido = false;
      erros.push(
        '\nPor favor, valide se as especializações foram selecionadas'
      );
    }

    if (
      !(
        Array.isArray(this.state.criteriosSelecao) &&
        this.state.criteriosSelecao.length
      )
    ) {
      formValido = false;
      erros.push('\nPor favor, valide se os critérios foram selecionados');
    }

    if (this.state.workflow !== 'aprovado') {
      if (
        !(
          Array.isArray(this.state.aprovadores) && this.state.aprovadores.length
        )
      ) {
        formValido = false;
        erros.push('\nPor favor, valide se os aprovadores foram selecionados');
      }
    }

    if (this.state.nomeRazaoSocial === '') {
      formValido = false;
      this.setState({
        nomeRazaoSocialErro: true,
      });

      erros.push('\nPor favor, informe a Razão social');
    }

    if (!this.isTamanhoAnexosPermitido()) {
      formValido = false;
      erros.push('\nA soma do tamanho dos anexos não pode exceder os 25mb.');
    }

    if (formValido) {
      if (this.props.edicao === true) {
        this.editFornecedor();
      } else {
        this.addFornecedor();
      }
    } else {
      alert(erros);
    }
  };

  CEPhandleChange = (event) => {
    this.setState(
      {
        cep: event.target.value,
      },
      () => {
        if (this.state.cep.length === 9) {
          this.getEndereco(this.state.cep.replace('-', ''));
        }
      }
    );
  };

  getEndereco = (cep) => {
    AppState.isLoading = true;
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          endereco: response.logradouro,
          bairro: response.bairro,
          cidade: response.localidade,
          uf: response.uf,
        });

        if (response.erro === true) {
          this.setState({
            endereco: '',
            bairro: '',
            cidade: '',
            uf: '',
          });
        }
      });
  };

  getEspecialidades = () => {
    AppState.isLoading = true;
    fornecedorAPI
      .getEspecialidades()
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          especialidadesList: response.request.reduce((list, item) => {
            if (item.ativo === 't') {
              let obj = {
                label: item.especialidade,
                value: item.especialidade,
              };
              list.push(obj);
            }
            return list;
          }, []),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getCriterioSelecao = () => {
    AppState.isLoading = true;
    fornecedorAPI
      .getCriterioSelecao()
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          criteriosSelecaoList: response.request.map((item) => ({
            label: item.criterio,
            value: item.criterio,
          })),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFornecedorAprovadores = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/fornecedores/GetFornecedorAprovadores.php?`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          aprovadoresList: response.request.map((item) => ({
            label: item.nome_usuario,
            value: item.cpf_usuario,
          })),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  aprovadoresHandleChange = (value) => {
    this.setState({
      aprovadores: value,
    });
  };

  criteriosSelecaoHandleChange = (value) => {
    this.setState({
      criteriosSelecao: value,
    });
  };

  especialidadeHandleChange = (value) => {
    this.setState({
      especialidade: value,
    });
  };

  contratoHandleChange = (event) => {
    if (this.contratoInput.current.files[0]) {
      this.setState({
        contratoSocial: [this.contratoInput.current.files[0]],
      });
    }

    event.target.value = null;
  };

  curriculumHandleChange = (event) => {
    if (this.curriculumInput.current.files[0]) {
      this.setState({
        curriculum: [this.curriculumInput.current.files[0]],
      });
    }

    event.target.value = null;
  };

  deleteContratoChip = (index) => {
    let arr = this.state.contratoSocial;

    arr.splice(index, 1);

    this.setState({
      contratoSocial: arr,
      contratoLink: null,
    });
  };

  deleteCurriculumChip = (index) => {
    let arr = this.state.curriculum;

    arr.splice(index, 1);

    this.setState({
      curriculum: arr,
      curriculumLink: null,
    });
  };

  servicoTecnicoHandleChange = (event) => {
    if (event.target.value === 'false') {
      this.setState({
        curriculum: [],
        contratoSocial: [],
        curriculumLink: null,
        contratoLink: null,
      });
    }

    this.setState({
      servicoTecnico: event.target.value,
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent style={{ paddingTop: '16px' }}>
          <Grid
            container={true}
            alignItems={'flex-start'}
            justify={'space-around'}
            direction={'row'}
            spacing={32}
          >
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4">Cadastro de Fornecedor</Typography>
              <Icon onClick={this.closeDialog}>close</Icon>
            </Grid>

            <Grid item={true} md={4} style={{ width: '100%' }}>
              {this.props.edicao && (
                <Grid container justify={'space-between'} spacing={16}>
                  <Grid item md={6}>
                    <InputLabel
                      style={{
                        textTransform: 'capitalize',
                        color:
                          this.state.workflow === 'aprovado' ? 'green' : 'red',
                      }}
                    >
                      Fornecedor{' '}
                      {this.state.workflow === 'em_aprovacao'
                        ? 'Em Aprovação'
                        : this.state.workflow}
                    </InputLabel>
                  </Grid>
                  <Grid item md={6}>
                    <FormControlLabel
                      style={{ marginTop: '-13px' }}
                      control={
                        <Switch
                          value={this.state.isActive}
                          disabled={
                            this.props.edicao === false ||
                            this.state.workflow === 'reprovado' ||
                            this.state.workflow === 'em_aprovacao' ||
                            (this.state.fl_contrato_ativo === true &&
                              this.state.isActive)
                          }
                          color={'primary'}
                          checked={this.state.isActive === true}
                          onChange={this.statusHandleChange}
                        />
                      }
                      label={
                        'Fornecedor ' +
                        (this.state.isActive ? 'Ativo' : 'Inativo')
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.isActive === true &&
                this.props.edicao === true &&
                this.state.fl_contrato_ativo === true && (
                  <FormControl fullWidth={true}>
                    <span style={{ color: 'red', marginBottom: '8px' }}>
                      {'Este Fornecedor tem contratos ativos.'}
                    </span>
                  </FormControl>
                )}

              {!this.props.edicao && (
                <FormControl
                  component="fieldset"
                  style={{ marginBottom: '-15px' }}
                >
                  <FormLabel>Natureza</FormLabel>
                  <RadioGroup
                    name="natureza"
                    row={true}
                    value={this.state.natureza}
                    onChange={this.natureHandleChange}
                  >
                    <FormControlLabel
                      value="PF"
                      control={<Radio color={'primary'} />}
                      label="Pessoa Física"
                    />
                    <FormControlLabel
                      value="PJ"
                      control={<Radio color={'primary'} />}
                      label="Pessoa Jurídica"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {this.state.natureza === 'PJ' && (
                <FormControl fullWidth={true}>
                  <TextField
                    label={'Nome Fantasia'}
                    name={'nomeFantasia'}
                    value={this.state.nomeFantasia}
                    onChange={this.handleChange}
                    error={this.state.nomeFantasiaErro}
                  />
                </FormControl>
              )}

              <FormControl fullWidth={true}>
                <TextField
                  label={this.state.natureza === 'PF' ? 'Nome' : 'Razão Social'}
                  name={'nomeRazaoSocial'}
                  value={this.state.nomeRazaoSocial}
                  onChange={this.handleChange}
                  error={this.state.nomeRazaoSocialErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={this.state.natureza === 'PF' ? 'CPF' : 'CNPJ'}
                  name={'cpf_cnpj'}
                  InputProps={{
                    inputComponent:
                      this.state.natureza === 'PF'
                        ? CPFMaskCustom
                        : CNPJMaskCustom,
                    readOnly: this.props.edicao,
                  }}
                  value={this.state.cpf_cnpj}
                  onChange={this.handleChange}
                  error={this.state.cpf_cnpjErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Telefone Comercial'}
                  name={'telComercial'}
                  value={this.state.telComercial}
                  InputProps={{
                    inputComponent: TelMaskCustom,
                  }}
                  onChange={this.handleChange}
                  error={this.state.telComercialErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Email Comercial'}
                  name={'emailComercial'}
                  value={this.state.emailComercial}
                  onChange={this.handleChange}
                  error={this.state.emailComercialErro}
                />
              </FormControl>

              <Grid container spacing={16}>
                <Grid item md={6} style={styles.halfGrid}>
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Inscrição Estadual'}
                      name={'inscricaoEstadual'}
                      value={this.state.inscricaoEstadual}
                      onChange={this.handleChange}
                      error={this.state.inscricaoEstadualErro}
                    />
                  </FormControl>
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Inscrição Municipal'}
                      name={'inscricaoMunicipal'}
                      value={this.state.inscricaoMunicipal}
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={16}>
                <Grid item md={6} style={styles.halfGrid}>
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Avaliação Média'}
                      name={'avaliacao'}
                      value={this.state.avaliacao}
                      disabled={true}
                    />
                  </FormControl>
                  <FormControl fullWidth={true}>
                    <br />
                    <StarRating
                      initialRating={this.state.avaliacao}
                      isReadOnly
                      isHalfRating={true}
                      key={this.state.avaliacao}
                      emptyColor={'#9ea6d8'}
                      size={'20px'}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} md={4} style={{ width: '100%' }}>
              <Grid container justify={'space-between'} spacing={16}>
                <Grid item md={6}>
                  {this.state.dtAprovacao && (
                    <FormControl fullWidth={true}>
                      <TextField
                        disabled
                        style={{ marginTop: '10px' }}
                        label={'Aprovador'}
                        value={this.state.aprovador}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={6}>
                  {this.state.dtAprovacao && (
                    <FormControl fullWidth={true}>
                      <TextField
                        disabled
                        style={{ marginTop: '10px' }}
                        label={'Data da Aprovação'}
                        value={this.state.dtAprovacao}
                        InputProps={{
                          inputComponent: DateMaskCustom,
                        }}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>

              <FormControl fullWidth={true} style={{ marginTop: '12px' }}>
                <TextField
                  label={'CEP'}
                  name={'cep'}
                  value={this.state.cep}
                  InputProps={{
                    inputComponent: CEPMaskCustom,
                  }}
                  onChange={this.CEPhandleChange}
                  error={this.state.cepErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Endereço'}
                  name={'endereco'}
                  value={this.state.endereco}
                  onChange={this.handleChange}
                  error={this.state.enderecoErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Número'}
                  name={'numeroEndereco'}
                  value={this.state.numeroEndereco}
                  onChange={this.handleChange}
                  error={this.state.numeroEnderecoErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Complemento'}
                  name={'complemento'}
                  value={this.state.complemento}
                  onChange={this.handleChange}
                  error={this.state.complementoErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Bairro'}
                  name={'bairro'}
                  value={this.state.bairro}
                  onChange={this.handleChange}
                  error={this.state.bairroErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Cidade'}
                  name={'cidade'}
                  value={this.state.cidade}
                  onChange={this.handleChange}
                  error={this.state.cidadeErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'UF'}
                  name={'uf'}
                  value={this.state.uf}
                  onChange={this.handleChange}
                  error={this.state.ufErro}
                />
              </FormControl>
            </Grid>

            <Grid item md={4} style={{ width: '100%' }}>
              <InputLabel>
                Selecione as Especializações do Fornecedor
              </InputLabel>
              <NewSelect
                value={this.state.especialidade}
                onChange={this.especialidadeHandleChange}
                options={this.state.especialidadesList}
                placeholder={'Selecione ou Digite...'}
                isMulti
              />

              <FormControl
                fullWidth={true}
                component="fieldset"
                style={{ marginTop: '1em' }}
              >
                <FormLabel>Serviço Técnico</FormLabel>
                <RadioGroup
                  aria-label="iso 9000"
                  name="servicoTecnico"
                  row={true}
                  value={this.state.servicoTecnico}
                  onChange={this.servicoTecnicoHandleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color={'primary'} />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color={'primary'} />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>

              <Grid container justify={'space-between'} spacing={16}>
                {this.state.servicoTecnico === 'true' && (
                  <Grid item md={12}>
                    <InputLabel>Curriculum</InputLabel>
                    <label htmlFor="curriculo-input" style={{ width: '100%' }}>
                      <input
                        id="curriculo-input"
                        type="file"
                        style={{ display: 'none' }}
                        ref={this.curriculumInput}
                        onChange={this.curriculumHandleChange}
                        disabled={this.state.curriculum.length > 0}
                      />
                      <Button
                        variant={'outlined'}
                        component="span"
                        color={'primary'}
                        fullWidth
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                        disabled={this.state.curriculum.length > 0}
                      >
                        Anexar Arquivo
                      </Button>
                    </label>

                    {this.state.curriculum.map((item, index) => (
                      <BigChip
                        key={index}
                        style={{
                          margin: '3px 3px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          position: 'relative',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                        label={
                          <a href={this.state.curriculumLink}>{item.name}</a>
                        }
                        component="a"
                        clickable={this.state.curriculumLink !== null}
                        onDelete={() => this.deleteCurriculumChip(index)}
                      />
                    ))}
                  </Grid>
                )}

                {this.state.servicoTecnico === 'true' &&
                  this.state.natureza === 'PJ' && (
                    <Grid item md={12}>
                      <InputLabel>Contrato Social</InputLabel>
                      <label htmlFor="contrato-input" style={{ width: '100%' }}>
                        <input
                          id="contrato-input"
                          type="file"
                          style={{ display: 'none' }}
                          ref={this.contratoInput}
                          onChange={this.contratoHandleChange}
                          disabled={this.state.contratoSocial.length > 0}
                        />
                        <Button
                          variant={'outlined'}
                          color={'primary'}
                          fullWidth
                          component="span"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          disabled={this.state.contratoSocial.length > 0}
                        >
                          Anexar Arquivo
                        </Button>
                      </label>

                      {this.state.contratoSocial.map((item, index) => (
                        <BigChip
                          key={index}
                          style={{
                            margin: '3px 3px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            position: 'relative',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          label={
                            <a href={this.state.contratoLink}>{item.name}</a>
                          }
                          component="a"
                          clickable={this.state.contratoLink !== null}
                          onDelete={() => this.deleteContratoChip(index)}
                        />
                      ))}
                    </Grid>
                  )}
              </Grid>

              <InputLabel style={{ marginTop: '16px' }}>
                Critérios de Seleção - SGI-74-001-PR
              </InputLabel>
              <NewSelect
                value={this.state.criteriosSelecao}
                onChange={this.criteriosSelecaoHandleChange}
                options={this.state.criteriosSelecaoList}
                placeholder={'Selecione ou Digite...'}
                isMulti
              />

              {this.props.edicao && this.state.workflow !== 'aprovado' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.reenviarEmail}
                      onChange={this.reenviaEmailHandleChange('reenviarEmail')}
                      color="primary"
                    />
                  }
                  label="Reenviar e-mail para os aprovadores"
                />
              )}

              {(!this.props.edicao ||
                this.state.reenviarEmail ||
                this.state.aprovadores.length > 0) && (
                <InputLabel style={{ marginTop: '16px' }}>
                  Selecione os Aprovadores
                </InputLabel>
              )}
              {(!this.props.edicao ||
                this.state.reenviarEmail ||
                this.state.aprovadores.length > 0) && (
                <NewSelect
                  value={this.state.aprovadores}
                  onChange={this.aprovadoresHandleChange}
                  options={this.state.aprovadoresList}
                  placeholder={'Selecione ou Digite...'}
                  isMulti
                />
              )}
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: '2em' }}
            container={true}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              onClick={this.validaForm}
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Form;
