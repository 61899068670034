import AppState from "../state";
import { browserHistory } from "react-router";
import moment from "moment";

export const get_dashboard_data = (months = 5) => {
  let promiseArr = [
    get_evolucao_mensal(months, false, false),
    get_relatorio_ytd(),
    get_relatorio_mtd(),
    get_relatorio_12m(),
    get_evolucao_anual(),
    get_prazos_pagamento(),
    get_faturas_em_aberto(),
    get_evolucao_mensal(months, false, true),
  ]

  return Promise.all(promiseArr).then((values) => {
    if (values.length != promiseArr.length) throw ('Dados inconsistentes')
    return formatValues(values);
  })
  .catch((err) => {
    throw (err);
  })
}

export const get_indicators_data = (dt_inicio, dt_fim, acum = false, reportType = 'consolidado', activeProjectsFilter = false, specific_project = null) => {
  let startDate = moment(dt_inicio, 'YYYY-MM').startOf('month');
  let startDateBackup = moment(dt_inicio, 'YYYY-MM').startOf('month');
  let endDate = moment(dt_fim, 'YYYY-MM').endOf('month');
  let typeArr = ['financeiro', 'economico'];
  
  let startDates = [];
  let endDates = [];

  while (startDate.isBefore(endDate)) {
    if (acum) {
      startDates.push(startDateBackup.format("YYYY-MM-DD"));
    } else {
      startDates.push(startDate.format("YYYY-MM-DD"));
    }
    endDates.push(startDate.endOf('month').format('YYYY-MM-DD'))
    startDate.add(1, 'month').startOf('month');
  }

  let promiseArr = [];
  for (let i = 0; i < startDates.length; i++) {
    for (let j = 0; j < typeArr.length; j++) {
      promiseArr.push(new Promise((resolve, reject) => {
        try {
          let data = consulta_relatorio(startDates[i], endDates[i], typeArr[j], null, false, reportType, activeProjectsFilter, false, specific_project);
          resolve (data);
        } catch (err) {
          reject(err)
        }
      }
      ))
    }
  }

  return Promise.all(promiseArr).then((values) => {
    if (values.length != promiseArr.length) throw ('Dados inconsistentes')
    return formatEvolucaoMensal(values);
  })
  .catch((err) => {
    throw (err);
  })
}

const formatValues = (data) => {
  return {
    'evolucao_mensal': formatEvolucaoMensal(data[0]),
    'relatorio_ytd': data[1],
    'relatorio_mtd': data[2],
    'roi_12m': calculateRoi(data[3]),
    'evolucao_anual': formatEvolucaoAnual(data[4]),
    'prazos_pagamento': data[5],
    'faturas_em_aberto': data[6].valor,
    'faturas_em_aberto90d': data[6].valor_90d,
    'evolucao_mensal_ativos': formatEvolucaoMensal(data[7])
  }
}

const formatEvolucaoMensal = (data) => {
  let result = {}
  result['financeiro'] = [];
  result['economico'] = [];
  for (let i = 0; i < data.length; i++) {
    let datum = data[i];
    if (datum.tipo == 'financeiro') {
      result['financeiro'].push(datum);
    } else if (datum.tipo == 'economico') {
      result['economico'].push(datum);
    } else {
      throw('Dados inconsistentes')
    }
  }
  return result
}

const formatEvolucaoAnual = (data) => {
  let result = {};
  for (let i = 0; i < data.length; i++) {
    let year_data = data[i];
    let year = moment(year_data.dt_inicio).format('YYYY')
    for (let j = 0; j < year_data.length; j++) {
      let value = year_data[j];
      if (!result[value.tipo_projeto]) {
        result[value.tipo_projeto] = [{ ano: year, receita: value.receita }];
      } else {
        result[value.tipo_projeto].push({ ano: year, receita: value.receita })
      }
    }
  }
  return result
}

const calculateRoi = (data) => {
  let receita = data.receita;
  let despesa = data.tdd + data.tdi;
  return Math.round(((receita - despesa) / despesa) * 100)
}

const get_relatorio_ytd = () => {
  let startDate = moment().startOf('year').format('YYYY-MM-DD')
  let endDate = moment().endOf('year').format('YYYY-MM-DD')
  
  return new Promise((resolve, reject) => {
    try {
      let data = consulta_relatorio(startDate, endDate);
      resolve (data);
    } catch (err) {
      reject(err)
    }
  })
}

const get_relatorio_mtd = () => {
  let startDate = moment().startOf('month').format('YYYY-MM-DD')
  let endDate = moment().endOf('month').format('YYYY-MM-DD')
  
  return new Promise((resolve, reject) => {
    try {
      let data = consulta_relatorio(startDate, endDate);
      resolve (data);
    } catch (err) {
      reject(err)
    }
  })
}

const get_relatorio_12m = () => {
  let dateRef = moment().subtract(1, 'months').endOf('month');
  let startDate = dateRef.clone().subtract(12, 'months').add(1, 'days').format('YYYY-MM-DD');
  let endDate = dateRef.format('YYYY-MM-DD');

  return new Promise((resolve, reject) => {
    try {
      let data = consulta_relatorio(startDate, endDate);
      resolve (data);
    } catch (err) {
      reject(err)
    }
  })
}

const get_evolucao_anual = async () => {
  let startDate = moment().startOf('year').subtract(4, 'years')
  let endDate = moment().endOf('year')
  let typeArr = ['financeiro'];
  
  let startDates = [];
  let endDates = [];

  while (startDate.isBefore(endDate)) {
    startDates.push(startDate.format("YYYY-01-01"));
    endDates.push(startDate.format('YYYY-12-31'))
    startDate.add(1, 'year');
  }

  let promiseArr = [];
  for (let i = 0; i < startDates.length; i++) {
    for (let j = 0; j < typeArr.length; j++) {
      promiseArr.push(new Promise((resolve, reject) => {
        try {
          let data = consulta_relatorio(startDates[i], endDates[i], typeArr[j], {raw_data: true});
          resolve (data);
        } catch (err) {
          reject(err)
        }
      }
      ))
    }
  }

  return Promise.all(promiseArr).then((values) => {
    return values;
  })
}

const get_evolucao_mensal = (months = 5, acum = false, activeProjectsFilter = false) => {
  let startDate = moment().subtract(months, 'months');
  let startDateBackup = moment().subtract(months, 'months');
  let endDate = moment().endOf('month');
  let typeArr = ['financeiro', 'economico'];
  
  let startDates = [];
  let endDates = [];

  while (startDate.isBefore(endDate)) {
    if (acum) {
      startDates.push(startDateBackup.format("YYYY-MM-01"));
    } else {
      startDates.push(startDate.format("YYYY-MM-01"));
    }
    endDates.push(startDate.endOf('month').format('YYYY-MM-DD'))
    startDate.add(1, 'month');
  }

  let promiseArr = [];
  for (let i = 0; i < startDates.length; i++) {
    for (let j = 0; j < typeArr.length; j++) {
      promiseArr.push(new Promise((resolve, reject) => {
        try {
          let data = consulta_relatorio(startDates[i], endDates[i], typeArr[j], null, false, 'consolidado', activeProjectsFilter);
          resolve (data);
        } catch (err) {
          reject(err)
        }
      }
      ))
    }
  }

  return Promise.all(promiseArr).then((values) => {
    return values;
  })
}

const reduz_relatorio = (data) => {
  let indicadores = data.reduce(
    (total, _original) => {
      total.tdi += parseFloat(_original.tdi);
      total.tdd += parseFloat(_original.tdd);
      total.despesa += parseFloat(_original.tdd) + parseFloat(_original.tdi);
      total.receita += parseFloat(_original.receita);
      total.saldo_periodo += parseFloat(_original.saldo_periodo);
      total.resultado += parseFloat(_original.resultado);
      total.mc += parseFloat(_original.mc);
      total.ac += parseFloat(_original.ac);
      total.it += parseFloat(_original.it);
      total.pessoal += parseFloat(_original.pessoal);
      total.outros += parseFloat(_original.outros);

      return total;
    },
    {
      tdi: 0,
      tdd: 0,
      despesa: 0,
      receita: 0,
      resultado: 0,
      saldo_periodo: 0,
      mc: 0,
      ac: 0,
      it: 0,
      pessoal: 0,
      outros: 0
    }
  );

  indicadores.mcAc = safe_division(indicadores.mc, indicadores.ac) * 100;
  indicadores.tddReceita = safe_division(indicadores.tdd, indicadores.receita) * 100;
  indicadores.acReceita = safe_division(indicadores.ac, indicadores.receita) * 100;
  indicadores.itReceita = safe_division(indicadores.it, indicadores.receita) * 100;
  indicadores.tdiReceita = safe_division(indicadores.tdi, indicadores.receita) * 100;
  indicadores.resReceita = safe_division(indicadores.resultado, indicadores.receita) * 100;
  indicadores.acTdd = safe_division(indicadores.ac, indicadores.tdd) * 100;
  indicadores.itTdd = safe_division(indicadores.it, indicadores.tdd) * 100;
  indicadores.resTdd = safe_division(indicadores.resultado, indicadores.tdd) * 100;
  indicadores.receitaTdd = (safe_division(indicadores.receita, indicadores.tdd) - 1) * 100;
  indicadores.tdiTdd = safe_division(indicadores.tdi, indicadores.tdd) * 100;

  return indicadores
}

const consulta_relatorio = (dt_inicio, dt_fim, tipo = 'financeiro', grouByReduce = false, df = false, reportType = 'consolidado', activeProjectsFilter = false, raw_data = false, specific_project = null) => {
  const json = JSON.stringify({
    dt_inicio: dt_inicio,
    dt_fim: dt_fim,
    tipo: reportType,
    calculadf: df
  });

  const url = tipo === "financeiro" ? "/relatorios/RelatorioGerencial.php" : "/relatorios/RelatorioGerencialEco.php";

  return fetch(
    `${AppState.serverAddr}${url}?json=${json}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  )
    .then(response => {
      if (response.status === 401) {
        AppState.isLoading = false;
        browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        console.log('Unauthorized');
        return;
      }
      const res = response.json();
      return res;
    })
    .then(response => {
      if (specific_project) {
        response.request = response.request.filter((obj) => obj.p_id_projeto == specific_project)
      }
      if (activeProjectsFilter) {
        response.request = response.request.filter((obj) => obj.status == "Ativo")
      }
      let data = grouByReduce ? groupByTipoProjeto(response.request) : raw_data ? response.request : reduz_relatorio(response.request);
      data.dt_inicio = dt_inicio;
      data.dt_fim = dt_fim;
      data.tipo = tipo;
      return data
    })
    .catch(function(error) {
      throw (error)
    });
};

const get_prazos_pagamento = () => {
  return fetch(
    `${AppState.serverAddr}/relatorios/GetPrazosPagamento.php`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  )
    .then(response => {
      if (response.status === 401) {
        AppState.isLoading = false;
        browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        console.log('Unauthorized');
        return;
      }
      const res = response.json();
      return res;
    })
    .then(response => {
      return response.request
    })
    .catch(function(error) {
      throw (error)
    });
}

const get_faturas_em_aberto = () => {
  return fetch(
    `${AppState.serverAddr}/relatorios/GetFaturasEmAberto.php`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  )
    .then(response => {
      if (response.status === 401) {
        AppState.isLoading = false;
        browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        console.log('Unauthorized');
        return;
      }
      const res = response.json();
      return res;
    })
    .then(response => {
      return response.request[0]
    })
    .catch(function(error) {
      throw (error)
    });
}

const groupByTipoProjeto = function(array) {
  let result = [];
  array.reduce(function(res, value) {
    if (!res[value.tipo_projeto]) {
      res[value.tipo_projeto] = { tipo_projeto: value.tipo_projeto, receita: 0 };
      result.push(res[value.tipo_projeto])
    }
    res[value.tipo_projeto].receita += value.receita;
    return res;
  }, {});

  return result;
};

export const safe_division = (num, den) => {
  if (num && den && den > 0) {
    return num / den
  } else {
    return 0
  }
}