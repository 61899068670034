import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  Typography,
  Input,
  Chip,
  Switch,
  Dialog,
  DialogContent,
  Icon,
  withStyles,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import AppState from '../../state';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Utils } from '../../services';
import moment from 'moment';
import CurrencyInput from '../../components/lib/CurrencyInput';

import * as funcionarioAPI from '../../api/funcionarios';

const BigChip = withStyles({
  deleteIcon: {
    position: 'absolute',
    right: '0',
  },
})(Chip);

const styles = {
  halfGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
  },
};

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

const CPFMaskCustomEdit = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\w/,
        /\w/,
        /\w/,
        /\w/,
        /\w/,
      ]}
      guide={false}
    />
  );
};

const CurrencyMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  const numberMask = createNumberMask({
    prefix: 'R$ ',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: true,
  });

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={numberMask}
      guide={false}
    />
  );
};

const DateMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

const CelMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={true}
    />
  );
};

const TelMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={true}
    />
  );
};

const CEPMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

const UFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/]}
      guide={false}
    />
  );
};

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

CPFMaskCustomEdit.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

DateMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

CelMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TelMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

CEPMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

UFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.documentoInput = React.createRef();

    this.state = {
      edicao: false,
      cpf: '',
      cpfErro: false,
      nome: '',
      nomeErro: false,
      emailPessoal: '',
      emailPessoalErro: false,
      emailProfissional: '',
      emailProfissionalErro: false,
      tipoVinculoList: [],
      tipoVinculo: '',
      tipoVinculoErro: false,
      jornadaTrabalho: '',
      jornadaTrabalhoErro: false,
      dataDesligamento: '',
      dataDesligamentoErro: false,
      sexo: '',
      exclusivo: '',
      projectList: [],
      project: '',
      projectErro: false,
      projetos: [],
      projetosErro: false,
      celular: '',
      telefone: '',
      telefoneErro: false,
      cep: '',
      cepErro: false,
      endereco: '',
      enderecoErro: false,
      numeroEndereco: '',
      numeroEnderecoErro: false,
      complemento: '',
      bairro: '',
      bairroErro: false,
      cidade: '',
      cidadeErro: false,
      uf: '',
      ufErro: false,
      trabalhaEscritorio: 'true',
      criaUsuarioSistema: '',
      perfil: '',
      perfilList: [],
      perfilErro: false,
      matricula: '',
      matriculaErro: false,
      custoMensal: '',
      custoMensalErro: false,
      isActive: this.dataDesligamento == null || this.dataDesligamento == '',
      cargo: '',
      cargoErro: false,
      dtAdmissao: moment().format('DD/MM/YYYY'),
      documentacao: [],
      linkDocumento: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getTipoVinculo();
    this.getProjetos();
    this.getPerfis();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edicao != this.props.edicao) {
      this.setState(
        {
          edicao: this.props.edicao,
          isActive:
            this.dataDesligamento == null || this.dataDesligamento == '',
        },
        () => {
          if (this.props.edicao) {
            this.getFuncionarioData();
          }
        }
      );
    }
  }

  getTipoVinculo = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/funcionarios/GetTipoVinculoList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          tipoVinculoList: response.request,
        });
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjetos = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/projetos/GetProjects.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projectList: response.request,
        });
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPerfis = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/comum/GetPerfisAcesso.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          perfilList: response.request,
        });
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFuncionarioData = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/funcionarios/GetFuncionario.php?json={"cpf_funcionario":"${this.props.funcionario}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          return response.json();
        }
      })
      .then((response) => {
        AppState.isLoading = false;

        const linkDocumentacao = JSON.parse(response.request[0].link_documentacao) || [];
        console.log(linkDocumentacao)
        const documentacaoArray = linkDocumentacao.map((link) => {
          const nomeArquivo = link.split('/').pop();
          return { name: nomeArquivo, link };
        });

        this.setState(
          {
            nome: response.request[0].nome_funcionario || '',
            matricula: response.request[0].matricula_funcionario || '',
            cpf: response.request[0].cpf_funcionario || '',
            sexo: response.request[0].sexo || '',
            bairro: response.request[0].bairro_residencia || '',
            celular: response.request[0].celular || '',
            telefone: response.request[0].tel_residencia || '',
            emailPessoal: response.request[0].email_pessoal || '',
            emailProfissional: response.request[0].email_profissional || '',
            dtAdmissao: Utils.dateToPT(response.request[0].dt_admissao),
            dataDesligamento: Utils.dateToPT(response.request[0].dt_demissao),
            uf: response.request[0].uf_residencia || '',
            cep: response.request[0].cep_residencia || '',
            endereco: response.request[0].end_residencia || '',
            numeroEndereco: response.request[0].numero_residencia || '',
            complemento: response.request[0].complemento_residencia || '',
            cidade: response.request[0].cidade_residencia || '',
            tipoVinculo: response.request[0].tipo_vinculo || '',
            jornadaTrabalho: response.request[0].jornada_diaria_trabalho || '',
            custoMensal: parseFloat(response.request[0].custo_total_mensal),
            isActive: response.request[0].dt_demissao === null,
            cargo: response.request[0].cargo || '',
            linkDocumento: response.request[0].link_documentacao || '',
            documentacao: documentacaoArray,
          }
        );
      })
      .catch((error) => {
        AppState.isLoading = false;
        console.error('Erro ao buscar dados do funcionário:', error);
      });
  };


  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  statusHandleChange = (event) => {
    if (event.target.checked) {
      this.setState({
        isActive: event.target.checked,
        dataDesligamento: '',
      });
    } else {
      this.setState({
        isActive: event.target.checked,
        dataDesligamento: Utils.dateToPT(
          new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 10)
        ),
      });
    }
  };

  projetosHandleChange = (event) => {
    if (event.target.value && event.target.value.length > 0) {
      this.setState({
        projetos: event.target.value,
      });
    }
  };

  exclusivoHandleChange = (event) => {
    this.setState({
      project: '',
      projetos: [],
      exclusivo: event.target.value,
    });
  };

  closeDialog = () => {
    this.setState(
      {
        edicao: false,
        cpf: '',
        cpfErro: false,
        nome: '',
        nomeErro: false,
        emailPessoal: '',
        emailPessoalErro: false,
        emailProfissional: '',
        emailProfissionalErro: false,
        tipoVinculo: '',
        tipoVinculoErro: false,
        jornadaTrabalho: '',
        jornadaTrabalhoErro: false,
        dataDesligamento: '',
        dataDesligamentoErro: false,
        sexo: '',
        exclusivo: '',
        project: '',
        projectErro: false,
        projetos: [],
        projetosErro: false,
        celular: '',
        telefone: '',
        telefoneErro: false,
        cep: '',
        cargo: '',
        cargoErro: false,
        cepErro: false,
        endereco: '',
        enderecoErro: false,
        numeroEndereco: '',
        numeroEnderecoErro: false,
        complemento: '',
        cidade: '',
        bairro: '',
        bairroErro: false,
        cidadeErro: false,
        uf: '',
        ufErro: false,
        trabalhaEscritorio: 'true',
        criaUsuarioSistema: '',
        perfil: '',
        perfilErro: false,
        matricula: '',
        matriculaErro: false,
        custoMensal: '',
        custoMensalErro: false,
        dtAdmissao: moment().format('DD/MM/YYYY'),
        dtAdmissaoErro: false,
        documentacao: [],
        linkDocumento: '',
      },
      () => this.props.close()
    );
  };

  getEndereco = (cep) => {
    AppState.isLoading = true;
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          endereco: response.logradouro,
          bairro: response.bairro,
          cidade: response.localidade,
          uf: response.uf,
        });

        if (response.erro == true) {
          this.setState({
            endereco: '',
            bairro: '',
            cidade: '',
            uf: '',
          });
        }
      });
  };
  addFuncionario = () => {
    AppState.isLoading = true;


    const data = JSON.stringify({
      cpf: this.state.cpf,
      nome: this.state.nome,
      matricula: this.state.matricula,
      sexo: this.state.sexo,
      email_pessoal: this.state.emailPessoal,
      email_profissional: this.state.emailProfissional,
      cel: this.state.celular,
      tel_residencia: this.state.telefone,
      tipo_vinculo: this.state.tipoVinculo,
      jornada_horas: this.state.jornadaTrabalho,
      custo_mensal: Utils.moeda2float(this.state.custoMensal),
      dt_admissao: Utils.dateToEN(this.state.dtAdmissao),
      dt_desligamento: Utils.dateToEN(this.state.dataDesligamento),
      cep: this.state.cep,
      endereco: this.state.endereco,
      end_numero: this.state.numeroEndereco,
      end_bairro: this.state.bairro,
      end_complemento: this.state.complemento,
      cidade: this.state.cidade,
      uf: this.state.uf,
      perfil: this.state.perfil,
      projetos:
        this.state.projetos.length < 1
          ? [{ id_projeto: this.state.project }]
          : this.state.projetos,
      ativo: 'true',
      exclusivo: this.state.exclusivo,
      cargo: this.state.cargo,
    });

    console.log('Dados do funcionário em JSON:', data);


    const documentacaoData = new FormData();
    documentacaoData.append('json', data);


    (this.state.documentacao || []).forEach((file, index) => {
      if (file && file.file) {
        documentacaoData.append(`file_${index}`, file.file);
      }
    })


    funcionarioAPI
      .addFuncionario(documentacaoData)
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch((error) => {
        AppState.isLoading = false;
    
        if (!error || !error.response || !error.response.data) {
            alert('Erro desconhecido, tente novamente.');
            return;
        }
    
        const errorData = error.response.data;
        const errCode = errorData.code;        
        const errMessage = errorData.message || 'Erro desconhecido.';
    
        if (errCode) {
            alert(errMessage);
        } else {
            alert('Erro desconhecido, tente novamente.');
        }
    
        if (error.response.status === 401) {
            const logoutUrl = process.env.NODE_ENV === 'development' 
                ? "http://localhost:3000/logout" 
                : `${process.env.PUBLIC_URL}/logout`;
            window.location.href = logoutUrl;
        };
    });
  };    

  editFuncionario = () => {
    AppState.isLoading = true;

    const validLinks = Array.isArray(this.state.documentacao)
      ? this.state.documentacao
        .filter((doc) => !doc.preview || doc.link)
        .map((doc) => doc.link || '')
      : [];

    const data = JSON.stringify({
      cpf: this.state.cpf,
      nome: this.state.nome,
      matricula: this.state.matricula,
      sexo: this.state.sexo,
      email_pessoal: this.state.emailPessoal,
      email_profissional: this.state.emailProfissional,
      cel: this.state.celular,
      tel_residencia: this.state.telefone,
      projeto_vinculado: this.state.project,
      tipo_vinculo: this.state.tipoVinculo,
      jornada_horas: this.state.jornadaTrabalho,
      custo_mensal: Utils.moeda2float(this.state.custoMensal),
      dt_admissao: Utils.dateToEN(this.state.dtAdmissao),
      dt_desligamento: Utils.dateToEN(this.state.dataDesligamento),
      cep: this.state.cep,
      endereco: this.state.endereco,
      end_bairro: this.state.bairro,
      end_numero: this.state.numeroEndereco,
      end_complemento: this.state.complemento,
      cidade: this.state.cidade,
      uf: this.state.uf,
      perfil: this.state.perfil,
      projetos: this.state.projetos,
      cargo: this.state.cargo,
      link_documento: validLinks,
    });

    console.log('data==>', data);

    const documentoData = new FormData();
    documentoData.append('json', data);

    (this.state.documentacao || []).forEach((doc, index) => {
      if (doc.file) {
        documentoData.append(`file_${index}`, doc.file);
      }
    });

    funcionarioAPI
      .editFuncionario(documentoData)
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch((error) => {
        AppState.isLoading = false;

        if (!error || !error.response || !error.response.status) {
          alert('Erro desconhecido, tente novamente.');
          return;
        }

        const Err_status = error.response.status;

        switch (Err_status) {
          case 400:
            const ret = error.response.data?.status?.value || '';
            if (['40001', '40002'].includes(ret)) {
              alert('Dados inválidos.');
            } else if (ret === '40003') {
              alert('Erro ao Atualizar Funcionário.');
            } else if (ret === '40004') {
              alert('Erro ao reativar funcionário.');
            } else if (ret === '40005') {
              alert('CPF já cadastrado como fornecedor.');
            } else if (ret === '40006') {
              alert('Já existe um usuário com este CPF.');
            } else if (ret === '40007') {
              alert('Usuário não encontrado.');
            } else {
              alert('Dados inválidos.');
            }
            break;

          case 401:
            if (process.env.NODE_ENV === 'development') {
              window.location.href = "http://localhost:3000/logout"; // Localhost
            } else {
              window.location.href = `${process.env.PUBLIC_URL}/logout`; // Produção
            }
            break;

          case 500:
          default:
            alert('Erro desconhecido, tente novamente.');
        }
      });
  };



  validaForm = () => {
    let formValido = true;

    if (!this.state.cpf || this.state.cpf == '' || this.state.cpf.length < 11) {
      formValido = false;
      this.setState({
        cpfErro: true,
      });
    }

    if (!this.state.nome || this.state.nome == '') {
      formValido = false;
      this.setState({
        nomeErro: true,
      });
    }

    if (this.state.tipoVinculo == '') {
      formValido = false;
      this.setState({
        tipoVinculoErro: true,
      });
    }

    if (this.state.cargo == '') {
      formValido = false;
      this.setState({
        cargoErro: true,
      });
    }

    if (this.state.matricula == '') {
      formValido = false;
      this.setState({
        matriculaErro: true,
      });
    }

    if (!this.state.emailProfissional || this.state.emailProfissional == '') {
      formValido = false;
      this.setState({
        emailProfissionalErro: true,
      });
    }

    if (this.state.jornadaTrabalho == '') {
      formValido = false;
      this.setState({
        jornadaTrabalhoErro: true,
      });
    }

    if (!this.state.custoMensal || this.state.custoMensal == '') {
      formValido = false;
      this.setState({
        custoMensalErro: true,
      });
    }

    if (this.state.cep == '' || this.state.cep.length < 9) {
      formValido = false;
      this.setState({
        cepErro: true,
      });
    }

    if (this.state.endereco == '') {
      formValido = false;
      this.setState({
        enderecoErro: true,
      });
    }

    if (this.state.bairro == '') {
      formValido = false;
      this.setState({
        bairroErro: true,
      });
    }

    if (this.state.numeroEndereco == '') {
      formValido = false;
      this.setState({
        numeroEnderecoErro: true,
      });
    }

    if (this.state.cidade == '') {
      formValido = false;
      this.setState({
        cidadeErro: true,
      });
    }

    if (this.state.uf == '' || this.state.uf.length != 2) {
      formValido = false;
      this.setState({
        ufErro: true,
      });
    }

    if (this.state.sexo == '') {
      formValido = false;
      alert('Por favor informe o sexo do funcionário.');
      this.setState({
        sexoErro: true,
      });
    }

    if (
      !this.state.dtAdmissao ||
      !moment(this.state.dtAdmissao, 'DD/MM/YYYY', true).isValid()
    ) {
      formValido = false;
      this.setState({
        dtAdmissaoErro: true,
      });
    }

    if (!this.state.edicao) {
      if (this.state.perfil == '') {
        formValido = false;
        this.setState({
          perfilErro: true,
        });
      }

      if (this.state.exclusivo == 'true') {
        if (this.state.project == '') {
          formValido = false;
          this.setState({
            projectErro: true,
          });
        }
      } else {
        if (this.state.projetos.length <= 0) {
          formValido = false;
          this.setState({
            projetosErro: true,
          });
        }
      }
    }

    if (formValido) {
      if (this.state.edicao) {
        this.editFuncionario();
      } else {
        this.addFuncionario();
      }
    }
  };

  CEPhandleChange = (event) => {
    this.setState(
      {
        cep: event.target.value,
      },
      () => {
        if (this.state.cep.length === 9) {
          this.getEndereco(this.state.cep.replace('-', ''));
        }
      }
    );
  };

  deleteDocumentacaoChip = (index) => {
    const arr = [...this.state.documentacao];
    arr.splice(index, 1);

    this.setState({
      documentacao: arr,
      linkDocumento: arr.length > 0 ? this.state.linkDocumento : null,
    });
  };

  handleDocumentacaoFile = (event) => {
    event.preventDefault();
    const files = Array.from(this.documentoInput.current.files);
    const tenMbInKb = 25000;

    const validFiles = files.filter((file) => {
      const sizeInKb = file.size / 1000;
      if (sizeInKb > tenMbInKb) {
        alert(`O arquivo ${file.name} deve ser inferior a 25MB.`);
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {

      const filesWithPreview = validFiles.map((file) => ({
        name: file.name,
        size: file.size,
        file,
        preview: URL.createObjectURL(file),
      }));

      this.setState((prevState) => ({
        documentacao: [...(prevState.documentacao || []), ...filesWithPreview],
      }));
    }

    event.target.value = null;
  };



  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        // maxWidth= 'lg'
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent>
          <form id={this.constructor.name}>
            <Grid
              container={true}
              alignItems={'flex-start'}
              justify={'space-around'}
              direction={'row'}
              spacing={40}
            >
              <Grid
                item
                sm={10}
                container
                direction="row"
                justify="space-between"
                style={{ maxWidth: '100%', flexGrow: '1', flexBasis: 'auto', flexWrap: 'nowrap' }}
              >
                <Typography variant="h4">Cadastro de Funcionários</Typography>
                <Icon onClick={this.closeDialog}>close</Icon>
              </Grid>

              <Grid item md={4} style={{ width: '100%' }}>
                <Grid container spacing={16}>
                  <Grid item md={5}>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'CPF'}
                        name={'cpf'}
                        onChange={this.handleChange}
                        value={this.state.cpf}
                        error={this.state.cpfErro}
                        InputProps={{
                          inputComponent:
                            this.state.cpf.length > 14
                              ? CPFMaskCustomEdit
                              : CPFMaskCustom,
                          readOnly: this.props.edicao,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={7} style={{ marginTop: '10px' }}>
                    <Switch
                      value={this.state.isActive}
                      disabled={this.state.edicao === false}
                      color={'primary'}
                      checked={this.state.isActive}
                      onChange={this.statusHandleChange}
                    />
                    <InputLabel>
                      Funcionário {this.state.isActive ? 'Ativo' : 'Desligado'}
                    </InputLabel>
                  </Grid>
                </Grid>

                <FormControl fullWidth={true}>
                  <TextField
                    label={'Nome Completo'}
                    name={'nome'}
                    value={this.state.nome}
                    onChange={this.handleChange}
                    error={this.state.nomeErro}
                  />
                </FormControl>

                <Grid container spacing={16}>
                  <Grid item md={6} style={styles.halfGrid}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="type_of_link">
                        Tipo de Vínculo
                      </InputLabel>
                      <Select
                        value={this.state.tipoVinculo}
                        inputProps={{
                          name: 'tipoVinculo',
                        }}
                        error={this.state.tipoVinculoErro}
                        onChange={this.handleChange}
                      >
                        {this.state.tipoVinculoList.map((item, index) => (
                          <MenuItem value={item.tipo_vinculo} key={index}>
                            {item.tipo_vinculo}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Matrícula'}
                        name={'matricula'}
                        value={this.state.matricula}
                        onChange={this.handleChange}
                        error={this.state.matriculaErro}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl fullWidth={true}>
                  <TextField
                    type="text"
                    label={'Cargo'}
                    name={'cargo'}
                    value={this.state.cargo}
                    onChange={this.handleChange}
                    error={this.state.cargoErro}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    type="email"
                    label={'E-mail Pessoal'}
                    name={'emailPessoal'}
                    value={this.state.emailPessoal}
                    onChange={this.handleChange}
                    error={this.state.emailPessoalErro}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    type="email"
                    label={'E-mail Profissional'}
                    name={'emailProfissional'}
                    value={this.state.emailProfissional}
                    onChange={this.handleChange}
                    error={this.state.emailProfissionalErro}
                  />
                </FormControl>

                <Grid container spacing={16}>
                  <Grid item md={6} style={styles.halfGrid}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="type_of_link">
                        Jornada de Trabalho
                      </InputLabel>
                      <Select
                        value={this.state.jornadaTrabalho}
                        inputProps={{
                          name: 'jornadaTrabalho',
                        }}
                        error={this.state.jornadaTrabalhoErro}
                        onChange={this.handleChange}
                      >
                        <MenuItem value={2} key={1}>
                          02 Horas Diárias
                        </MenuItem>
                        <MenuItem value={3} key={2}>
                          03 Horas Diárias
                        </MenuItem>
                        <MenuItem value={4} key={3}>
                          04 Horas Diárias
                        </MenuItem>
                        <MenuItem value={5} key={4}>
                          05 Horas Diárias
                        </MenuItem>
                        <MenuItem value={6} key={5}>
                          06 Horas Diárias
                        </MenuItem>
                        <MenuItem value={7} key={6}>
                          07 Horas Diárias
                        </MenuItem>
                        <MenuItem value={8} key={7}>
                          08 Horas Diárias
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth={true}>
                      <CurrencyInput
                        label={'Custo Total Mensal'}
                        name={'custoMensal'}
                        value={this.state.custoMensal}
                        onChange={this.handleChange}
                        error={this.state.custoMensalErro}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl>
                  <FormLabel
                    style={{ display: 'inline-block', marginTop: '16px' }}
                  >
                    Sexo
                  </FormLabel>
                  <RadioGroup
                    label="sexo"
                    name="sexo"
                    row={true}
                    value={this.state.sexo}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio color={'primary'} />}
                      style={{ width: 'auto' }}
                      label="Masculino"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio color={'primary'} />}
                      style={{ width: 'auto' }}
                      label="Feminino"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item md={4} style={{ width: '100%' }}>
                <Grid container spacing={16} style={{ marginTop: '2px' }}>
                  <Grid item md={this.state.isActive === false ? 6 : 12}>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Data de Admissão'}
                        value={this.state.dtAdmissao}
                        name={'dtAdmissao'}
                        onChange={this.handleChange}
                        InputProps={{
                          inputComponent: DateMaskCustom,
                        }}
                        error={this.state.dtAdmissaoErro}
                      />
                    </FormControl>
                  </Grid>
                  {this.state.isActive === false && (
                    <Grid item md={6}>
                      <FormControl fullWidth={true}>
                        <TextField
                          label={'Data de Desligamento'}
                          value={this.state.dataDesligamento}
                          name={'dataDesligamento'}
                          InputProps={{
                            inputComponent: DateMaskCustom,
                          }}
                          onChange={this.handleChange}
                          error={this.state.dataDesligamentoErro}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>

                <Grid container spacing={16}>
                  <Grid item md={6} style={styles.halfGrid}>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Celular'}
                        name={'celular'}
                        value={this.state.celular}
                        onChange={this.handleChange}
                        InputProps={{ inputComponent: CelMaskCustom }}
                      />
                    </FormControl>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Telefone Residêncial'}
                        name={'telefone'}
                        value={this.state.telefone}
                        onChange={this.handleChange}
                        InputProps={{ inputComponent: TelMaskCustom }}
                        error={this.state.telefoneErro}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl fullWidth={true}>
                  <TextField
                    label={'CEP Residêncial'}
                    name={'cep'}
                    value={this.state.cep}
                    onChange={this.CEPhandleChange}
                    InputProps={{ inputComponent: CEPMaskCustom }}
                    error={this.state.cepErro}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    label={'Endereço'}
                    name={'endereco'}
                    value={this.state.endereco}
                    onChange={this.handleChange}
                    error={this.state.enderecoErro}
                  />
                </FormControl>

                <Grid container spacing={16}>
                  <Grid item md={6} style={styles.halfGrid}>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Número'}
                        name={'numeroEndereco'}
                        value={this.state.numeroEndereco}
                        onChange={this.handleChange}
                        error={this.state.numeroEnderecoErro}
                      />
                    </FormControl>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Complemento'}
                        name={'complemento'}
                        value={this.state.complemento}
                        onChange={this.handleChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl fullWidth={true}>
                  <TextField
                    label={'Bairro'}
                    name={'bairro'}
                    value={this.state.bairro}
                    onChange={this.handleChange}
                    error={this.state.bairroErro}
                  />
                </FormControl>

                <Grid container spacing={8}>
                  <Grid item md={6} style={styles.halfGrid}>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'Cidade'}
                        name={'cidade'}
                        value={this.state.cidade}
                        onChange={this.handleChange}
                        error={this.state.cidadeErro}
                      />
                    </FormControl>
                    <FormControl fullWidth={true}>
                      <TextField
                        label={'UF'}
                        name={'uf'}
                        value={this.state.uf}
                        onChange={this.handleChange}
                        error={this.state.ufErro}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} style={{ width: '100%' }}>
                {!this.state.edicao && (
                  <React.Fragment>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="profile">Perfil</InputLabel>
                      <Select
                        value={this.state.perfil}
                        inputProps={{
                          name: 'perfil',
                        }}
                        error={this.state.perfilErro}
                        onChange={this.handleChange}
                      >
                        {this.state.perfilList.map((item) => (
                          <MenuItem key={item.id_perfil} value={item.id_perfil}>
                            {item.desc_perfil}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ marginTop: '16px' }}
                    >
                      <FormLabel>
                        Funcionario exclusivo de um projeto?
                      </FormLabel>
                      <RadioGroup
                        name="exclusivo"
                        row={true}
                        value={this.state.exclusivo}
                        onChange={this.exclusivoHandleChange}
                      >
                        <FormControlLabel
                          value={'true'}
                          control={<Radio color={'primary'} />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value={'false'}
                          control={<Radio color={'primary'} />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  </React.Fragment>
                )}

                {this.state.exclusivo == 'true' && (
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="projetos">
                      Selecione o Projeto
                    </InputLabel>
                    <Select
                      error={this.state.projectErro}
                      value={this.state.project}
                      inputProps={{
                        name: 'project',
                      }}
                      onChange={this.handleChange}
                    // error={this.state.projectErro}
                    >
                      {this.state.projectList.map((item) => (
                        <MenuItem key={item.id_projeto} value={item.id_projeto}>
                          {item.display}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {this.state.exclusivo == 'false' && (
                  <FormControl fullWidth>
                    <InputLabel>Selecione os Projetos</InputLabel>
                    <Select
                      multiple
                      error={this.state.projetosErro}
                      value={this.state.projetos}
                      onChange={this.projetosHandleChange}
                      input={<Input id="select-multiple-chip" />}
                      style={{ wordWrap: 'break-word' }}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                this.state.projectList.filter(
                                  (item) => item.id_projeto == value.id_projeto
                                )[0].display
                              }
                              style={{
                                margin: '3px 3px',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            ></Chip>
                          ))}
                        </div>
                      )}
                    >
                      {this.state.projectList
                        .filter((projeto) => projeto.id_projeto != 0)
                        .map((item) => (
                          <MenuItem key={item.id_projeto} value={item}>
                            {item.display}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                <InputLabel
                  style={{
                    fontSize: '18px',
                    display: 'block',
                    marginTop: '8px',
                  }}
                >
                  Documentos
                </InputLabel>
                <label
                  htmlFor="outlined-contrato-file"
                  style={{ width: '100%', marginTop: '8px' }}
                >
                  <input
                    id="outlined-contrato-file"
                    type="file"
                    style={{ display: 'none' }}
                    ref={this.documentoInput}
                    onChange={this.handleDocumentacaoFile}
                    multiple
                  />
                  <Button
                    variant="outlined"
                    component="span"
                    fullWidth={true}

                  >
                    Anexar Documentação
                  </Button>
                </label>
                {console.log(this.state.documentacao)}
                {this.state.documentacao.length > 0 &&
                  this.state.documentacao.map((item, index) => {
                    const link = item.preview || item.link || this.state.linkDocumento;

                    return (
                      <BigChip
                        key={index}
                        style={{
                          margin: '3px 3px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          position: 'relative',
                        }}
                        label={
                          <span>
                            <a
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => {
                                if (!/^https?:\/\//.test(link) && !/^blob:/.test(link)) {
                                  e.preventDefault();
                                  console.error("O link fornecido não é uma URL válida:", link);
                                }
                              }}
                            >
                              {item.name}
                            </a>
                          </span>
                        }
                        clickable={Boolean(link)}
                        onDelete={() => this.deleteDocumentacaoChip(index)}
                      />
                    );
                  })}




              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: '2em' }}
              container={true}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.validaForm}
              >
                Salvar
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Form;
