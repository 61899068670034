import React from 'react';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import { Utils } from '../../../services';
import ReactTable from 'react-table';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';

const schema = {
  'Data (ex: 30/01/2019)': {
    prop: 'data',
    type: Date,
    required: false,
  },
  'Valor do índice (ex:1.52453)': {
    prop: 'indice',
    type: Number,
    required: false,
  },
};

class ImporteIndices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabelaIndice: [],
    };
  }

  componentDidMount() {
    this.getTabelaIndice();
  }

  closeDialog = () => {
    this.props.close();
  };

  getTabelaIndice = () => {
    AppState.isLoading = true;

    fetch(
      `${AppState.serverAddr}/relatorios/GetTabelaIndice.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          AppState.isLoading = false;
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
          return;
        }
        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          tabelaIndice: response.request ? response.request : [],
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        console.log('Erro de api!', error);
      });
  };

  importHandleChange = (event) => {
    if (event.target.files.length) {
      AppState.isLoading = true;
      const input = event.target;
      readXlsxFile(input.files[0], { schema }).then(({ rows }) => {
        let errors = [];
        let ret = [];
        let dateList = [];
        // const firstDayLastMonth = moment()
        //   .subtract(1, "month")
        //   .startOf("month");
        // const lastDayCurrentMonth = moment().endOf("month");

        rows.forEach(function (entry, index) {
          if (entry.data) {
            //formata a data para o formato YYYY-MM-DD

            const data = moment(entry.data);
            entry.data = data.format('YYYY-MM-DD');

            // if (!data.isBetween(firstDayLastMonth, lastDayCurrentMonth)) {
            //   errors.push(
            //     "A data não faz parte do mês corrente ou mês passado na linha " +
            //       (index + 3)
            //   );
            // }

            if (
              dateList.filter((date) => date === data.format('DD/MM/YYYY'))
                .length == 0
            ) {
              dateList.push(data.valueOf());
            } else {
              errors.push('Data repetida na linha ' + (index + 3));
            }

            if (!entry.indice) {
              errors.push('Indice inválido/vazio na linha ' + (index + 3));
            }

            ret.push(entry);
          } else {
            errors.push('Data inválida/vazia na linha ' + (index + 3));
          }
        });

        input.value = null;

        if (errors.length > 0) {
          AppState.isLoading = false;
          let str = '';
          errors.forEach(function (v) {
            str += v + '\n';
          });
          input.value = '';
          alert('Os seguintes erros foram encontrados:\n' + str);
        } else {
          let str = '';
          dateList.sort().forEach((date) => {
            str += moment(date).format('DD/MM/YYYY') + '\n';
          });
          if (
            window.confirm('Confirmar envio de indices dos dias \n' + str + '?')
          ) {
            this.importaIndices(ret);
          } else {
            AppState.isLoading = false;
          }
        }
      });
    }
  };

  importaIndices = (dados) => {
    const data = JSON.stringify({
      dados,
    });

    fetch(
      `${AppState.serverAddr}/relatorios/ImportaIndices.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.getTabelaIndice();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40002') {
            console.log('40002 Data já cadastrada');
            alert('Data já cadastrada no sistema');
          } else if (ret === '40003') {
            console.log('40003 Erro ao importar os índices.');
            alert('Erro ao importar os novos índices.');
          } else if (ret === '40004') {
            console.log('40004 Tabela Vazia.');
            alert('A tabela que você tentou importar está vazia.');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  render() {
    const { tabelaIndice } = this.state;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <Grid container style={{ padding: '24px 24px' }}>
          <Grid container>
            <Grid container>
              <Grid
                item
                sm={10}
                container
                direction="row"
                justify="space-between"
                style={{
                  maxWidth: '100%',
                  flexGrow: '1',
                  flexBasis: 'auto',
                  flexWrap: 'nowrap',
                }}
              >
                <Typography variant="h4">Gerenciamento de Índices</Typography>
                <Icon onClick={() => this.closeDialog()}>close</Icon>
              </Grid>

              <Grid
                container
                justify={'flex-end'}
                style={{ marginTop: '32px' }}
              >
                <Grid item>
                  <a
                    href={`${process.env.PUBLIC_URL}/${AppState.serverAddr}/arquivos/tabela_indice_tamplate.xlsx`}
                    style={{ display: 'block', marginBottom: '8px' }}
                  >
                    Baixar Modelo
                  </a>
                  <label htmlFor="raised-button-file" style={{ width: '100%' }}>
                    <input
                      id="raised-button-file"
                      accept={'.xlsx, .xls'}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={this.importHandleChange}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Importe o modelo
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <ReactTable
                filterable
                fullWidth
                className="-striped -highlight"
                style={{ marginTop: '16px', width: '100%', height: '370px' }}
                data={tabelaIndice}
                defaultPageSize={100}
                showPagination={true}
                defaultFilterMethod={(filter, row) =>
                  filter.id === 'data'
                    ? Utils.dateToPT(String(row[filter.id])).startsWith(
                        filter.value.toLowerCase()
                      )
                    : String(row[filter.id])
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase())
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: 'Data',
                        accessor: 'data',
                        Cell: (row) => (
                          <div style={{ textAlign: 'center' }}>
                            {Utils.dateToPT(row.value)}
                          </div>
                        ),
                      },

                      {
                        Header: 'Indice',
                        accessor: 'indice',
                        filterable: false,
                        Cell: (row) => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                      },
                    ],
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

ImporteIndices.propTypes = {};

export default ImporteIndices;
