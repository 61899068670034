import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Switch,
  Button,
  DialogContent,
  Dialog,
  Typography,
  Icon,
  Input,
  Chip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppState from '../../../state';
import { browserHistory } from 'react-router';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Utils } from '../../../services';
import moment from 'moment';
import NewSelect from 'react-select';

import CurrencyInput from '../../../components/lib/CurrencyInput';
import * as projetoAPI from '../../../api/projetos';
import * as contratoAPI from '../../../api/contratos';

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};
CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CPFMaskCustomEdit = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\w/,
        /\w/,
        /\w/,
        /\w/,
        /\w/,
      ]}
      guide={false}
    />
  );
};
CPFMaskCustomEdit.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CelMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={true}
    />
  );
};
CelMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const DateMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

DateMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const BigChip = withStyles({
  deleteIcon: {
    position: 'absolute',
    right: '0',
  },
})(Chip);

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.aditivoInput = React.createRef();
    this.contratoInput = React.createRef();

    this.state = {
      idContrato: this.props.contrato,
      dialog: false,
      anexoAditivoLabel: 'Anexar Aditivo',
      fornecedor: null,
      tipoVinculo: '',
      tipoContrato: '',
      inicioVigencia: Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ),
      vencimentoVigencia: '',
      fimVigencia: '',
      fornecedorList: [],
      jornadaDiaria: '',
      projectList: [],
      exclusivo: '',
      valorContrato: '',
      valorTotalContrato: '',
      isActive: true,
      paymentModeList: [],
      paymentMode: '',
      objetoContrato: '',
      frequenciaPagamento: '',
      tipoContratoList: [],
      tipoVinculoList: [],
      cpf: '',
      emailProfissional: '',
      nome: '',
      celular: '',
      perfil: '',
      perfilList: [],
      project: '',
      valorAditivo: '',
      vencimentoAditivo: '',
      anexoAditivo: '',
      anexocontrato: '',
      aditivos: [],
      contrato: [],
      linkContrato: null,
      descricaoCurta: '',
      usuario: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getContratoData();
    this.getFornecedores();
    this.getProjetos();
    this.getPaymentModes();
    this.getTipoContrato();
    this.getTipoVinculo();
    this.getPerfis();
  }

  handleAditivoFile = (event) => {
    event.preventDefault();

    if (this.aditivoInput.current.files[0]) {
      this.setState({
        anexoAditivoLabel: this.aditivoInput.current.files[0].name,
        anexoAditivo: this.aditivoInput.current.files[0],
      });
    }

    event.target.value = null;
  };

  handleContratoFile = (event) => {
    event.preventDefault();

    if (this.contratoInput.current.files[0]) {
      this.setState({
        contrato: [this.contratoInput.current.files[0]],
      });
    }

    event.target.value = null;
  };

  tipoContratoHandleChange = (event) => {
    this.setState({
      tipoContrato: event.target.value,
      jornadaDiaria: '',
      exclusivo: '',
      project: '',
      projetos: [],
      perfil: '',
      cpf: '',
      nome: '',
      celular: '',
      emailProfissional: '',
    });
  };

  getTipoContrato = () => {
    fetch(
      `${AppState.serverAddr}/contratos/GetTipoContratos.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          tipoContratoList: response.request,
        });

        if (response.request[0].id_projeto_vinculado === null) {
          this.setState({
            exclusivo: 'false',
            projetos: [],
          });
        } else {
          this.setState({
            exclusivo: 'true',
            project: response.request[0].id_projeto_vinculado,
          });
        }
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 Erro Desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTipoVinculo = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/funcionarios/GetTipoVinculoList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          tipoVinculoList: response.request,
        });
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getPerfis = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/comum/GetPerfisAcesso.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          perfilList: response.request,
        });
      })

      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  addContrato = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf_cnpj_fornecedor: this.state.fornecedor.value,
      tipo_contrato: this.state.tipoContrato,
      inicio_vigencia: Utils.dateToEN(this.state.inicioVigencia),
      fim_vigencia:
        this.state.fimVigencia === ''
          ? null
          : Utils.dateToEN(this.state.fimVigencia),
      tipo_vinculo: this.state.tipoVinculo,
      jornada_diaria_trabalho:
        this.state.jornadaDiaria === '' ? null : this.state.jornadaDiaria,
      valor_total_contrato: this.moeda2float(this.state.valorTotalContrato),
      valor_base_contrato: this.moeda2float(this.state.valorContrato),
      objeto_do_contrato: this.state.objetoContrato,
      projetos:
        this.state.projetos.length > 0
          ? this.state.projetos
          : [{ id_projeto: this.state.project }],
      ativo: 'true',
      dt_vencimento: Utils.dateToEN(this.state.vencimentoVigencia),
      exclusivo: this.state.exclusivo,
      aditivos: this.state.aditivos,
      cpf_PJ: this.state.cpf,
      nome_PJ: this.state.nome,
      email_PJ: this.state.emailProfissional,
      cel_PJ: this.state.celular,
      perfil: this.state.perfil,
      desc_curta: this.state.descricaoCurta,
    });

    var contratoData = new FormData();
    contratoData.append('json', data);
    contratoData.append('file', this.state.contrato[0]);

    this.state.aditivos.forEach((item, index) => {
      contratoData.append('aditivo' + index, item.anexoAditivo);
    });

    fetch(
      `${AppState.serverAddr}/contratos/AddContrato.php`,
      {
        method: 'POST',
        headers: {
          Authorization: AppState.token,
        },
        body: contratoData,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        const Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          const ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40004') {
            console.log(
              '40004 - Erro ao tentar cadastrar PJ. Já cadastrado como funcionário'
            );
            alert('Erro ao cadastrar PJ. Já cadastrado como funcionário');
          } else if (ret === '40005') {
            console.log('40005 - PJ já cadastrado');
            alert('Erro ao cadastrar. PJ já cadastrado');
          } else if (ret === '40009') {
            alert(
              'Erro ao cadastrar. Fornecedor selecionado não está ativo ou aprovado'
            );
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getContratoData = () => {
    AppState.isLoading = true;

    if (!this.props.contrato) {
      return;
    }

    contratoAPI
      .getContratoById(this.props.contrato)
      .then((response) => {
        const { fornecedor } = response.request[0];
        const labelFornecedor =
          fornecedor.aprovacao_workflow === 'aprovado' &&
          fornecedor.status === 't'
            ? fornecedor.nome_lista
            : `${fornecedor.nome_lista} (Inabilitado)`;

        this.setState(
          {
            nome: response.request[0].nome_pj,
            cpf: response.request[0].cpf_pj,
            fornecedor: {
              label: labelFornecedor,
              value: response.request[0].fornecedor.cpf_cnpj_fornecedor,
            },
            tipoContrato: response.request[0].tipo_contrato,
            tipoVinculo: response.request[0].tipo_contrato,
            inicioVigencia: Utils.dateToPT(response.request[0].inicio_vigencia),
            fimVigencia: Utils.dateToPT(response.request[0].fim_vigencia),
            vencimentoVigencia: Utils.dateToPT(
              response.request[0].dt_encerramento
            ),
            jornadaDiaria: response.request[0].jornada_diaria_trabalho,
            valorContrato: Utils.float2moeda(
              response.request[0].valor_base_contrato
            ),
            valorTotalContrato: Utils.float2moeda(
              response.request[0].valor_total_contrato
            ),
            isActive: response.request[0].fim_vigencia === null ? true : false,
            objetoContrato: response.request[0].objeto_do_contrato,
            linkContrato: response.request[0].link_contrato,
            aditivos: response.request[0].aditivos,
            descricaoCurta: response.request[0].descricao_curta,
            usuario: response.request[0].usuario,
          },
          () => {
            if (this.state.linkContrato) {
              this.setState({
                contrato: [{ name: 'Contrato Anexado' }],
              });
            }

            AppState.isLoading = false;
          }
        );
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('40001 Erro Desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getProjetos = () => {
    AppState.isLoading = true;
    projetoAPI
      .getProjetos()
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          projectList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getFornecedores = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/fornecedores/GetFornecedores.php?campo_ordenacao=nome_lista&ordem_ordenacao=ASC`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          fornecedorList: response.request.map((item) => {
            const label =
              item.aprovacao_workflow === 'aprovado' && item.status === 't'
                ? item.nome_lista
                : `${item.nome_lista} (Inabilitado)`;

            return {
              label,
              value: item.cpf_cnpj_fornecedor,
            };
          }),
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 - Erro desconhecido');
          } else alert('Erro ao processar, por favor tente novamente');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  projetosHandleChange = (event) => {
    if (event.target.value && event.target.value.length > 0) {
      this.setState({
        projetos: event.target.value,
      });
    }
  };

  exclusivoHandleChange = (event) => {
    this.setState({
      exclusivo: event.target.value,
      project: '',
      projetos: [],
    });
  };

  closeDialog = () => {
    this.setState(
      {
        fornecedor: '',
        tipoVinculo: '',
        tipoContrato: '',
        anexoAditivoLabel: 'Anexar Aditivo',
        inicioVigencia: Utils.dateToPT(
          new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 10)
        ),
        vencimentoVigencia: '',
        fimVigencia: '',
        jornadaDiaria: '',
        exclusivo: '',
        valorContrato: '',
        valorTotalContrato: '',
        isActive: true,
        paymentMode: '',
        objetoContrato: '',
        frequenciaPagamento: '',
        cpf: '',
        emailProfissional: '',
        nome: '',
        celular: '',
        perfil: '',
        valorAditivo: '',
        vencimentoAditivo: '',
        fornecedorErro: false,
        tipoContratoErro: false,
        inicioVigenciaErro: false,
        valorContratoErro: false,
        valorTotalContratoErro: false,
        vencimentoVigenciaErro: false,
        frequenciaPagamentoErro: false,
        paymentModeErro: false,
        objetoContratoErro: false,
        exclusivoErro: false,
        projectErro: false,
        projetosErro: false,
        perfilErro: false,
        cpfErro: false,
        nomeErro: false,
        emailProfissionalErro: false,
        jornadaDiariaErro: false,
        celularErro: false,
        valorAditivoErro: false,
        vencimentoAditivoErro: false,
        descricaoCurtaErro: false,
        aditivos: [],
        contrato: [],
        linkContrato: null,
        descricaoCurta: '',
        usuario: null,
      },
      () => this.props.close()
    );
  };

  getPaymentModes = () => {
    fetch(
      `${AppState.serverAddr}/lancamentos/GetFormaPagamentoList.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          paymentModeList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Erro desconhecido');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  statusHandleChange = (event) => {
    let fimVigencia = '';

    if (!event.target.checked) {
      fimVigencia = Utils.dateToPT(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      );
    }

    this.setState({
      isActive: event.target.checked,
      fimVigencia,
    });
  };

  editContrato = () => {
    const data = JSON.stringify({
      nome_pj: this.state.nome,
      id_contrato: this.props.contrato,
      cpf_cnpj_fornecedor: this.state.fornecedor.value,
      cpf_pj: this.state.cpf,
      tipo_contrato: this.state.tipoContrato,
      inicio_vigencia: Utils.dateToEN(this.state.inicioVigencia),
      dt_fim_vigencia:
        this.state.fimVigencia === ''
          ? null
          : Utils.dateToEN(this.state.fimVigencia),
      tipo_vinculo: this.state.tipoVinculo,
      jornada_diaria_trabalho:
        this.state.jornadaDiaria === '' ? null : this.state.jornadaDiaria,
      valor_total_contrato: this.moeda2float(this.state.valorTotalContrato),
      valor_base_contrato: this.moeda2float(this.state.valorContrato),
      objeto_do_contrato: this.state.objetoContrato,
      ativo: this.state.isActive,
      dt_vencimento: Utils.dateToEN(this.state.vencimentoVigencia),
      aditivos: this.state.aditivos,
      desc_curta: this.state.descricaoCurta,
      link_contrato: this.state.linkContrato,
    });

    const { usuario } = this.state;

    if (this.state.fimVigencia !== '') {
      if (usuario && usuario.ativo === 't') {
        alert(
          'Ao desativar o contrato, o usuário vinculado será desativado e perderá o acesso ao sistema'
        );
      }
    } else {
      if (usuario && usuario.ativo === 'f') {
        alert(
          'Ao ativar o contrato, o usuário vinculado será ativado e ganhará o acesso ao sistema novamente'
        );
      }
    }

    AppState.isLoading = true;

    var contratoData = new FormData();
    contratoData.append('json', data);
    contratoData.append('file', this.state.contrato[0]);

    this.state.aditivos.forEach((item, index) => {
      contratoData.append('aditivo' + index, item.anexoAditivo);
    });

    fetch(
      `${AppState.serverAddr}/contratos/EditContrato.php`,
      {
        method: 'POST',
        headers: {
          Authorization: AppState.token,
        },
        body: contratoData,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001' || ret === '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret === '40003') {
            console.log('40003 - Erro ao atualizar Contrato');
            alert('Erro ao atualizar Contrato');
          } else if (ret === '40004') {
            console.log('40004 - Erro ao reativar Contrato');
            alert('Erro ao reativar Contrato');
          } else if (ret === '40005') {
            console.log('40005 - CPF já cadastrado como funcionário');
            alert('CPF já cadastrado como funcionário');
          } else if (ret === '40006') {
            console.log('40006 - Já existe um usuário com este cpf');
            alert('Já existe um usuário com este cpf');
          } else if (ret === '40007') {
            console.log('40007 - Usuário não encontrado');
            alert('Usuário não encontrado');
          } else if (ret === '40008') {
            console.log('40008 - Erro ao atualizar aditivos');
            alert('Erro ao atualizar aditivos');
          } else if (ret === '40009') {
            console.log('40009 - Fornecedor inválido');
            alert(
              'Erro ao editar o Contrato. Fornecedor selecionado não está ativo ou aprovado.'
            );
          } else if (ret === '40010') {
            console.log('40010 - Erro ao inserir aditivos');
            alert('Erro ao inserir aditivos');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  isTamanhoAnexosPermitido = () => {
    const tamanhoContratoInKb =
      this.state.contrato[0] && this.state.contrato[0].size
        ? this.state.contrato[0].size / 1000
        : 0;
    const tamanhoAditivosInKb = this.state.aditivos.reduce(
      (acc, { anexoAditivo = 0 }) => {
        return (acc += anexoAditivo.size ? anexoAditivo.size / 1000 : 0);
      },
      0
    );

    const limitePermitidoInKb = 25000;
    return tamanhoContratoInKb + tamanhoAditivosInKb <= limitePermitidoInKb;
  };

  validaForm = () => {
    let formValido = true;

    if (!this.state.fornecedor || this.state.fornecedor === '') {
      formValido = false;
      this.setState({
        fornecedorErro: true,
      });
    }

    if (!this.state.tipoContrato || this.state.tipoContrato === '') {
      formValido = false;
      this.setState({
        tipoContratoErro: true,
      });
    }

    if (!this.state.inicioVigencia || this.state.inicioVigencia === '') {
      formValido = false;
      this.setState({
        inicioVigenciaErro: true,
      });
    } else if (this.state.inicioVigencia !== '') {
      const date = moment(Utils.dateToEN(this.state.inicioVigencia));
      if (!date.isValid()) {
        formValido = false;
        this.setState({
          inicioVigenciaErro: true,
        });
      }
    }

    if (!this.state.descricaoCurta || this.state.descricaoCurta === '') {
      formValido = false;
      this.setState({
        descricaoCurtaErro: true,
      });
    }

    if (
      !this.state.valorTotalContrato ||
      this.state.valorTotalContrato === ''
    ) {
      formValido = false;
      this.setState({
        valorTotalContratoErro: true,
      });
    }

    if (
      !this.state.vencimentoVigencia ||
      this.state.vencimentoVigencia === ''
    ) {
      formValido = false;
      this.setState({
        vencimentoVigenciaErro: true,
      });
    } else if (this.state.vencimentoVigencia !== '') {
      const date = moment(Utils.dateToEN(this.state.vencimentoVigencia));
      if (!date.isValid()) {
        formValido = false;
        this.setState({
          vencimentoVigenciaErro: true,
        });
      }
    }

    if (!this.state.valorContrato || this.state.valorContrato === '') {
      formValido = false;
      this.setState({
        valorContratoErro: true,
      });
    }

    if (!this.state.objetoContrato || this.state.objetoContrato === '') {
      formValido = false;
      this.setState({
        objetoContratoErro: true,
      });
    }

    if (this.props.edicao === false && this.state.tipoContrato === 'PJ') {
      if (!this.state.exclusivo || this.state.exclusivo === '') {
        formValido = false;
        this.setState({
          exclusivoErro: true,
        });
      }

      if (this.state.exclusivo === 'true') {
        if (!this.state.projetos || this.state.project === '') {
          formValido = false;
          this.setState({
            projectErro: true,
          });
        }
      } else {
        if (!this.state.projetos || this.state.projetos === '') {
          formValido = false;
          this.setState({
            projetosErro: true,
          });
        }
      }

      if (!this.state.perfil || this.state.perfil === '') {
        formValido = false;
        this.setState({
          perfilErro: true,
        });
      }

      if (!this.state.cpf || this.state.cpf === '' || this.state.length <= 14) {
        formValido = false;
        this.setState({
          cpfErro: true,
        });
      }

      if (!this.state.nome || this.state.nome === '') {
        formValido = false;
        this.setState({
          nomeErro: true,
        });
      }

      if (
        !this.state.emailProfissional ||
        this.state.emailProfissional === ''
      ) {
        formValido = false;
        this.setState({
          emailProfissionalErro: true,
        });
      }

      if (!this.state.jornadaDiaria || this.state.jornadaDiaria === '') {
        formValido = false;
        this.setState({
          jornadaDiariaErro: true,
        });
      }

      if (!this.state.celular || this.state.celular === '') {
        formValido = false;
        this.setState({
          celularErro: true,
        });
      }
    }

    if (!this.isTamanhoAnexosPermitido()) {
      formValido = false;
      alert('O limite máximo dos anexos não pode ultrapassar os 25mb.');
    }

    // if (!(Array.isArray(this.state.contrato) && this.state.contrato.length)) {
    //   formValido = false;
    //   alert("Por favor anexe um contrato.");
    // }

    if (formValido) {
      if (this.props.edicao === true) {
        this.editContrato();
      } else if (this.props.edicao === false) {
        this.addContrato();
      }
    }
  };

  moeda2float = (moeda) => {
    if (moeda === '') {
      return '';
    } else {
      moeda = moeda.replace('R$ ', '');
      moeda = moeda.replace(/\./g, '');
      moeda = moeda.replace(/,/g, '.');
      return parseFloat(moeda);
    }
  };

  float2moeda = (moeda) => {
    if (moeda === '' || moeda === 0 || moeda === null) {
      return '';
    } else {
      return moeda.replace(/\./g, ',');
    }
  };

  validaAditivo = () => {
    let aditivoValido = true;

    if (!this.state.valorAditivo || !this.state.valorAditivo) {
      aditivoValido = false;
      this.setState({
        valorAditivoErro: true,
      });
    }

    if (!this.state.vencimentoAditivo || !this.state.vencimentoAditivo) {
      aditivoValido = false;
      this.setState({
        vencimentoAditivoErro: true,
      });
    } else if (this.state.vencimentoAditivo !== '') {
      const date = moment(Utils.dateToEN(this.state.vencimentoAditivo));
      if (!date.isValid()) {
        aditivoValido = false;
        this.setState({
          vencimentoAditivoErro: true,
        });
      }
    }

    if (aditivoValido) {
      this.criaAditivo();
    }
  };

  criaAditivo = () => {
    this.setState({
      valorAditivo: '',
      vencimentoAditivo: '',
      anexoAditivo: '',
      anexoAditivoLabel: 'Anexar Aditivo',
      aditivos: [
        ...this.state.aditivos,
        {
          id_aditivo: null,
          valor: this.moeda2float(this.state.valorAditivo),
          dt_fim_vigencia: Utils.dateToEN(this.state.vencimentoAditivo),
          anexoAditivo: this.state.anexoAditivo,
        },
      ],
    });
  };

  deleteContratoChip = (index) => {
    let arr = this.state.contrato;

    arr.splice(index, 1);

    this.setState({
      contrato: arr,
      linkContrato: null,
    });
  };

  deleteAditivoChip = (index) => {
    let arr = this.state.aditivos;

    arr.splice(index, 1);

    this.setState({
      aditivos: arr,
    });
  };

  fornecedorHandleChange = (value) => {
    this.setState({
      fornecedor: value,
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent style={{ paddingTop: '16px' }}>
          <Grid
            container={true}
            alignItems={'flex-start'}
            justify={'space-around'}
            direction={'row'}
            spacing={32}
          >
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4">Cadastro de Contrato</Typography>
              <Icon onClick={this.closeDialog}>close</Icon>
            </Grid>
            <Grid item={true} md={6} style={{ width: '100%' }}>
              <InputLabel>Status: </InputLabel>
              <FormControlLabel
                style={{ marginLeft: '5px' }}
                control={
                  <Switch
                    value={this.state.isActive}
                    disabled={this.props.edicao === false}
                    color={'primary'}
                    checked={this.state.isActive === true}
                    onChange={this.statusHandleChange}
                  />
                }
                label={
                  'Contrato ' + (this.state.isActive ? 'Vigênte' : 'Encerrado')
                }
              />

              <InputLabel style={{ display: 'block' }}>Fornecedor</InputLabel>
              <NewSelect
                value={this.state.fornecedor}
                onChange={this.fornecedorHandleChange}
                options={this.state.fornecedorList}
                placeholder={'Selecione ou Digite...'}
              />

              <FormControl fullWidth={true}>
                <InputLabel>Tipo de Contrato</InputLabel>
                <Select
                  fullWidth
                  value={this.state.tipoContrato}
                  inputProps={{
                    name: 'tipoContrato',
                  }}
                  readOnly={!!this.state.idContrato}
                  onChange={this.tipoContratoHandleChange}
                  error={this.state.tipoContratoErro}
                >
                  {this.state.tipoContratoList.map((item, index) => (
                    <MenuItem value={item.tipo_contrato} key={index}>
                      {item.tipo_contrato}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {this.state.tipoContrato === 'PJ' && (
                <FormControl fullWidth={true}>
                  <TextField
                    label={'CPF do PJ'}
                    name={'cpf'}
                    onChange={this.handleChange}
                    value={this.state.cpf}
                    error={this.state.cpfErro}
                    InputProps={{
                      inputComponent:
                        this.state.cpf.length > 14
                          ? CPFMaskCustomEdit
                          : CPFMaskCustom,
                      readOnly: this.props.edicao,
                    }}
                  />
                </FormControl>
              )}

              {this.state.tipoContrato === 'PJ' && (
                <FormControl fullWidth={true}>
                  <TextField
                    label={'Nome Completo do PJ'}
                    name={'nome'}
                    value={this.state.nome}
                    onChange={this.handleChange}
                    error={this.state.nomeErro}
                  />
                </FormControl>
              )}

              <FormControl fullWidth={true}>
                <TextField
                  label={'Data de Inicio da Vigência'}
                  value={this.state.inicioVigencia}
                  name={'inicioVigencia'}
                  InputProps={{
                    inputComponent: DateMaskCustom,
                  }}
                  onChange={this.handleChange}
                  error={this.state.inicioVigenciaErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Data de Vencimento Esperada'}
                  value={this.state.vencimentoVigencia}
                  name={'vencimentoVigencia'}
                  InputProps={{
                    inputComponent: DateMaskCustom,
                  }}
                  onChange={this.handleChange}
                  error={this.state.vencimentoVigenciaErro}
                />
              </FormControl>

              {this.state.isActive === false && (
                <FormControl fullWidth={true}>
                  <TextField
                    label={'Data do Fim da Vigência'}
                    value={this.state.fimVigencia}
                    name={'fimVigencia'}
                    InputProps={{
                      inputComponent: DateMaskCustom,
                    }}
                    onChange={this.handleChange}
                    error={this.state.fimVigenciaErro}
                  />
                </FormControl>
              )}

              <FormControl fullWidth={true}>
                <CurrencyInput
                  label={'Valor Base do Contrato'}
                  name={'valorContrato'}
                  value={this.state.valorContrato}
                  error={this.state.valorContratoErro}
                  onChange={this.handleChange}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <CurrencyInput
                  label={'Valor Total do Contrato'}
                  name={'valorTotalContrato'}
                  value={this.state.valorTotalContrato}
                  error={this.state.valorTotalContratoErro}
                  onChange={this.handleChange}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Descrição Curta'}
                  name={'descricaoCurta'}
                  value={this.state.descricaoCurta}
                  error={this.state.descricaoCurtaErro}
                  onChange={this.handleChange}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  style={{ marginTop: '24px' }}
                  variant="outlined"
                  label={'Objeto do contrato'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name={'objetoContrato'}
                  value={this.state.objetoContrato}
                  onChange={this.handleChange}
                  multiline={true}
                  rows="2"
                  error={this.state.objetoContratoErro}
                />
              </FormControl>
            </Grid>
            <Grid item={true} md={6} style={{ width: '100%' }}>
              <Grid container spacing={16}>
                <Grid item md={3}>
                  <InputLabel style={{ fontSize: '18px', marginTop: '30px' }}>
                    Contratos
                  </InputLabel>
                </Grid>
                <Grid item md={9}>
                  <label
                    htmlFor="outlined-contrato-file"
                    style={{ marginTop: '21px', width: '100%' }}
                  >
                    <input
                      id="outlined-contrato-file"
                      type="file"
                      style={{ display: 'none' }}
                      ref={this.contratoInput}
                      onChange={this.handleContratoFile}
                      disabled={this.state.contrato.length > 0}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth={true}
                      disabled={this.state.contrato.length > 0}
                    >
                      Anexar Contrato
                    </Button>
                  </label>
                </Grid>
              </Grid>

              {this.state.contrato.map((item, index) => (
                <BigChip
                  key={index}
                  style={{
                    margin: '3px 3px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                  }}
                  label={<a href={this.state.linkContrato}>{item.name}</a>}
                  component="a"
                  clickable={this.state.linkContrato !== null}
                  onDelete={() => this.deleteContratoChip(index)}
                />
              ))}

              <InputLabel style={{ fontSize: '18px', marginTop: '40px' }}>
                Aditivos
              </InputLabel>

              <Grid container spacing={16}>
                <Grid item md={4}>
                  <FormControl fullWidth={true}>
                    <CurrencyInput
                      label={'Valor do Aditivo'}
                      name={'valorAditivo'}
                      error={this.state.valorAditivoErro}
                      value={this.state.valorAditivo}
                      onChange={this.handleChange}
                      allowNegative
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Data de Vencimento'}
                      value={this.state.vencimentoAditivo}
                      error={this.state.vencimentoAditivoErro}
                      name={'vencimentoAditivo'}
                      InputProps={{
                        inputComponent: DateMaskCustom,
                      }}
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <label
                    htmlFor="outlined-aditivo-file"
                    style={{ marginTop: '16px' }}
                  >
                    <input
                      id="outlined-aditivo-file"
                      type="file"
                      style={{ display: 'none' }}
                      ref={this.aditivoInput}
                      onChange={this.handleAditivoFile}
                    />
                    <Button
                      variant="outlined"
                      fullWidth={true}
                      component="span"
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {this.state.anexoAditivoLabel}
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <Button
                color="primary"
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: '8px', marginBottom: '8px' }}
                onClick={this.validaAditivo}
              >
                Adicionar Aditivo
              </Button>

              {this.state.aditivos.map((item, index) => (
                <BigChip
                  key={index}
                  style={{
                    margin: '3px 3px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                  }}
                  label={
                    item.link_aditivo !== null && item.link_aditivo !== '' ? (
                      <a
                        href={item.link_aditivo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {(item.id_aditivo ? `ID: ${item.id_aditivo} •` : '') +
                          ` Vencimento: ${Utils.dateToPT(
                            item.dt_fim_vigencia
                          )} • Valor: R$${Utils.float2moeda(item.valor)}`}
                      </a>
                    ) : (
                      (item.id_aditivo ? `ID: ${item.id_aditivo} •` : '') +
                      ` Vencimento: ${Utils.dateToPT(
                        item.dt_fim_vigencia
                      )} • Valor: R$${Utils.float2moeda(item.valor)}`
                    )
                  }
                  clickable={false}
                  onDelete={() => this.deleteAditivoChip(index)}
                />
              ))}

              <Grid container style={{ marginTop: '24px' }}>
                <Grid item md={6}>
                  {!this.props.edicao && this.state.tipoContrato === 'PJ' && (
                    <FormControl fullWidth={true} component="fieldset">
                      <FormLabel>Custo Exclusivo de Projeto</FormLabel>
                      <RadioGroup
                        name="exclusivo"
                        row={true}
                        value={this.state.exclusivo}
                        onChange={this.exclusivoHandleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio color={'primary'} />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio color={'primary'} />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
              </Grid>

              {this.state.exclusivo === 'true' &&
                this.props.edicao === false &&
                this.state.tipoContrato === 'PJ' && (
                  <FormControl fullWidth={true}>
                    <InputLabel>Selecione o Projeto</InputLabel>
                    <Select
                      value={this.state.project}
                      inputProps={{
                        name: 'project',
                        id: 'project',
                      }}
                      onChange={this.handleChange}
                      error={this.state.projectErro}
                    >
                      {this.state.projectList.map((item) => (
                        <MenuItem key={item.id_projeto} value={item.id_projeto}>
                          {item.display}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

              {this.state.exclusivo === 'false' &&
                this.props.edicao === false &&
                this.state.tipoContrato === 'PJ' && (
                  <FormControl fullWidth>
                    <InputLabel>Selecione os Projetos</InputLabel>
                    <Select
                      multiple
                      error={this.state.projetosErro}
                      value={this.state.projetos}
                      onChange={this.projetosHandleChange}
                      input={<Input id="select-multiple-chip" />}
                      style={{ wordWrap: 'break-word' }}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip
                              classes={{
                                deleteIcon: {
                                  positon: 'absolute',
                                  right: '0',
                                },
                              }}
                              key={value}
                              label={
                                this.state.projectList.filter(
                                  (item) => item.id_projeto === value.id_projeto
                                )[0].display
                              }
                              style={{
                                margin: '3px 3px',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            ></Chip>
                          ))}
                        </div>
                      )}
                    >
                      {this.state.projectList
                        .filter((projeto) => projeto.id_projeto !== 0)
                        .map((item) => (
                          <MenuItem key={item.id_projeto} value={item}>
                            {item.desc_curta}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

              {this.props.edicao === false &&
                this.state.tipoContrato === 'PJ' && (
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="profile">Perfil</InputLabel>
                    <Select
                      value={this.state.perfil}
                      inputProps={{
                        name: 'perfil',
                      }}
                      error={this.state.perfilErro}
                      onChange={this.handleChange}
                    >
                      {this.state.perfilList.map((item) => (
                        <MenuItem key={item.id_perfil} value={item.id_perfil}>
                          {item.desc_perfil}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

              {this.props.edicao === false &&
                this.state.tipoContrato === 'PJ' && (
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="type_of_link">
                      Jornada de Trabalho
                    </InputLabel>
                    <Select
                      value={this.state.jornadaDiaria}
                      inputProps={{
                        name: 'jornadaDiaria',
                      }}
                      error={this.state.jornadaDiariaErro}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={2} key={1}>
                        02 Horas Diárias
                      </MenuItem>
                      <MenuItem value={3} key={1}>
                        03 Horas Diárias
                      </MenuItem>
                      <MenuItem value={4} key={1}>
                        04 Horas Diárias
                      </MenuItem>
                      <MenuItem value={5} key={1}>
                        05 Horas Diárias
                      </MenuItem>
                      <MenuItem value={6} key={1}>
                        06 Horas Diárias
                      </MenuItem>
                      <MenuItem value={7} key={1}>
                        07 Horas Diárias
                      </MenuItem>
                      <MenuItem value={8} key={1}>
                        08 Horas Diárias
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}

              {this.props.edicao === false &&
                this.state.tipoContrato === 'PJ' && (
                  <FormControl fullWidth={true}>
                    <TextField
                      type="email"
                      label={'E-mail Profissional'}
                      name={'emailProfissional'}
                      value={this.state.emailProfissional}
                      onChange={this.handleChange}
                      error={this.state.emailProfissionalErro}
                    />
                  </FormControl>
                )}

              {this.props.edicao === false &&
                this.state.tipoContrato === 'PJ' && (
                  <FormControl fullWidth={true}>
                    <TextField
                      label={'Celular'}
                      name={'celular'}
                      value={this.state.celular}
                      error={this.state.celularErro}
                      onChange={this.handleChange}
                      InputProps={{ inputComponent: CelMaskCustom }}
                    />
                  </FormControl>
                )}
              <Grid
                style={{ marginTop: '2em' }}
                container={true}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.validaForm}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

Form.propTypes = {
  edicao: PropTypes.bool.isRequired,
};

Form.defaultProps = {
  edicao: false,
};

export default Form;
