import React from "react";
import {FormControl, Grid, MenuItem, InputLabel, Select, TextField, Button} from "@material-ui/core";
import ReactTable from "react-table";
import AppState from "../../../state";
import {browserHistory} from "react-router";
import moment from "moment";
import debounce from 'lodash.debounce';
import ReactExport from "react-data-export";
import { Utils } from "../../../services";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RelatorioQuestoes extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            questions: [],
            quizType: 'todos',
            period: "EsseMes",
            dtInicio: moment().startOf('month').format('YYYY-MM-DD'),
            dtFim: moment().endOf('month').format('YYYY-MM-DD'),
        };
        this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
    }

    componentDidMount() {
        this.getQuestions();
    }

    safe_division = (num, den) => {
        if (num && den && den > 0) {
            return num / den
        } else {
            return 0
        }
    }

    dateFilterHandleChange = name => event => {
        this.setState(
            {
            [name]: event.target.value
            },
            () => {
            if (
                this.state.dtInicio.length === 10 &&
                this.state.dtFim.length === 10
            )
                this._debouncedHandleChange();
            }
        );
    };

    customAccessor = (row) => {
        if (row) {
            return this.state.quizType == 'todos' ? parseInt(100 * this.safe_division(row.respostas_corretas_total, row.respostas_total)) : 
                    this.state.quizType == 'avaliacoes' ? parseInt(100 * this.safe_division(row.respostas_corretas_avaliacao, row.respostas_avaliacao)) : 
                        this.state.quizType == 'treinamentos' ? parseInt(100 * this.safe_division(row.respostas_corretas_treinamento, row.respostas_treinamento)) : ''           
        }
        return 
    }

    getQuestions = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/relatorios/GetRelatorioQuestoes.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}"}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                questions: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    periodHandleChange = event => {
        let dtInicio = "";
        let dtFim = "";
        if (event.target.value == "EsseMes") {
            dtInicio = moment().startOf('month').format('YYYY-MM-DD');
            dtFim = moment().endOf('month').format('YYYY-MM-DD');
        } else if (event.target.value == "MesAnterior") {
            dtInicio = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
            dtFim = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        }
        this.setState(
        {
            dtInicio: dtInicio,
            dtFim: dtFim,
            period: event.target.value
        },
        () => {
            if (this.state.period != "custom") {
                this.getQuestions();
            }
        }
        );
    };

    dateHandleChange = name => event => {
        this.setState(
        {
            [name]: event.target.value
        },
        () => {
            if (this.state.dtInicio.length === 10 && this.state.dtFim.length === 10)
            this._debouncedHandleChange();
        }
        );
    };

    _debouncedHandleChange = () => {
        this.getQuestions();
    }

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Relatório de Questões</h1>

                <Grid 
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid 
                        item
                    >
                        <Grid container
                            justify="space-between"
                            alignItems="center"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Grid 
                                item
                            >
                                <FormControl
                                    component="fieldset"
                                    style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                                >
                                    <InputLabel htmlFor={"quizTypeFilter"}>
                                    {" "}
                                    Tipo de Resposta{" "}
                                    </InputLabel>
                                    <Select
                                    inputProps={{ id: "quizType", name: "quizType" }}
                                    // style={{ margin: "0.5em 0px" }}
                                    value={this.state.quizType}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem value={"todos"}>Todos</MenuItem>
                                    <MenuItem value={"avaliacoes"}>Avaliações</MenuItem>
                                    <MenuItem value={"treinamentos"}>Treinamentos</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                component="fieldset"
                                style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                                >
                                <InputLabel htmlFor={"period"}>
                                    {" "}
                                    Período de Resposta{" "}
                                </InputLabel>
                                <Select
                                    inputProps={{ id: "period", name: "period" }}
                                    // style={{ margin: "0.5em 0px" }}
                                    value={this.state.period || "Ontem"}
                                    onChange={this.periodHandleChange}
                                >
                                    <MenuItem value={"EsseMes"}>Esse Mês</MenuItem>
                                    <MenuItem value={"MesAnterior"}>Mês Anterior</MenuItem>
                                    <MenuItem value={"custom"}>Customizado</MenuItem>
                                </Select>
                                </FormControl>
                                {this.state.period === "custom" && (
                                <TextField
                                    style={{
                                    marginLeft: "32px",
                                    marginRight: "16px",
                                    paddingTop: "13px"
                                    }}
                                    label="Inicio"
                                    type="date"
                                    defaultValue={this.state.dtInicio}
                                    onChange={this.dateFilterHandleChange("dtInicio")}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                                )}

                                {this.state.period === "custom" && (
                                <TextField
                                    style={{ paddingTop: "13px" }}
                                    label="Fim"
                                    type="date"
                                    defaultValue={this.state.dtFim}
                                    onChange={this.dateFilterHandleChange("dtFim")}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                                )}
                            </Grid>
                            <Grid item>
                                <ExcelFile
                                    filename={"RelatorioQuestoes-" + Utils.getTodayDate()}
                                    element={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.exportarExcel}
                                    >
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 192 192"
                                        style={{ fill: "#000000", paddingRight: "6px" }}
                                        >
                                        <g
                                            fill="none"
                                            fillRule="nonzero"
                                            stroke="none"
                                            strokeWidth="1"
                                            strokeLinecap="butt"
                                            strokeLinejoin="miter"
                                            strokeMiterlimit="10"
                                            strokeDasharray=""
                                            strokeDashoffset="0"
                                            fontFamily="none"
                                            fontWeight="none"
                                            fontSize="none"
                                            textAnchor="none"
                                            style={{ mixBlendMode: "normal" }}
                                        >
                                            <path d="M0,192v-192h192v192z" fill="none"></path>
                                            <g fill="#ffffff">
                                            <g id="surface1">
                                                <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                                            </g>
                                            </g>
                                        </g>
                                        </svg>
                                        Exportar
                                    </Button>
                                    }
                                >
                                    <ExcelSheet
                                    data={() => this.selectTable.getResolvedState().sortedData}
                                    name="Questoes"
                                    >
                                    <ExcelColumn
                                        label="Tema"
                                        value="tema"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="Seção"
                                        value="secao"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="Nivel"
                                        value="nivel_display"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="Pergunta"
                                        value="pergunta"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="Respostas"
                                        value="respostas"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    <ExcelColumn
                                        label="% de acerto"
                                        value="acerto"
                                        style={{ font: { sz: "24", bold: true } }}
                                    />
                                    </ExcelSheet>
                                </ExcelFile>
                            </Grid>
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.questions}
                        pageSize={50}
                        className="-striped -highlight"
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        ref={r => {
                            this.selectTable = r;
                        }}
                        defaultSorted={[{id: 'respostas', desc: true}]}
                        columns={[
                            {
                                Header: "Tema",
                                accessor: "tema",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Seção",
                                accessor: "secao",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Nível",
                                accessor: "nivel_display",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Pergunta",
                                accessor: "pergunta",
                                width: 500,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Respostas",
                                accessor: this.state.quizType == 'todos' ? 'respostas_total' : 
                                            this.state.quizType == 'avaliacoes' ? 'respostas_avaliacao' : 
                                            this.state.quizType == 'treinamentos' ? 'respostas_treinamento' : '',
                                id: 'respostas',
                                            width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "% de Acerto",
                                width: 200,
                                id: 'acerto',
                                accessor: this.customAccessor,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > 
                                    {row.value} % </div> )
                            }
                        ]}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default RelatorioQuestoes;