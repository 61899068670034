import React from 'react'
import ReactTable from "react-table";
import AppState from "../../../state";
import {Utils} from "../../../services";
import moment from 'moment';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
  "Jul", "Ago", "Set", "Out", "Nov", "Dez"
];

class RelatorioCapitalGiro extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            receita_data: [],
            despesa_data: [],
            resumo_data: [],
            months: 3
        }
    }

    componentDidMount() {
        this.carrega_relatorio();
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.carrega_relatorio();
        });
    };

    formatExecutiveData = (data) => {
        let treated_data = [];
        let last_month = moment().subtract(1, 'months').startOf('month');
        let months_from_now = moment().add(this.state.months - 1, 'months').endOf('month');
        for (let i = 0; i < data.length; i++) {
            let element = data[i];
            if (moment(element.dt_referencia).isBetween(last_month, months_from_now)) {
                let found = treated_data.find((el) => el.dt_referencia === element.dt_referencia);
                if (found) {
                    if (element['natureza'] === 'receita') {
                        found['receita'] =+ element['valor_projetado'] ? parseFloat(element['valor_projetado']) : 0.0;
                    } else {
                        found['despesa'] =+ element['valor_projetado'] ? parseFloat(element['valor_projetado']) : 0.0;
                    }
                } else {
                    if (element['natureza'] === 'receita') {
                        element['receita'] = element['valor_projetado'] ? parseFloat(element['valor_projetado']) : 0.0;
                        element['despesa'] = 0.0;
                    } else {
                        element['despesa'] = element['valor_projetado'] ? parseFloat(element['valor_projetado']) : 0.0;
                        element['receita'] = 0.0;
                    }
                    treated_data.push(element);
                }
            }
        }
        return treated_data;
    }

    carrega_relatorio = () => {
        AppState.isLoading = true;

        fetch(`${AppState.serverAddr}/relatorios/GetRelatorioCapitalDeGiro.php?json={"meses":"${this.state.months}"}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': AppState.token
                }
            })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                AppState.isLoading = false;
                this.setState({
                    receita_data: response.request.filter((el) => el.natureza !== 'despesa'),
                    despesa_data: response.request.filter((el) => el.natureza !== 'receita'),
                    executive_data: this.formatExecutiveData(response.request)
                })
            })
            .catch(function(error) {
                AppState.isLoading = false;
                console.log("Erro de api!", error);
            });
    }

    render() {
        return (
        <div id={this.constructor.name} style={{marginTop: "5em"}}>
            <h1>Relatório Capital de Giro</h1>
            <Grid
                container
                justify="space-between"
                style={{ marginTop: "16px", marginBottom: "8px" }}
                >
                <Grid item>
                    <Grid container justify="flex-start" style={{ marginTop: "30px" }}>
                    <Grid item>
                    <FormControl
                        component="fieldset"
                        style={{ width: "200px", paddingTop: "15px", marginLeft: "15px" }}
                        >
                        <InputLabel htmlFor={"month"}>
                            {" "}
                            Meses de análise{" "}
                        </InputLabel>
                        <Select
                            inputProps={{ id: "month", name: "months" }}
                            style={{ margin: "0.5em 0px" }}
                            value={this.state.months}
                            onChange={this.handleChange}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Grid container direction="row" justify="space-around" style={{marginTop: "1em", margin: "auto"}}>
                <h4>Visão Geral</h4> 
            </Grid>
            <Grid container direction="row" justify="space-around" style={{marginTop: "1em", margin: "auto"}}>
                <Grid xs={5}>
                    <ReactTable
                    data={this.state.receita_data}
                    pageSize={this.state.months + 9}
                    showPagination={false}
                    columns={[{
                        "Header": "Receita",
                        "columns": [
                    {
                        "Header": "Mês/Ano",
                        "accessor": "dt_referencia",
                        Cell: (row) => (
                            <div
                                style={{
                                    height: "22px",
                                    verticalAlign: "middle",
                                    position: "relative"
                                }}
                            >
                                {monthNames[parseInt(moment(row.value).format('MM'))-1] + '/' + moment(row.value).format('YYYY')}
                            </div>
                        )
                    },
                    {
                        "Header": "Previsto",
                        "accessor": "valor_projetado",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.value)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                                Utils.float2moeda(
                                row.data.reduce(
                                    (total, { valor_projetado }) => (total += valor_projetado ? parseFloat(valor_projetado) : 0.0 ),
                                    0
                                )
                                )}
                            </span>
                        )
                    },
                    {
                        "Header": "Realizado",
                        "accessor": "valor_realizado",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.value)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                                Utils.float2moeda(
                                row.data.reduce(
                                    (total, { valor_realizado }) => (total += valor_realizado ? parseFloat(valor_realizado) : 0.0 ),
                                    0
                                )
                                )}
                            </span>
                        ),
                    },
                    {
                        "Header": "Variação %",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative",
                                textAlign: "center"
                            }}
                            >
                            {row.original.valor_projetado && row.original.valor_realizado ? 
                                parseInt((row.original.valor_realizado / row.original.valor_projetado - 1) * 1000) / 10 + ' %' : ''}
                            </div>
                        ),
                        Footer: row => (
                            <div style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative",
                                textAlign: "center"
                            }}>
                            {
                            row.data.length > 0 ?
                                parseInt( 1000 *
                                    (row.data.reduce((total, { valor_realizado }) => (total += valor_realizado ? parseFloat(valor_realizado) : 0.0 ), 0)
                                    /
                                    row.data.reduce((total, { valor_projetado }) => (total += valor_projetado ? parseFloat(valor_projetado) : 0.0 ), 0)
                                    - 1)
                                ) / 10 + ' %'
                                : ''
                            }
                            </div>
                        )
                    }]
                    }]
                    }
                    className="-striped -highlight"/>
                </Grid>
                <Grid xs={5}>
                <ReactTable
                    data={this.state.despesa_data}
                    pageSize={this.state.months + 9}
                    showPagination={false}
                    columns={[{
                        "Header": "Despesa",
                        "columns": [
                    {
                        "Header": "Mês/Ano",
                        "accessor": "dt_referencia",
                        Cell: (row) => (
                            <div
                                style={{
                                    height: "22px",
                                    verticalAlign: "middle",
                                    position: "relative"
                                }}
                            >
                                {monthNames[parseInt(moment(row.value).format('MM'))-1] + '/' + moment(row.value).format('YYYY')}
                            </div>
                        )
                    },
                    {
                        "Header": "Previsto",
                        "accessor": "valor_projetado",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.value)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                                Utils.float2moeda(
                                row.data.reduce(
                                    (total, { valor_projetado }) => (total += valor_projetado ? parseFloat(valor_projetado) : 0.0 ),
                                    0
                                )
                                )}
                            </span>
                        )
                    },
                    {
                        "Header": "Realizado",
                        "accessor": "valor_realizado",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.value)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                                Utils.float2moeda(
                                row.data.reduce(
                                    (total, { valor_realizado }) => (total += valor_realizado ? parseFloat(valor_realizado) : 0.0 ),
                                    0
                                )
                                )}
                            </span>
                        ),
                    },
                    {
                        "Header": "Variação %",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative",
                                textAlign: "center"
                            }}
                            >
                            {row.original.valor_projetado && row.original.valor_realizado ? 
                                parseInt((row.original.valor_realizado / row.original.valor_projetado - 1) * 1000) / 10 + ' %' : ''}
                            </div>
                        ),
                        Footer: row => (
                            <div style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative",
                                textAlign: "center"
                            }}>
                            {
                            row.data.length > 0 ?
                                parseInt( 1000 *
                                    (row.data.reduce((total, { valor_realizado }) => (total += valor_realizado ? parseFloat(valor_realizado) : 0.0 ), 0)
                                    /
                                    row.data.reduce((total, { valor_projetado }) => (total += valor_projetado ? parseFloat(valor_projetado) : 0.0 ), 0)
                                    - 1)
                                ) / 10 + ' %'
                                : ''
                            }
                            </div>
                        )
                    }]}, {
                    "Header": "Resultado",
                    "columns":[
                    {
                        width: 120,
                        Cell: 
                            row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {this.state.receita_data[row.index].valor_realizado && row.original.valor_realizado ? 
                                "R$ " + Utils.float2moeda(this.state.receita_data[row.index].valor_realizado - row.original.valor_realizado) : ''}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            row.data.length > 0 ?
                                "R$ " + Utils.float2moeda(
                                    this.state.receita_data.reduce((total, { valor_realizado }) => (total += valor_realizado ? parseFloat(valor_realizado) : 0.0 ), 0)
                                    -
                                    row.data.reduce((total, { valor_realizado }) => (total += valor_realizado ? parseFloat(valor_realizado) : 0.0 ), 0)
                                )
                                : ''
                            }
                            </span>
                        )
                    }]
                    }]
                    }
                    className="-striped -highlight"/>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="space-around" style={{marginTop: "1em"}}>
                <h4>{this.state.months > 1 ? "Próximos " + this.state.months + " Meses" : "Próximo " + this.state.months + " Mês"}</h4> 
            </Grid>
            <Grid container direction="row" justify="space-around" style={{marginTop: "1em"}}>
                <Grid xs={8}>
                <ReactTable
                    data={this.state.executive_data}
                    pageSize={this.state.months}
                    showPagination={false}
                    columns={[{
                        "Header": "Resumo Executivo",
                        "columns": [
                    {
                        "Header": "Mês/Ano",
                        "accessor": "dt_referencia",
                        Cell: (row) => (
                            <div
                                style={{
                                    height: "22px",
                                    verticalAlign: "middle",
                                    position: "relative"
                                }}
                            >
                                {monthNames[parseInt(moment(row.value).format('MM'))-1] + '/' + moment(row.value).format('YYYY')}
                            </div>
                        )
                    },
                    {
                        "Header": "Receita Projetada",
                        "accessor": "receita",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.value)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                                Utils.float2moeda(
                                row.data.reduce(
                                    (total, { receita }) => (total += receita ),
                                    0
                                )
                                )}
                            </span>
                        )
                    },
                    {
                        "Header": "Despesa Projetada",
                        "accessor": "despesa",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.value)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                                Utils.float2moeda(
                                row.data.reduce(
                                    (total, { despesa }) => (total += despesa ),
                                    0
                                )
                                )}
                            </span>
                        ),
                    },
                    {
                        "Header": "Capital de Giro",
                        Cell: row => (
                            <div
                            style={{
                                height: "22px",
                                verticalAlign: "middle",
                                position: "relative"
                            }}
                            >
                            {"R$ " + Utils.float2moeda(row.original.receita - row.original.despesa)}
                            </div>
                        ),
                        Footer: row => (
                            <span>
                            {
                            "R$ " +
                            Utils.float2moeda(
                                row.data.reduce((total, { receita, despesa }) => (total += parseFloat(receita) - parseFloat(despesa) ), 0)
                            )}
                            </span>
                        )
                    }]
                    }]
                    }
                    className="-striped -highlight"/>
                </Grid>
            </Grid>
        </div>)
    }

}

export default RelatorioCapitalGiro;