import AppState from "../state";

const baseUrl = `${AppState.serverAddr}/relatorios`;

export const rateioDespEconomico = (dt_inicial, dt_final) => {
  return fetch(`${baseUrl}/GetRateioDespEconomico.php?dt_inicial=${dt_inicial}&dt_final=${dt_final}`, {
    method: "GET",
    headers: {
      Authorization: AppState.token
    }
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};
