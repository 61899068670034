import AppState from "../state";

const baseUrl = `${AppState.serverAddr}/projetos`;

const editProject = projetoData => {
  return fetch(`${baseUrl}/EditProject.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: projetoData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};

const addProject = projetoData => {
  return fetch(`${baseUrl}/AddProject.php`, {
    method: "POST",
    headers: {
      Authorization: AppState.token
    },
    body: projetoData
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        var error = new Error(response.status);
        error.response = json;
        throw error;
      });
    }

    const res = response.json();
    return res;
  });
};

const getProjectById = idProjeto => {
  return fetch(`${baseUrl}/GetProject.php?json={"id_projeto":"${idProjeto}"}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: AppState.token
    }
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const getProjectTypes = () => {
  return fetch(`${baseUrl}/GetProjectTypes.php`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: AppState.token
    }
  }).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const getResponsaveis = () => {
  return fetch(
    `${baseUrl}/GetSocios.php`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  ).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
};

const getProjetos = () => {
  return fetch(
    `${baseUrl}/GetProjects.php`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: AppState.token
      }
    }
  ).then(response => {
    if (!response.ok) {
      return response.json().then(json => {
        let error = new Error(response.status);
        error.response = json;
        throw error;
      });
    } else {
      const res = response.json();
      return res;
    }
  });
}

export {
  editProject,
  addProject,
  getProjectById,
  getProjectTypes,
  getResponsaveis,
  getProjetos
};
