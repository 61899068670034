import React, { useEffect, useState } from 'react';
import AppState from "../../../state";
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './style.css';
import { MenuItem, Select, TextField, FormControl, Grid, InputLabel, Button } from "@material-ui/core/";

const ConsolidadoDespesasPessoalFinanceiro = () => {
    const [data, setData] = useState({ transformedData: [], percentData: [] });
    const [dtInicio, setDtInicio] = useState(`${new Date().getFullYear()}-01-01`);
    const [dtFim, setDtFim] = useState(`${new Date().getFullYear()}-12-31`);
    const [statusProjeto, setStatusProjeto] = useState('todos');

    const fetchData = () => {
        const url = `${AppState.serverAddr}/relatorios/GetRelatorioPessoalFinanceiro.php?dt_inicio=${dtInicio}&dt_fim=${dtFim}&status_projeto=${statusProjeto}`;

        fetch(url)
            .then(response => response.json())
            .then(result => {
                if (result.length > 0) {
                    const processedData = processResponseData(result);
                    setData(processedData);
                } else {
                    setData([])
                    console.error('Erro ao buscar dados: Estrutura de resposta inesperada', result);
                }
            })
            .catch(error => {
                console.error('Erro ao buscar dados:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);


    const processResponseData = (rawData) => {
        const transformedData = rawData.map(item => {
            const newEntry = { conta: item.conta };
            const percentEntry = { conta: item.conta };
            let totalValor = 0;

            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    const { valor, percent_valor, valor_base } = item[projetoId];

                    newEntry[projetoId] = valor.toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    });

                    percentEntry[projetoId] = `${percent_valor.toLocaleString('pt-BR', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                    })}%`;

                    totalValor += valor;
                }
            });


            newEntry['Total'] = totalValor.toLocaleString('pt-BR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });


            return { newEntry, percentEntry };
        });

        const transformedDataArray = transformedData.map(data => data.newEntry);
        const percentDataArray = transformedData.map(data => data.percentEntry);

        const contasToRemove = ['2.1.1.1', '2.1.1.2', '2.1.1.3', '2.1.1.4'];

        const percentDataArrayFilter = percentDataArray.filter(item => !contasToRemove.includes(item.conta));

        const calculateLineTotal = (baseRow) => {
            return Object.keys(baseRow).reduce((total, projetoId) => {
                if (projetoId !== 'conta') {
                    const valorNumerico = parseFloat(baseRow[projetoId].replace(/\./g, '').replace(',', '.')) || 0;
                    total += valorNumerico;
                }
                return total;
            }, 0);
        };

        const projectKeys = Object.keys(rawData[0]).filter(key => key !== 'conta');

        const baseRow = { conta: 'Base' };

        rawData.forEach(item => {

            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    baseRow[projetoId] = item[projetoId].valor_base
                        ? item[projetoId].valor_base.toLocaleString('pt-BR', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        : '0.00';
                }
            });
        });

        baseRow['Total'] = calculateLineTotal(baseRow).toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });

        const benefRow = { conta: 'Benefícios' };
        rawData.forEach(item => {
            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    benefRow[projetoId] = item[projetoId].valor_benef
                        ? item[projetoId].valor_benef.toLocaleString('pt-BR', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        : '0.00';
                }
            });
        });
        benefRow['Total'] = calculateLineTotal(benefRow, projectKeys).toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });

        const encRow = { conta: 'Encargos' };
        rawData.forEach(item => {
            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    encRow[projetoId] = item[projetoId].valor_enc
                        ? item[projetoId].valor_enc.toLocaleString('pt-BR', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        : '0.00';
                }
            });
        });
        encRow['Total'] = calculateLineTotal(encRow, projectKeys).toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });

        const encBenefRow = { conta: 'Enc + Benef' };
        rawData.forEach(item => {
            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    encBenefRow[projetoId] = item[projetoId].valor_enc_benef
                        ? item[projetoId].valor_enc_benef.toLocaleString('pt-BR', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        : '0.00';
                }
            });
        });
        encBenefRow['Total'] = calculateLineTotal(encBenefRow, projectKeys).toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });


        transformedDataArray.push(baseRow, benefRow, encRow, encBenefRow);

        const percentBenefRow = { conta: 'Benefícios' };
        rawData.forEach(item => {
            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    percentBenefRow[projetoId] = item[projetoId].percent_benef
                        ? `${item[projetoId].percent_benef.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}%`
                        : '0.00%';
                }
            });
        });

        percentBenefRow['Total'] = `${(
            parseFloat(benefRow.Total.replace(/\./g, '').replace(',', '.')) /
            parseFloat(baseRow.Total.replace(/\./g, '').replace(',', '.')) * 100
        ).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}%`;

        const percentEncRow = { conta: 'Encargos' };
        rawData.forEach(item => {
            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    percentEncRow[projetoId] = item[projetoId].percent_enc
                        ? `${item[projetoId].percent_enc.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}%`
                        : '0.00%';
                }
            });
        });
        percentEncRow['Total'] = `${(
            parseFloat(encRow.Total.replace(/\./g, '').replace(',', '.')) /
            parseFloat(baseRow.Total.replace(/\./g, '').replace(',', '.')) * 100
        ).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}%`;

        const percentEncBenefRow = { conta: 'Enc + Benef' };
        rawData.forEach(item => {
            Object.keys(item).forEach(projetoId => {
                if (projetoId !== 'conta') {
                    percentEncBenefRow[projetoId] = item[projetoId].percent_enc_benef
                        ? `${item[projetoId].percent_enc_benef.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}%`
                        : '0.00%';
                }
            });
        });
        percentEncBenefRow['Total'] = `${(
            parseFloat(encBenefRow.Total.replace(/\./g, '').replace(',', '.')) /
            parseFloat(baseRow.Total.replace(/\./g, '').replace(',', '.')) * 100
        ).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}%`;

        percentDataArrayFilter.push(percentBenefRow, percentEncRow, percentEncBenefRow);

        percentDataArrayFilter.forEach(percentEntry => {
            const valorCorrespondente = transformedDataArray.find(dataEntry => dataEntry.conta === percentEntry.conta);
            if (valorCorrespondente) {
                percentEntry['Total'] = `${(
                    parseFloat(valorCorrespondente['Total'].replace(/\./g, '').replace(',', '.')) /
                    parseFloat(baseRow.Total.replace(/\./g, '').replace(',', '.')) * 100
                ).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}%`;
            }
        });

        return { transformedData: transformedDataArray, percentData: percentDataArrayFilter };
    };


    const projectIds = data.transformedData?.reduce((acc, entry) => {
        Object.keys(entry).forEach(key => {
            if (key !== 'conta' && key !== 'Total' && !['Benef', 'Enc', 'Enc+Benef'].includes(key)) {
                acc.push(key);
            }
        });
        return acc;
    }, []);

    const uniqueProjectIds = [...new Set(projectIds)];

    uniqueProjectIds.sort((a, b) => Number(a) - Number(b));

    const columns = [
        {
            Header: 'Conta',
            accessor: 'conta',
        },
        ...uniqueProjectIds.map(projetoId => ({
            Header: projetoId,
            accessor: projetoId,
        })),
        {
            Header: 'Total',
            accessor: 'Total',
        }
    ];


    return (
        <>
            <Grid>
                <Grid container alignItems={"center"}>
                    <Grid style={{ display: 'flex', width: '100%', alignItems: 'center', gap: 50 }}>
                        <h1>Consolidado Pessoal Financeiro</h1>
                        <Grid item>
                            <Grid container spacing={32}>
                                <Grid item>
                                    <TextField
                                        style={{ marginRight: "20px" }}
                                        id="date"
                                        label="Inicio"
                                        type="date"
                                        value={dtInicio}
                                        onChange={(e) => setDtInicio(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        id="date"
                                        label="Fim"
                                        type="date"
                                        value={dtFim}
                                        onChange={(e) => setDtFim(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={32}>
                                <Grid item>
                                    <FormControl style={{ marginTop: 12 }}>
                                        <InputLabel>Status do Projeto</InputLabel>
                                        <Select
                                            style={{ marginBottom: "14px", minWidth: "156px" }}
                                            id={"status_projeto"}
                                            value={statusProjeto}
                                            onChange={(e) => setStatusProjeto(e.target.value)}
                                        >
                                            <MenuItem key={1} value="andamento">
                                                Em Andamento
                                            </MenuItem>
                                            <MenuItem key={2} value="encerrado">
                                                Encerrados
                                            </MenuItem>
                                            <MenuItem key={0} value="todos">
                                                Todos
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button variant="contained" color="primary" onClick={fetchData}>
                            Consultar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <div className="table-container">
                <ReactTable
                    data={data.transformedData}
                    columns={columns}
                    defaultPageSize={30}
                    className="-striped -highlight react-table"
                />

                <ReactTable
                    data={data.percentData}
                    columns={columns}
                    defaultPageSize={30}
                    className="-striped -highlight react-table"
                />
            </div>
        </>
    );


};

export default ConsolidadoDespesasPessoalFinanceiro;
