import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, CircularProgress } from '@material-ui/core/';
import { observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import AppState from '../../state';
import img_logo from '../../assets/images/logo_ambiental.png';

import './style.css';
import MaskedInput from 'react-text-mask';

const emptyState = {
  usuario: '',
  senha: '',
};

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Login extends Component {
  state = {
    ...emptyState,
    capsWarning: false,
  };

  _handleChange = (name) => (event) => {
    var value =
      event.target && event.target.value
        ? event.target.value
        : typeof event === 'string'
          ? event
          : '';
    this.setState({ [name]: value });
  };

  _handlesPasswordInputKeyUp = (event) => {
    if (event.getModifierState('CapsLock')) {
      this.setState({
        capsWarning: true,
      });
    } else {
      this.setState({
        capsWarning: false,
      });
    }

    if (event.keyCode === 13) {
      this._submitLogin();
    }
  };

  _treatLoginData = (token, userData) => {
    console.log('userData:', JSON.stringify(userData));

    if (token) {
      AppState.isLoggedIn = true;
      AppState.token = token;
      AppState.empresa = userData.empresa;
      AppState.idPerfil = userData.id_perfil;
      AppState.userInfo = userData;
    } else {
      this._userLogout();
    }

    let menu = { views: userData.views };
    localStorage.setItem('token', token);
    localStorage.setItem('Menu', JSON.stringify(menu));
    //localStorage.setItem('userInfo', JSON.stringify({cpf_usuario: userData.cpf_usuario, nome_usuario: userData.nome_usuario}))
    localStorage.setItem(
      'userInfo',
      JSON.stringify({
        cpf_usuario: userData.cpf_usuario,
        nome_usuario: userData.nome_usuario,
        id_perfil: userData.id_perfil
      })
    );
    /*
            AppState.idPerfil = idPerfil;
            if(idPerfil === 1) {
              AppState.isMaster = true;
              AppState.isAdmin = true;
            }
            if(idPerfil === 2) {
              AppState.isAdmin = true;
            }

            localStorage.setItem('idPerfil', idPerfil);
            */
  };

  _submitLogin = () => {
    // Aqui vai a chamada AJAX do Login
    var self = this;
    var usuario = this.state.usuario;
    var senha = this.state.senha;

    const checkStatus = (response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        if (response.status == 401) {
          return response.json().then(function (json) {
            var ret = json['status'].value.split(' ')[0];
            if (ret == '40101') alert('Login ou senha incorreto');
            else if (ret == '40102') alert('Usuário bloqueado');
            else alert('Erro de conexão');
          });
        }
        var error = new Error(response.statusText);
        error.response = response;
        this._handleChange('senha')('');
        throw error;
      }
    };

    function parseJSON(response) {
      return response.json();
    }

    function validaForm() {
      //aqui vai a validação
      if (usuario.length !== 14) {
        alert('CPF Inexistente. Insira um CPF Válido.');
        return false;
      }

      if (senha.length < 6) {
        alert('A senha deve ter pelo menos 6 dígitos.');
        return false;
      }
      return true;
    }


    if (validaForm()) {
      AppState.isLoading = true;
      fetch(
        `${AppState.serverAddr}/comum/Login.php`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            user: usuario,
            pwd: senha,
          }),
        }
      )
        .then(checkStatus)
        .then(parseJSON)
        .then(function (response) {
          const token = response.auth.replace(/['"]+/g, '');
          const userData = response.request;
      
          self._treatLoginData(token, userData);
          AppState.isLoading = false;
      
          return fetch(`${AppState.serverAddr}/config/GetValueInvoiceCurrent.php`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          });
        })
        .then(checkStatus)
        .then(parseJSON)
        .then(function (invoiceResponse) {
          const isInvoiceCurrent = invoiceResponse.data || 'false';
          localStorage.setItem('noInvoiceCurrent', isInvoiceCurrent);
      
          if (AppState.userInfo.id_perfil === 'ges_director') {
            browserHistory.push(
              `${process.env.PUBLIC_URL}/relatorio/dashboard-gerencial`
            );
          } else {
            browserHistory.push(`${process.env.PUBLIC_URL}/timesheets`);
          }
        })
        .catch(function (ex) {
          AppState.isLoading = false;
          console.error('Erro ao processar login ou buscar is_invoice_current:', ex);
        });
      
    }
  };

  _submitForgotPassword = () => {
    // Aqui vai a chamada AJAX do Esqueci minha Senha
    var usuario = this.state.usuario;

    function checkStatus(response) {
      if (!response.ok) {
        return response.json().then((json) => {
          var error = new Error(response.status);
          error.response = json;
          throw error;
        });
      } else {
        return response;
      }
    }

    function parseJSON(response) {
      return response.json();
    }

    if (usuario.length !== 14) {
      alert('Informe o seu CPF no campo do usuário.');
      return;
    }
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/comum/RecoverPwD.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          cpf: usuario,
        }),
      }
    )
      .then(checkStatus)
      .then(parseJSON)
      .then(function (response) {
        AppState.isLoading = false;
        alert('As instruções foram enviadas para o seu email.');
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40007') alert('CPF não cadastrado');
          else alert('Erro, operação não realizada.');
        } else {
          alert('Erro, operação não realizada.');
        }
      });
  };

  render() {
    return (
      <div id="Login" className="login-place">
        <div className="right-content">
          <div>
            <div className="box-login">
              {AppState.isDevMode && <p>Ambiente de testes</p>}

              <img className="logo-img" src={img_logo} alt="" />
              <TextField
                id="usuario"
                label="USUÁRIO"
                placeholder="CPF"
                InputProps={{
                  inputComponent: TextMaskCustom,
                  value: this.state.usuario,
                  onChange: this._handleChange('usuario'),
                }}
              />

              <TextField
                id="senha"
                label="SENHA"
                fullWidth={true}
                type="password"
                inputProps={{
                  maxLength: 12,
                }}
                value={this.state.senha}
                onChange={this._handleChange('senha')}
                onKeyUp={this._handlesPasswordInputKeyUp}
              />

              {this.state.capsWarning && (
                <p style={{ color: 'red', marginTop: '8px' }}>
                  O CapsLock está ativado!
                </p>
              )}

              <div className={'buttons'}>
                <Button
                  className={'btn-login'}
                  color="primary"
                  variant="contained"
                  onClick={this._submitLogin}
                >
                  entrar
                </Button>
                <Button
                  className="btn-forget-place"
                  variant="outlined"
                  onClick={this._submitForgotPassword}
                >
                  Esqueci minha senha
                </Button>
              </div>
            </div>
          </div>
        </div>
        {AppState.isLoading && (
          <div className="loading-overlay">
            <div className="loading-container">
              <CircularProgress size={60} thickness={4} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default observer(Login);
