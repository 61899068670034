import React from "react";
import {Grid,
        Paper,
        Button,
        TextField,
        Icon
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import ReactTable from "react-table";
import AppState from "../../../state";
import {browserHistory} from "react-router";
import Form from "./Form";
import Quiz from "../quiz/Quiz";
import moment from "moment";
import debounce from 'lodash.debounce';

class Treinamento extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            trainings: [],
            trainings_results: [],
            selectedTrainingId: '',
            selectedTrainingData: [],
            selected: '',
            dialog: false,
            quizDialog: false,
            dtInicio: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            dtFim: moment().format('YYYY-MM-DD')
        };
        this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
    }

    _debouncedHandleChange = () => {
        this.getTrainingResults();
    }

    componentDidMount() {
        this.getTrainings();
        this.getTrainingResults();
    }

    closeDialog = () => {
        this.setState({ dialog: false, edicao: false, selectedTrainingData: [], selectedTrainingId: '', selected: '' }, () => this.getTrainings());
    };

    closeQuizDialog = () => {
        this.setState({ quizDialog: false, selectedTrainingData: [], selectedTrainingId: '', selected: '' }
        , () => {
            this.getTrainings();
            this.getTrainingResults();
        });
    };

    openDialog = (edicao = false) => {
        this.setState({
            edicao: edicao,
            dialog: true
        });
    };

    openQuizDialog = () => {
        this.setState({
            quizDialog: true
        });
    };

    getTrainings = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/treinamentos/GetTreinamentos.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                trainings: response.request
            }, () => {
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getTrainingResults = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/relatorios/GetRelatorioTreinamentosByUser.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}", "cpf_usuario":"${AppState.userInfo.cpf_usuario}"}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                trainings_results: response.request
            }, () => {
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    deleteTreinamento = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_treinamento: this.state.selectedTrainingId,
            nome: this.state.selectedTrainingData.nome,
            faceis: this.state.selectedTrainingData.faceis,
            medias: this.state.selectedTrainingData.medias,
            dificeis: this.state.selectedTrainingData.dificeis,
            temas: this.state.selectedTrainingData.temas.map((val) => val.id_tema_secao),
            status: false
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/treinamentos/UpdateTreinamento.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((json) => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            }

            const res = response.json()
            return res;
            AppState.isLoading = false
        })
        .then((response) => {
            AppState.isLoading = false;
            this.closeDialog()
        })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                console.log(ret)
                alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getTrainingData = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/treinamentos/GetTreinamento.php?json={"id_treinamento":${this.state.selectedTrainingId}}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                selectedTrainingData: response.request
            }, () => {
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    dateFilterHandleChange = name => event => {
        this.setState(
            {
            [name]: event.target.value
            },
            () => {
            if (
                this.state.dtInicio.length === 10 &&
                this.state.dtFim.length === 10
            )
                this._debouncedHandleChange();
            }
        );
    };

    corectAnswersAccessor = (row) => {
        return parseInt(100 * this.safe_division(row.corretas, row.num_perguntas))
    }

    safe_division = (num, den) => {
        if (num && den && den > 0) {
            return num / den
        } else {
            return 0
        }
    }

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Meus Treinamentos</h1>

                <Grid
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid
                        item
                    >
                        <Grid
                            container
                            justify="space-between"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Grid>
                                <h4>Meus Treinamentos</h4>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.openDialog()}
                                >
                                    <AddIcon />
                                    Treinamento
                                </Button>
                            </Grid>
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.trainings}
                        pageSize={10}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    this.setState({
                                        selected: rowInfo.index,
                                        selectedTrainingId: rowInfo.original.id_treinamento
                                    }, () => {
                                        this.getTrainingData();
                                    })
                                },
                                style: {
                                    background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                }
                            }
                            }else{
                                return {}
                            }
                        }}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        columns={[
                            {
                                accessor: "id_treinamento",
                                Header: "id",
                                width: 80,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            },
                            {
                                accessor: "nome",
                                Header: "Nome",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }
                        ]}
                        />
                        <Grid
                            item
                            style={{paddingTop: "3rem", textAlign: "center"}}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={this.state.selectedTrainingId == ''}
                                onClick={() => this.openQuizDialog()}
                            >
                                Iniciar Treinamento
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        style={{paddingLeft: "10rem"}}
                    >
                        <Grid
                            container
                            justify="space-between"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            {this.state.selectedTrainingId !== '' ?
                            <Grid container
                                justify="flex-start"
                            >
                                <h4>Detalhe do Treinamento</h4>
                            </Grid>
                            :
                            <Grid item>
                                <TextField
                                    style={{
                                    marginRight: "16px",
                                    }}
                                    label="Inicio"
                                    type="date"
                                    defaultValue={this.state.dtInicio}
                                    onChange={this.dateFilterHandleChange("dtInicio")}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                                <TextField
                                    label="Fim"
                                    type="date"
                                    defaultValue={this.state.dtFim}
                                    onChange={this.dateFilterHandleChange("dtFim")}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                            </Grid>
                            }
                        </Grid>
                        {this.state.selectedTrainingId !== '' ?
                        <Paper style={{width: "380px", height: "480px", overflow: 'auto'}}>
                            {Object.keys(this.state.selectedTrainingData).length > 0 && (
                                <Grid
                                    container
                                    justify="center"
                                >
                                    <Grid
                                        container
                                        style={{paddingTop:"2rem", paddingBottom: "2rem", paddingLeft: "1rem", paddingRight: "1rem"}}
                                        direction="row" justify="space-between" alignItems="flex-start"
                                    >
                                        <h4 style={{textDecoration: "underline", fontWeight: "bold", textTransform: "capitalize",}}>{this.state.selectedTrainingData.nome}</h4>
                                        <Icon
                                            title={"fechar"}
                                            onClick={this.closeDialog}
                                        >
                                            close
                                        </Icon>
                                    </Grid>
                                    <Grid
                                        container
                                        justify="space-between"
                                        style={{paddingLeft: "1rem", paddingRight: "1rem"}}
                                    >
                                        <Grid>
                                            <div style={{paddingBottom: "2rem"}}>
                                                <h5 style={{fontWeight: "bold"}}>Temas / Seções</h5>
                                            </div>
                                            {this.state.selectedTrainingData.temas.map((val, idx) => {
                                                return (
                                                <div key={idx} style={{paddingBottom: "0.5rem"}}>
                                                    {val.tema} / {val.secao}
                                                </div>
                                                )
                                            })}
                                        </Grid>
                                        <Grid>
                                            <div style={{paddingBottom: "2rem"}}>
                                                <h5 style={{fontWeight: "bold"}}>Configuração</h5>
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Difíceis: {this.state.selectedTrainingData.dificeis}
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Médias: {this.state.selectedTrainingData.medias}
                                            </div>
                                            <div style={{paddingBottom: "0.5rem"}}>
                                                Fáceis: {this.state.selectedTrainingData.faceis}
                                            </div>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            )}
                        </Paper>
                        :
                        <ReactTable
                            style={{width: "580px", height: "480px", overflow: 'auto'}}
                            filterable={true}
                            data={this.state.trainings_results}
                            pageSize={10}
                            className="-striped -highlight"
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                            }
                            defaultSorted={[{id: 'tema', desc: false}]}
                            columns={[
                                {
                                    accessor: "id_treinamento",
                                    Header: "id",
                                    width: 80,
                                    Cell: row => (
                                        <div style={{
                                            height: "22px",
                                            verticalAlign: "middle",
                                            position: "relative",
                                            textTransform: "capitalize",
                                            textAlign: "center" }}
                                        > {row.value} </div> )
                                },
                                {
                                    accessor: "nome",
                                    Header: "Nome",
                                    Cell: row => (
                                        <div style={{
                                            height: "22px",
                                            verticalAlign: "middle",
                                            position: "relative",
                                            textTransform: "capitalize",
                                            textAlign: "center" }}
                                        > {row.value} </div> )
                                },
                                {
                                    Header: "Tema",
                                    accessor: "tema",
                                    id: 'tema',
                                    width: 180,
                                    PivotValue: row => (
                                        <div style={{
                                            height: "22px",
                                            verticalAlign: "middle",
                                            position: "relative",
                                            textTransform: "capitalize",
                                            textAlign: "center" }}
                                        > {row.value} </div> )
                                }, {
                                    Header: "Perguntas",
                                    accessor: "num_perguntas",
                                    id: 'num_perguntas',
                                    width: 100,
                                    PivotValue: row => (
                                        <div style={{
                                            height: "22px",
                                            verticalAlign: "middle",
                                            position: "relative",
                                            textTransform: "capitalize",
                                            textAlign: "center" }}
                                        > {row.value} </div> )
                                }, {
                                    Header: "% de Acerto",
                                    width: 100,
                                    id: 'acerto',
                                    accessor: this.corectAnswersAccessor,
                                    Cell: row => (
                                        <div style={{
                                            height: "22px",
                                            verticalAlign: "middle",
                                            position: "relative",
                                            textTransform: "capitalize",
                                            textAlign: "center" }}
                                        >
                                        {row.value} % </div> )
                                }
                            ]}
                            />
                        }
                        <Grid
                            container
                            justify="space-between"
                            style={{paddingTop: "3rem", display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.selectedTrainingId == ''}
                                    onClick={this.deleteTreinamento}
                                >
                                    Excluir
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.selectedTrainingId == ''}
                                    onClick={() => this.openDialog(true)}
                                >
                                    Editar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            <Form
                open={this.state.dialog}
                close={this.closeDialog}
                treinamento={this.state.selectedTrainingData}
                edicao={this.state.edicao}
                isLoading={true}
            />
            <Quiz
                open={this.state.quizDialog}
                close={this.closeQuizDialog}
                titulo={this.state.selectedTrainingData.nome}
                quizType='treinamento'
                id_treinamento={this.state.selectedTrainingId}
                isLoading={true}
            />
            </div>
        )
    }
}

export default Treinamento;
