import React from "react";
import {
  Grid,
  Button,
  DialogContent,
  Dialog,
  Typography,
  Icon,
  TextField,
} from "@material-ui/core";
import AppState from "../../../state";
import { browserHistory } from "react-router";
import PropTypes from "prop-types";
import { Utils } from "../../../services";

import Tooltip from "../../../components/lib/Tooltip";
import { VictoryTooltip,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
} from "victory";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cnpj_fornecedor: this.props.cnpj_fornecedor,
      dt_plano_acao: this.props.dt_plano_acao,
      nome_fornecedor: this.props.nome_fornecedor,
      dialog: false,
      plano_acao: '',
      chartData: [{x: 1, y: 0}, {x: 2, y: 0}, {x: 3, y: 0}, {x: 4, y: 0}, {x: 5, y: 0}],
      edicao: false
    };
  }

  componentDidMount() {
    this.getData()
  }

  reduceEvaluationData = (data) => {
    let response = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0
    };
    for (let i = 0; i < data.length; i++) {
      let actual = data[i];
      response[parseInt(actual.avaliacao_fornecedor)] = response[parseInt(actual.avaliacao_fornecedor)] + 1;
    }
    if (data.length == 0) {
      return [{x: 1, y: 0}, {x: 2, y: 0}, {x: 3, y: 0}, {x: 4, y: 0}, {x: 5, y: 0}];
    }
    return Object.keys(response).map((key) => {return {x: key, y: response[key]}});
  }
  
  handleChange = (event) => {
    this.setState({plano_acao: event.target.value});
  }

  getData = () => {
    AppState.isLoading = true;

    fetch(
      `${AppState.serverAddr}/fornecedores/GetPlanodeAcao.php?json={"dt_plano_acao":"${this.state.dt_plano_acao}","cpf_cnpj_fornecedor":"${this.state.cnpj_fornecedor}"}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: AppState.token
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          return response.json().then(json => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then(response => {
        let edicao = false;
        let plano_acao = "";
        if (response.request.length > 0) {
          if (response.request[0].plano_acao) {
            edicao = true;
            plano_acao = response.request[0].plano_acao;
          }
        }
        this.setState(
          {
            chartData: this.reduceEvaluationData(response.request),
            plano_acao: plano_acao,
            edicao: edicao
          },
          () => (AppState.isLoading = false)
        );
      })
      .catch(function(error) {
        AppState.isLoading = false;
        if (error.toString() == "" || error.toString().split(" ").length < 2) {
          alert("Erro desconhecido, tente novamente");
          return;
        }
        var Err_status = error.toString().split(" ")[1];
        if (Err_status == 400) {
          var ret = error.response["status"].value.split(" ")[0];
          if (ret == "40001" || ret == "40002") {
            console.log("Dados invalidos/não informados");
          }
          else alert("Dados inválidos");
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert("Erro desconhecido, tente novamente");
        }
      });
  };

  addPlanoAcao = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf_cnpj_fornecedor: this.state.cnpj_fornecedor,
      dt_plano_acao: this.state.dt_plano_acao,
      plano_acao: this.state.plano_acao
    });

    var planoData = new FormData();
    planoData.append("json", data);

    fetch(
      `${AppState.serverAddr}/fornecedores/AddPlanoAcao.php`,
      {
        method: "POST",
        headers: {
          Authorization: AppState.token
        },
        body: planoData
      }
    )
      .then(response => {
        if (!response.ok) {
          return response.json().then(json => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then(response => {
        AppState.isLoading = false;
        this.closeDialog();
      })
      .catch(function(error) {
        AppState.isLoading = false;
        if (error.toString() === "" || error.toString().split(" ").length < 2) {
          alert("Erro desconhecido, tente novamente");
          return;
        }
        const Err_status = error.toString().split(" ")[1];
        if (Err_status === "400") {
          const ret = error.response["status"].value.split(" ")[0];
          if (ret === "40001" || ret === "40002") {
            console.log("ERRO dados inválidos");
            alert("Dados inválidos");
          } else if (ret === "40004") {
            console.log(
              "40004 - Erro ao tentar cadastrar PJ. Já cadastrado como funcionário"
            );
            alert("Erro ao cadastrar PJ. Já cadastrado como funcionário");
          } else if (ret === "40005") {
            console.log("40005 - PJ já cadastrado");
            alert("Erro ao cadastrar. PJ já cadastrado");
          } else if (ret === "40009") {
            alert(
              "Erro ao cadastrar. Fornecedor selecionado não está ativo ou aprovado"
            );
          } else {
            alert("Dados inválidos");
          }
        } else if (Err_status === "401") {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === "500") {
          alert("Erro desconhecido, tente novamente");
        }
      });
  };

  closeDialog = () => {
    this.setState(
      {
        plano_acao: "",
        cpf_cnpj_fornecedor: "",
      },
      () => this.props.close()
    );
  };

  editPlanoAcao = () => {
    const data = JSON.stringify({
      cpf_cnpj_fornecedor: this.state.cnpj_fornecedor,
      dt_plano_acao: this.state.dt_plano_acao,
      plano_acao: this.state.plano_acao
    });

    AppState.isLoading = true;

    var planoData = new FormData();
    planoData.append("json", data);

    fetch(
      `${AppState.serverAddr}/fornecedores/EditPlanoAcao.php`,
      {
        method: "POST",
        headers: {
          Authorization: AppState.token
        },
        body: planoData
      }
    )
      .then(response => {
        if (!response.ok) {
          return response.json().then(json => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then(response => {
        AppState.isLoading = false;
        this.closeDialog();
      })
      .catch(function(error) {
        AppState.isLoading = false;
        if (error.toString() === "" || error.toString().split(" ").length < 2) {
          alert("Erro desconhecido, tente novamente");
          return;
        }
        var Err_status = error.toString().split(" ")[1];
        if (Err_status === "400") {
          var ret = error.response["status"].value.split(" ")[0];
          if (ret === "40001" || ret === "40002") {
            console.log("ERRO dados inválidos");
            alert("Dados inválidos");
          } else if (ret === "40003") {
            console.log("40003 - Erro ao atualizar Contrato");
            alert("Erro ao atualizar Contrato");
          } else if (ret === "40004") {
            console.log("40004 - Erro ao reativar Contrato");
            alert("Erro ao reativar Contrato");
          } else if (ret === "40005") {
            console.log("40005 - CPF já cadastrado como funcionário");
            alert("CPF já cadastrado como funcionário");
          } else if (ret === "40006") {
            console.log("40006 - Já existe um usuário com este cpf");
            alert("Já existe um usuário com este cpf");
          } else if (ret === "40007") {
            console.log("40007 - Usuário não encontrado");
            alert("Usuário não encontrado");
          } else if (ret === "40008") {
            console.log("40008 - Erro ao atualizar aditivos");
            alert("Erro ao atualizar aditivos");
          } else if (ret === "40009") {
            console.log("40009 - Fornecedor inválido");
            alert("Erro ao editar o Contrato. Fornecedor selecionado não está ativo ou aprovado.");
          } else if (ret === "40010") {
            console.log("40010 - Erro ao inserir aditivos");
            alert("Erro ao inserir aditivos");
          } else {
            alert("Dados inválidos");
          }
        } else if (Err_status === "401") {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === "500") {
          alert("Erro desconhecido, tente novamente");
        }
      });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        maxWidth="md"
        disableBackdropClick={true}
      >
        <DialogContent>
          <Grid
            container={true}
            alignItems={"flex-start"}
            justify={"space-around"}
            direction={"row"}
            spacing={32}
          >
            <Grid item sm={10}>
              <Typography variant="h4">{this.state.nome_fornecedor}</Typography>
            </Grid>
            <Grid item sm={2}>
              <Icon style={{ marginLeft: "80%" }} onClick={this.closeDialog}>
                close
              </Icon>
            </Grid>
            <Grid 
              item={true}
              xs={12}
              style={{ textAlign:"center", height: 200 }}
            >
            <h3>Notas do Fornecedor</h3>
            <VictoryChart
              animate={{duration: this.state.animation}}
              domainPadding={{x: [20, 20]}}
              padding={{left: 50, right: 20, top: 20, bottom: 30}}
              theme={VictoryTheme.material}
              width={400}
              height={200}
            >
              <VictoryBar 
                data={this.state.chartData.map(val => {
                  return {
                    'x': val.x, 
                    'y': val.y,
                    'yLabel': val.y,
                    'subtitle': 'Nota ' + val.x
                  }})}
                style={{ data: { fill: "#528e70" } }}
                labelComponent={<VictoryTooltip flyoutComponent={<Tooltip/>} />}
                labels={() => " "}
              />
              <VictoryAxis dependentAxis
                tickFormat={t => Math.round(t) !== t ? undefined : t.toFixed(0)}
                style={{ fontSize: 7 }}
              />
              <VictoryAxis 
                style={{ fontSize: 7 }}
                tickFormat={(x) => parseFloat(x).toFixed(0)}
              />
            </VictoryChart>
            </Grid>

            <Grid 
              item={true}
              xs={12}
              style={{ textAlign:"center", height: 150 }}
            >
              <h3>Plano de Ação</h3>
              <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                value={this.state.plano_acao}
                onChange={this.handleChange}
                rows={3}
                style={{width: 500}}
              />
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: "2em" }}
            container={true}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={this.state.edicao ? this.editPlanoAcao : this.addPlanoAcao}
            >
              Salvar
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

Form.propTypes = {
  edicao: PropTypes.bool.isRequired
};

Form.defaultProps = {
  edicao: false
};

export default Form;
