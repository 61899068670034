import React from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    Icon
} from "@material-ui/core"
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import AppState from "../../../../state";
import {browserHistory} from "react-router";
import DualListBox from 'react-dual-listbox';
import { Utils } from "../../../../services";
import AutoSelect from '../../../../components/inputs/AutoSelect'

class Form extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            nome_avaliacao: '',
            id_avaliacao: '',
            temas_secoes: [],
            temas_secoes_available: [],
            temas: [],
            tema_selecionado: '',
            temas_secoes_avaliacao: [],
            dificeis: 0,
            medias: 0,
            faceis: 0,
            dificeis_disponiveis: 0,
            medias_disponiveis: 0,
            faceis_disponiveis: 0,
            grupos_usuarios: [],
            grupos_usuarios_avaliacao: [],
            dt_inicio: '',
            dt_fim: '',
            status: '',
            avaliacao_respondida: false
        }
    }

    componentDidMount() {
        AppState.isLoading = this.props.isLoading;
        this.getTemasSecoes(this.props.isLoading);
        // this.getGruposUsuarios(this.props.isLoading);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edicao != this.props.edicao) {
            this.setState({
                edicao: this.props.edicao
            },()=>{
                if (this.props.edicao) {
                    this.setState({
                        temas_secoes_avaliacao:  Object.keys(this.props.avaliacao.temas),
                        grupos_usuarios_avaliacao: this.props.avaliacao.grupos.map((val) => {return {'label': val.nome_grupo, 'value': val.id_grupo}}),
                        dificeis: this.props.avaliacao.questoes.filter((x) => x.nivel == 3).length,
                        medias: this.props.avaliacao.questoes.filter((x) => x.nivel == 2).length,
                        faceis: this.props.avaliacao.questoes.filter((x) => x.nivel == 1).length,
                        nome_avaliacao: this.props.avaliacao.nome_avaliacao,
                        id_avaliacao: this.props.avaliacao.id_avaliacao,
                        dt_inicio: this.props.avaliacao.dt_inicio,
                        dt_fim: this.props.avaliacao.dt_fim,
                        status: this.props.avaliacao.status == 't' ? true : false,
                        avaliacao_respondida: this.props.avaliacao.avaliacao_respondida == 't' ? true : false
                    }, () => {
                        this.getQuestoesDisponiveis();
                        this.getTemasSecoes(this.props.isLoading);
                        this.getGruposUsuarios(this.props.isLoading);
                    });
                } else {
                    this.getQuestoesDisponiveis();
                    this.getTemasSecoes(this.props.isLoading);
                    this.getGruposUsuarios(this.props.isLoading);
                }
            })
        }
    }

    closeDialog = () =>{
        this.setState({
            nome_avaliacao: '',
            id_avaliacao: '',
            tema_selecionado: '',
            temas_secoes_avaliacao: [],
            dificeis: 0,
            medias: 0,
            faceis: 0,
            dificeis_disponiveis: 0,
            medias_disponiveis: 0,
            faceis_disponiveis: 0,
            grupos_usuarios: [],
            grupos_usuarios_avaliacao: [],
            dt_inicio: '',
            dt_fim: '',
            status: '',
            avaliacao_respondida: false
        })
        this.props.close()
    }

    temaHandleChange(event) {
        const available = [];
        const temas_secoes = this.state.temas_secoes;
            for (let i=0; i < temas_secoes.length; i++){
                          available.push(
                          {
                              'value': temas_secoes[i].id_tema_secao,
                              'label': temas_secoes[i].tema + ' / ' + temas_secoes[i].secao
                          });
            }

        this.setState({
            [event.target.name]: event.target.value,
            temas_secoes_available: Object.keys(available)
        })
    }




    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    selectedHandleChange(selected) {
        this.setState({
            temas_secoes_avaliacao: selected
        }, () => {
            this.getQuestoesDisponiveis();
        })
    }

    groupHandleChange = event => {
        this.setState({
            grupos_usuarios_avaliacao: event,
        })
    }

    getTemasSecoes = (isLoading = false) => {
        fetch(`${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasSecoes.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    temas_secoes: response.request,
                    temas: [...new Set(response.request.map((val) => {return val.tema}))]
                }, () => {
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    };
                    });
                })
            .catch(function(error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }

    getGruposUsuarios = (isLoading = false) => {
        fetch(`${AppState.serverAddr}/modulo-treinamento/usuarios/GetGruposUsuarios.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                }
            })
            .then((response) => {
                this.setState({
                    grupos_usuarios: response.request.map((val) => {return {'label': val.nome, 'value': val.id_grupo}})
                }, () => {
                    if (isLoading == false) {
                        AppState.isLoading = isLoading;
                    };
                    });
                })
            .catch(function(error) {
                if (isLoading == false) {
                    AppState.isLoading = isLoading;
                }
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if(ret == '40001') {
                        console.log("40001 - Erro desconhecido");
                    }
                    else
                        alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })

    }

    getQuestoesDisponiveis = () => {
        AppState.isLoading = true
        let temas_secoes = Object.keys(this.state.temas_secoes_avaliacao);
        fetch(`${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasQuestoesDisponiveis.php?json=["${temas_secoes}"]`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization':AppState.token,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        let error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                } else {
                    const res = response.json()
                    return res;
                    AppState.isLoading = false
                }
            })
            .then((response) => {
                AppState.isLoading = false;
                let faceis = response.request[0].faceis
                let medias = response.request[0].medias
                let dificeis = response.request[0].dificeis
                this.setState({
                    faceis_disponiveis: faceis ? faceis : 0,
                    medias_disponiveis: medias ? medias : 0,
                    dificeis_disponiveis: dificeis ? dificeis : 0
                })
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    alert("Dados inválidos");
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    addAvaliacao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            nome: this.state.nome_avaliacao,
            dt_inicio: this.state.dt_inicio,
            dt_fim: this.state.dt_fim,
            faceis: this.state.faceis,
            medias: this.state.medias,
            dificeis: this.state.dificeis,
            temas: this.state.temas_secoes_avaliacao,
            grupos_usuarios: this.state.grupos_usuarios_avaliacao.map((x) => x.value)
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/avaliacoes/AddAvaliacao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })

            .then((response) => {
                if(!response.ok) {
                    return response.json().then((json) => {
                        var error = new Error(response.status);
                        error.response = json;
                        throw error;
                    });
                }

                const res = response.json()
                return res;
                AppState.isLoading = false
            })
            .then((response) => {
                AppState.isLoading = false;
                this.closeDialog()

            })
            .catch(function(error) {
                AppState.isLoading = false;
                if(error.toString() == "" || error.toString().split(' ').length < 2){
                    alert("Erro desconhecido, tente novamente");
                    return ;
                }
                var Err_status = error.toString().split(' ')[1];
                if(Err_status == 400) {
                    var ret = error.response['status'].value.split(' ')[0];
                    if (ret == '40004') {
                        alert('Número de questões inválido')
                    } else {
                        alert("Dados inválidos");
                    }
                }
                else if(Err_status == 401) {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                }
                else if(Err_status == 500) {
                    alert("Erro desconhecido, tente novamente");
                }
            })
    }

    editAvaliacao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_avaliacao: this.state.id_avaliacao,
            nome: this.state.nome_avaliacao,
            dt_inicio: this.state.dt_inicio,
            dt_fim: this.state.dt_fim,
            faceis: this.state.faceis,
            medias: this.state.medias,
            dificeis: this.state.dificeis,
            temas: this.state.temas_secoes_avaliacao,
            grupos_usuarios: this.state.grupos_usuarios_avaliacao.map((x) => x.value),
            atualizar_questoes: !this.state.avaliacao_respondida,
            status: this.state.status
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/avaliacoes/UpdateAvaliacao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((json) => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            }

            const res = response.json()
            return res;
            AppState.isLoading = false
        })
        .then((response) => {
            AppState.isLoading = false;
            this.closeDialog()
        })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if (ret == '40004') {
                    alert('Número de questões inválido')
                } else {
                    alert("Dados inválidos");
                }
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    render() {
        return (
            <Dialog
                style={{ height: "110vh" }}
                maxWidth={"md"}
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.close}
                disableBackdropClick={true}
            >
                <DialogContent>
                    <Icon
                        style={{ float: "right" }}
                        title={"fechar"}
                        onClick={this.closeDialog}
                    >
                        close
                    </Icon>
                <Typography variant="h4" style={{marginBottom:'40px'}}>Avaliação</Typography>

                <Grid
                    container
                    justify='space-between'
                    spacing={32}
                >
                    <Grid
                        container
                        sm={6}
                        style={{paddingLeft: "3rem", paddingRight: "3rem"}}
                    >
                        <TextField
                            value={this.state.nome_avaliacao}
                            label={"Nome da Avaliação"}
                            name={"nome_avaliacao"}
                            fullWidth
                            onChange={(e) => this.handleChange(e)}
                        />

                        <Grid
                            item
                            fullWidth={true}
                            style={{display: "flex", flexDirection: "row", marginTop: "2rem", width: "100%"}}
                        >

                            <FormControl style={{width: "33%"}} >
                                <TextField label={"Dificeis - Máx " + this.state.dificeis_disponiveis}
                                    value={this.state.dificeis}
                                    type={"number"} name={"dificeis"}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={this.state.dificeis_disponiveis == 0 || this.state.avaliacao_respondida}
                                    InputProps={{inputProps: { max: this.state.dificeis_disponiveis, min: 0 }}}
                                />
                            </FormControl>

                            <FormControl
                            style={{marginLeft: "4rem", marginRight: "4rem", width: "33%"}}
                            >
                                <TextField label={"Medias - Máx " + this.state.medias_disponiveis}
                                    value={this.state.medias}
                                    type={"number"} name={"medias"}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={this.state.medias_disponiveis == 0 || this.state.avaliacao_respondida}
                                    InputProps={{inputProps: { max: this.state.medias_disponiveis, min: 0 }}}
                                />
                            </FormControl>

                            <FormControl style={{width: "33%"}} >
                                <TextField label={"Faceis - Máx " + this.state.faceis_disponiveis}
                                    value={this.state.faceis}
                                    type={"number"} name={"faceis"}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={this.state.faceis_disponiveis == 0 || this.state.avaliacao_respondida}
                                    InputProps={{inputProps: { max: this.state.faceis_disponiveis, min: 0 }}}
                                />
                            </FormControl>
                        </Grid>

                        <Grid
                            container
                            style={{paddingTop: "2rem", width: "100%"}}
                            justify='flex-end'
                            alignItems='flex-end'
                        >
                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="temas">Selecione um Tema</InputLabel>
                                <Select
                                    value={ this.state.tema_selecionado }
                                    inputProps={{
                                        name: 'tema_selecionado',
                                        id: 'tema_selecionado',
                                    }}
                                    onChange={(e) => this.temaHandleChange(e)}
                                    disabled={this.state.avaliacao_respondida}
                                >
                                    {this.state.temas.map((item, idx)=>(
                                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justify='space-between'
                        sm={6}
                        style={{paddingLeft: "3rem", paddingRight: "3rem"}}
                    >
                        {this.state.id_avaliacao !== '' && (
                            <Grid
                                item
                                fullWidth={true}
                                style={{display: "flex", flexDirection: "row", width: "100%"}}
                                onClick={() => {
                                    this.setState(prevState  => ({status: !prevState.status})
                                )}}
                            >
                                <h5 style={{fontWeight: "bold"}}>Status:</h5>
                                <Grid
                                    container
                                    style={{paddingBottom: "0.5rem"}}
                                    direction="row"
                                    alignItems="center"
                                    justify="flex-end"
                                >
                                    {this.state.status ? (
                                    <Grid container alignItems='center' justify='center'>
                                        <Check/>
                                        Ativo
                                    </Grid> ) :(
                                    <Grid container alignItems='center' justify='center'>
                                        <Cancel/>
                                        Inativo
                                    </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            container
                            fullWidth={true}
                            style={{marginTop: "2rem"}}
                        >
                            <Grid
                                container
                                style={{paddingBottom: "0.5rem"}}
                                alignItems="flex-end"
                            >
                                <h5 style={{fontWeight: "bold"}}>Período</h5>
                            </Grid>
                            <Grid
                                container
                                style={{paddingBottom: "0.5rem"}}
                                direction="row"
                                alignItems="flex-end"
                                justify="space-between"
                            >
                                Inicio:
                                <TextField
                                    value={this.state.dt_inicio}
                                    type={"date"} name={"dt_inicio"}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Grid>
                            <Grid
                                container
                                style={{paddingBottom: "0.5rem"}}
                                direction="row"
                                alignItems="flex-end"
                                justify="space-between"
                            >
                                Fim:
                                <TextField
                                    value={this.state.dt_fim}
                                    type={"date"} name={"dt_fim"}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            fullWidth={true}
                            style={{marginTop: "2rem"}}
                        >
                            <Grid
                                container
                                alignItems="flex-end"
                            >
                                <h5 style={{fontWeight: "bold"}}>Grupos</h5>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-end"
                                justify="space-between"
                            >
                                {this.state.grupos_usuarios.length > 0 &&
                                    <AutoSelect
                                        suggestions={this.state.grupos_usuarios}
                                        placeholder='Grupos'
                                        multi={true}
                                        value={this.state.grupos_usuarios_avaliacao}
                                        initialValue={this.state.grupos_usuarios_avaliacao}
                                        handleChange2={this.groupHandleChange}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid
                    container
                    spacing={32}
                    style={{display: "flex", flexDirection: "row", marginTop: "3rem"}}
                >
                    <div style={{width: "100%", paddingTop: "3rem", paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "3rem"}}>
                        <DualListBox
                            options={this.state.temas_secoes.map((val) => {
                                return {
                                    'value': val.id_tema_secao,
                                    'label': val.tema + ' / ' + val.secao
                                }
                            })}
                            selected={this.state.temas_secoes_avaliacao}
                            available={this.state.temas_secoes_available}
                            onChange={(e) => this.selectedHandleChange(e)}
                            showHeaderLabels={true}
                            showNoOptionsText={!this.state.avaliacao_respondida}
                            disabled={this.state.avaliacao_respondida}
                            lang={{
                                availableHeader: 'Seções do Tema',
                                moveAllLeft: 'Remover todos',
                                moveAllRight: 'Adicionar todos',
                                moveLeft: 'Remover',
                                moveRight: 'Adicionar',
                                noAvailableOptions: this.state.tema_selecionado == '' ? "Selecione um tema":  'Não há seções disponíveis',
                                noSelectedOptions: 'Nenhuma seção selecionada',
                                selectedHeader: 'Meus temas / seções',
                            }}
                        />
                    </div>
                </Grid>

                <Grid style={{marginTop: "2em"}} container={true} direction="row" justify="center" alignItems="center">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.state.edicao ? this.editAvaliacao : this.addAvaliacao}
                    >
                        Salvar
                    </Button>
                </Grid>

                </DialogContent>
            </Dialog>)
    }

}

export default Form;
