import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Switch,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Icon,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import AppState from '../../../state';
import { browserHistory } from 'react-router';

const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};
CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CNPJMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};
CNPJMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TelMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={true}
    />
  );
};
TelMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CEPMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};
CEPMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edicao: false,
      natureza: 'PJ',
      nomeFantasia: '',
      nomeRazaoSocial: '',
      cpf_cnpj: '',
      cep: '',
      tipoCliente: '',
      endereco: '',
      numeroEndereco: '',
      bairro: '',
      complemento: '',
      isActive: true,
      cidade: '',
      uf: '',
      telComercial: '',
      emailComercial: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      possuiISO: 'true',
      tipoClienteList: [],
    };
    this.getTipoCliente();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edicao != this.props.edicao) {
      this.setState(
        {
          edicao: this.props.edicao,
        },
        () => {
          if (this.props.edicao) {
            this.getClienteData();
          }
        }
      );
    }
  }

  getClienteData = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/cliente/GetCliente.php?json={"cpf_cnpj":"${this.props.cliente}"}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          natureza:
            response.request[0].cpf_cnpj_cliente.length == 18 ? 'PJ' : 'PF',
          nomeFantasia: response.request[0].nome_fantasia
            ? response.request[0].nome_fantasia
            : '',
          nomeRazaoSocial: response.request[0].razao_social
            ? response.request[0].razao_social
            : '',
          cpf_cnpj: response.request[0].cpf_cnpj_cliente
            ? response.request[0].cpf_cnpj_cliente
            : '',
          tipoCliente: response.request[0].tipo_cliente
            ? response.request[0].tipo_cliente
            : '',
          cep: response.request[0].cep_comercial
            ? response.request[0].cep_comercial
            : '',
          endereco: response.request[0].end_comercial
            ? response.request[0].end_comercial
            : '',
          numeroEndereco: response.request[0].end_numero
            ? response.request[0].end_numero
            : '',
          complemento: response.request[0].end_complemento
            ? response.request[0].end_complemento
            : '',
          cidade: response.request[0].end_cidade
            ? response.request[0].end_cidade
            : '',
          uf: response.request[0].end_uf ? response.request[0].end_uf : '',
          bairro: response.request[0].end_bairro
            ? response.request[0].end_bairro
            : '',
          possuiISO: response.request[0].tem_iso === 't' ? 'true' : 'false',
          emailComercial: response.request[0].email_comercial
            ? response.request[0].email_comercial
            : '',
          telComercial: response.request[0].tel_comercial
            ? response.request[0].tel_comercial
            : '',
          inscricaoEstadual: response.request[0].inscricao_estadual
            ? response.request[0].inscricao_estadual
            : '',
          inscricaoMunicipal: response.request[0].inscricao_municipal
            ? response.request[0].inscricao_municipal
            : '',
          isActive: response.request[0].ativo == 't' ? true : false,
          wasActive: response.request[0].ativo == 't' ? true : false,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002') {
            console.log('40001 id_projeto invalido/não informado');
          } else alert('Dados inválidos');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  getTipoCliente = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/cliente/GetTipoCliente.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;

        this.setState({
          tipoClienteList: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40002') {
            console.log(
              '40002 Ocorreu um erro ao acessar os tipos de clientes'
            );
          } else alert('Ocorreu um erro ao acessar os tipos de clientes');
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  handleChange = (event) => {
    console.log(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  statusHandleChange = (event) => {
    if (event.target.checked) {
      this.setState({
        isActive: event.target.checked,
      });
    } else {
      this.setState({
        isActive: event.target.checked,
      });
    }
  };

  closeDialog = () => {
    this.setState(
      {
        edicao: false,
        natureza: 'PJ',
        nomeFantasia: '',
        nomeRazaoSocial: '',
        cpf_cnpj: '',
        cep: '',
        tipoCliente: '',
        endereco: '',
        numeroEndereco: '',
        bairro: '',
        complemento: '',
        isActive: true,
        cidade: '',
        uf: '',
        telComercial: '',
        emailComercial: '',
        inscricaoEstadual: '',
        inscricaoMunicipal: '',
        possuiISO: 'true',
        tipoClienteErro: false,
        nomeRazaoSocialErro: false,
        cpf_cnpjErro: false,
      },
      () => this.props.close()
    );
  };

  CEPhandleChange = (event) => {
    this.setState(
      {
        cep: event.target.value,
      },
      () => {
        if (this.state.cep.length === 9) {
          this.getEndereco(this.state.cep.replace('-', ''));
        }
      }
    );
  };

  getEndereco = (cep) => {
    AppState.isLoading = true;
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
          AppState.isLoading = false;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          endereco: response.logradouro,
          bairro: response.bairro,
          cidade: response.localidade,
          uf: response.uf,
        });

        if (response.erro == true) {
          this.setState({
            endereco: '',
            bairro: '',
            cidade: '',
            uf: '',
          });
        }
      });
  };

  addCliente = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf_cnpj: this.state.cpf_cnpj,
      razao_social: this.state.nomeRazaoSocial,
      tipo_cliente: this.state.tipoCliente,
      nome_fantasia: this.state.nomeFantasia,
      tel_comercial: this.state.telComercial,
      email_comercial: this.state.emailComercial,
      cep_comercial: this.state.cep,
      end_comercial: this.state.endereco,
      end_numero: this.state.numeroEndereco,
      end_complemento: this.state.complemento,
      end_bairro: this.state.bairro,
      end_cidade: this.state.cidade,
      end_uf: this.state.uf,
      tem_iso: this.state.possuiISO,
      inscricao_municipal: this.state.inscricaoMunicipal,
      inscricao_estadual: this.state.inscricaoEstadual,
      ativo: 'true',
    });

    fetch(
      `${AppState.serverAddr}/cliente/AddCliente.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40003') {
            console.log('40003 - Erro ao cadastrar Cliente');
            alert('Erro ao cadastrar o Cliente');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  editCliente = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf_cnpj: this.state.cpf_cnpj,
      razao_social: this.state.nomeRazaoSocial,
      tipo_cliente: this.state.tipoCliente,
      nome_fantasia: this.state.nomeFantasia,
      tel_comercial: this.state.telComercial,
      email_comercial: this.state.emailComercial,
      cep_comercial: this.state.cep,
      end_comercial: this.state.endereco,
      end_numero: this.state.numeroEndereco,
      end_complemento: this.state.complemento,
      end_bairro: this.state.bairro,
      end_cidade: this.state.cidade,
      end_uf: this.state.uf,
      tem_iso: this.state.possuiISO,
      inscricao_municipal: this.state.inscricaoMunicipal,
      inscricao_estadual: this.state.inscricaoEstadual,
      ativo: this.state.isActive ? 'true' : 'false',
    });

    fetch(
      `${AppState.serverAddr}/cliente/EditCliente.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.props.carregaTabela();
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40003') {
            console.log('40003 - Erro ao Editar Fornecedor');
            alert('Erro ao Editar o Fornecedor');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  validaForm = () => {
    let formValido = true;

    if (this.state.natureza === 'PJ') {
      if (this.state.cpf_cnpj.length < 18 || this.state.cpf_cnpj == '') {
        formValido = false;
        this.setState({
          cpf_cnpjErro: true,
        });
      }
    } else if (this.state.natureza === 'PF') {
      if (this.state.cpf_cnpj.length < 14 || this.state.cpf_cnpj == '') {
        formValido = false;
        this.setState({
          cpf_cnpjErro: true,
        });
      }
    }

    if (this.state.nomeRazaoSocial == '') {
      formValido = false;
      this.setState({
        nomeRazaoSocialErro: true,
      });
    }

    if (this.state.tipoCliente == '') {
      formValido = false;
      this.setState({
        tipoClienteErro: true,
      });
    }

    if (formValido) {
      if (this.state.edicao === true) {
        this.editCliente();
      } else {
        this.addCliente();
      }
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <DialogContent>
          <Grid
            container={true}
            alignItems={'flex-start'}
            justify={'space-around'}
            direction={'row'}
            spacing={40}
          >
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4">Cadastro de Cliente</Typography>
              <Icon onClick={this.closeDialog}>close</Icon>
            </Grid>
            <Grid item={true} md={6}>
              <FormControl component="fieldset">
                <FormLabel>Natureza</FormLabel>
                <RadioGroup
                  name="natureza"
                  row={true}
                  value={this.state.natureza}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="PF"
                    control={<Radio color={'primary'} />}
                    label="Pessoa Física"
                  />
                  <FormControlLabel
                    value="PJ"
                    control={<Radio color={'primary'} />}
                    label="Pessoa Jurídica"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={this.state.natureza == 'PF' ? 'CPF' : 'CNPJ'}
                  name={'cpf_cnpj'}
                  InputProps={{
                    inputComponent:
                      this.state.natureza == 'PF'
                        ? CPFMaskCustom
                        : CNPJMaskCustom,
                    readOnly: this.state.edicao,
                  }}
                  value={this.state.cpf_cnpj}
                  onChange={this.handleChange}
                  error={this.state.cpf_cnpjErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={this.state.natureza == 'PF' ? 'Nome' : 'Razão Social'}
                  name={'nomeRazaoSocial'}
                  value={this.state.nomeRazaoSocial}
                  onChange={this.handleChange}
                  error={this.state.nomeRazaoSocialErro}
                />
              </FormControl>

              {this.state.natureza === 'PJ' && (
                <FormControl fullWidth={true}>
                  <TextField
                    label={'Nome Fantasia'}
                    name={'nomeFantasia'}
                    value={this.state.nomeFantasia}
                    onChange={this.handleChange}
                    error={this.state.nomeFantasiaErro}
                  />
                </FormControl>
              )}

              <FormControl fullWidth={true}>
                <InputLabel>Tipo de Cliente</InputLabel>
                <Select
                  name={'tipoCliente'}
                  value={this.state.tipoCliente}
                  onChange={this.handleChange}
                  error={this.state.tipoClienteErro}
                >
                  {this.state.tipoClienteList.map((item, index) => (
                    <MenuItem key={index} value={item.tipo_cliente}>
                      {item.tipo_cliente}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'CEP'}
                  name={'cep'}
                  value={this.state.cep}
                  InputProps={{
                    inputComponent: CEPMaskCustom,
                  }}
                  onChange={this.CEPhandleChange}
                  error={this.state.cepErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Endereço'}
                  name={'endereco'}
                  value={this.state.endereco}
                  onChange={this.handleChange}
                  error={this.state.enderecoErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Número'}
                  name={'numeroEndereco'}
                  value={this.state.numeroEndereco}
                  onChange={this.handleChange}
                  error={this.state.numeroEnderecoErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Bairro'}
                  name={'bairro'}
                  value={this.state.bairro}
                  onChange={this.handleChange}
                  error={this.state.bairroErro}
                />
              </FormControl>
            </Grid>

            <Grid item={true} md={6}>
              <FormControlLabel
                style={{ marginTop: '20px' }}
                control={
                  <Switch
                    value={this.state.isActive}
                    disabled={this.state.edicao === false}
                    color={'primary'}
                    checked={this.state.isActive === true}
                    onChange={this.statusHandleChange}
                  />
                }
                label={'Cliente ' + (this.state.isActive ? 'Ativo' : 'Inativo')}
              />

              <FormControl fullWidth={true}>
                <TextField
                  label={'Complemento'}
                  name={'complemento'}
                  value={this.state.complemento}
                  onChange={this.handleChange}
                  error={this.state.complementoErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Cidade'}
                  name={'cidade'}
                  value={this.state.cidade}
                  onChange={this.handleChange}
                  error={this.state.cidadeErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'UF'}
                  name={'uf'}
                  value={this.state.uf}
                  onChange={this.handleChange}
                  error={this.state.ufErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Telefone Comercial'}
                  name={'telComercial'}
                  value={this.state.telComercial}
                  InputProps={{
                    inputComponent: TelMaskCustom,
                  }}
                  onChange={this.handleChange}
                  error={this.state.telComercialErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Email Comercial'}
                  name={'emailComercial'}
                  value={this.state.emailComercial}
                  onChange={this.handleChange}
                  error={this.state.emailComercialErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Inscrição Estadual'}
                  name={'inscricaoEstadual'}
                  value={this.state.inscricaoEstadual}
                  onChange={this.handleChange}
                  error={this.state.inscricaoEstadualErro}
                />
              </FormControl>

              <FormControl fullWidth={true}>
                <TextField
                  label={'Inscrição Municipal'}
                  name={'inscricaoMunicipal'}
                  value={this.state.inscricaoMunicipal}
                  onChange={this.handleChange}
                />
              </FormControl>

              <FormControl
                fullWidth={true}
                component="fieldset"
                style={{ marginTop: '1em' }}
              >
                <FormLabel>Possui ISO 9000</FormLabel>
                <RadioGroup
                  aria-label="iso 9000"
                  name="possuiISO"
                  row={true}
                  value={this.state.possuiISO}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color={'primary'} />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color={'primary'} />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: '2em' }}
            container={true}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              onClick={this.validaForm}
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Form;
