import React from "react";
import {FormControl, Grid, MenuItem, InputLabel, Select, TextField, Button} from "@material-ui/core";
import ReactTable from "react-table";
import AppState from "../../../state";
import {browserHistory} from "react-router";
import moment from "moment";
import debounce from 'lodash.debounce';
import _ from "lodash";
import ReactExport from "react-data-export";
import { Utils } from "../../../services";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RelatorioAvaliacoes extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            trials: [],
            table_data: [],
            period: "EsseMes",
            dtInicio: moment().startOf('month').format('YYYY-MM-DD'),
            dtFim: moment().endOf('month').format('YYYY-MM-DD'),
            avaliacoes: [],
            avaliacao_selecionada: '0',
            completition: 0
        };
        this._debouncedHandleChange = debounce(this._debouncedHandleChange, 1000);
    }

    componentDidMount() {
        this.getTrials();
    }

    safe_division = (num, den) => {
        if (num && den && den > 0) {
            return num / den
        } else {
            return 0
        }
    }

    dateFilterHandleChange = name => event => {
        this.setState(
            {
            [name]: event.target.value
            },
            () => {
            if (
                this.state.dtInicio.length === 10 &&
                this.state.dtFim.length === 10
            )
                this._debouncedHandleChange();
            }
        );
    };

    getCompletition = () => {
        let completition = 0;
        if (this.selectTable.getResolvedState().sortedData.length > 0) {
            let data = this.selectTable.getResolvedState().sortedData;
            let completed = data.filter((x) => x.compleicao == 100).length
            completition = 100 * completed / data.length
        } else {
            let completed = this.state.trials.filter((x) => x.num_questoes == x.num_respostas).length
            completition = 100 * completed / this.state.trials.length
        }
        this.setState({
            completition: completition
        })
    }

    getTrials = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/relatorios/GetRelatorioAvaliacoes.php?json={"dt_inicio":"${this.state.dtInicio}", "dt_fim":"${this.state.dtFim}"}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            let avaliacoes = response.request.map((val) => {return {'id_avaliacao': val.id_avaliacao, 'nome': val.nome}})
            avaliacoes = this.getUniqueListBy(avaliacoes, 'id_avaliacao')
            this.setState({
                trials: response.request,
                table_data: this.reduceTrialData(response.request),
                avaliacoes: avaliacoes
            }, () => {  
                AppState.isLoading = false;
                this.getCompletition();
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    corectAnswersAccessor = (row) => {
        return parseInt(100 * this.safe_division(row.num_corretas, row.num_questoes))
    }

    completeAnswersAccessor = (row) => {
        return parseInt(100 * this.safe_division(row.num_respostas, row.num_questoes))
    }


    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    reduceTrialData = (data) => {
        let filtered_data = data
        if (this.state.avaliacao_selecionada !== '0') {
            filtered_data = filtered_data.filter((x) => x.id_avaliacao == this.state.avaliacao_selecionada)
        }

        return filtered_data
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.setState({
                table_data: this.reduceTrialData(this.state.trials)
            }, () => {
                this.getCompletition();
            })
        });
    };

    periodHandleChange = event => {
        let dtInicio = "";
        let dtFim = "";
        if (event.target.value == "EsseMes") {
            dtInicio = moment().startOf('month').format('YYYY-MM-DD');
            dtFim = moment().endOf('month').format('YYYY-MM-DD');
        } else if (event.target.value == "MesAnterior") {
            dtInicio = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
            dtFim = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        }
        this.setState(
        {
            dtInicio: dtInicio,
            dtFim: dtFim,
            period: event.target.value
        },
        () => {
            if (this.state.period != "custom") {
                this.getTrials();
            }
        }
        );
    };

    dateHandleChange = name => event => {
        this.setState(
        {
            [name]: event.target.value
        },
        () => {
            if (this.state.dtInicio.length === 10 && this.state.dtFim.length === 10)
            this._debouncedHandleChange();
        }
        );
    };

    _debouncedHandleChange = () => {
        this.getTrials();
    }

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Relatório de Avaliações</h1>

                <Grid 
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid 
                        item
                    >
                        <Grid 
                            container
                            justify="space-between"
                            alignItems="center"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Grid item>
                                <FormControl
                                    component="fieldset"
                                    style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                                    >
                                    <InputLabel htmlFor="avaliacoes">Filtre uma Avaliação</InputLabel>
                                    <Select
                                        value={ this.state.avaliacao_selecionada }
                                        inputProps={{
                                            name: 'avaliacao_selecionada',
                                            id: 'avaliacao_selecionada',
                                        }}
                                        // style={{ margin: "0.5em 0px" }}
                                        onChange={(e) => this.handleChange(e)}
                                    >
                                        {this.state.avaliacoes.map((item, idx)=>(
                                            <MenuItem key={idx} value={item.id_avaliacao}>{item.nome}</MenuItem>
                                        ))}
                                        <MenuItem key={this.state.avaliacoes.length + 2} value={'0'}>Todas</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                component="fieldset"
                                style={{ width: "200px", paddingTop: "13px", marginLeft: "13px" }}
                                >
                                <InputLabel htmlFor={"period"}>
                                    {" "}
                                    Inicio da Avaliação{" "}
                                </InputLabel>
                                <Select
                                    inputProps={{ id: "period", name: "period" }}
                                    // style={{ margin: "0.5em 0px" }}
                                    value={this.state.period || "Ontem"}
                                    onChange={this.periodHandleChange}
                                >
                                    <MenuItem value={"EsseMes"}>Esse Mês</MenuItem>
                                    <MenuItem value={"MesAnterior"}>Mês Anterior</MenuItem>
                                    <MenuItem value={"custom"}>Customizado</MenuItem>
                                </Select>
                                </FormControl>
                                {this.state.period === "custom" && (
                                <TextField
                                    style={{
                                    marginLeft: "32px",
                                    marginRight: "16px",
                                    paddingTop: "13px"
                                    }}
                                    label="Inicio"
                                    type="date"
                                    defaultValue={this.state.dtInicio}
                                    onChange={this.dateFilterHandleChange("dtInicio")}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                                )}

                                {this.state.period === "custom" && (
                                <TextField
                                    style={{ paddingTop: "13px" }}
                                    label="Fim"
                                    type="date"
                                    defaultValue={this.state.dtFim}
                                    onChange={this.dateFilterHandleChange("dtFim")}
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                />
                                )}
                            </Grid>
                            <Grid item 
                                style={{display: "flex", flexDirection: "row"}}
                            >
                                <Grid item style={{marginRight: "1rem"}}>
                                    <ExcelFile
                                        filename={"RelatorioAvaliacoes-" + Utils.getTodayDate()}
                                        element={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.exportarExcel}
                                        >
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 192 192"
                                            style={{ fill: "#000000", paddingRight: "6px" }}
                                            >
                                            <g
                                                fill="none"
                                                fillRule="nonzero"
                                                stroke="none"
                                                strokeWidth="1"
                                                strokeLinecap="butt"
                                                strokeLinejoin="miter"
                                                strokeMiterlimit="10"
                                                strokeDasharray=""
                                                strokeDashoffset="0"
                                                fontFamily="none"
                                                fontWeight="none"
                                                fontSize="none"
                                                textAnchor="none"
                                                style={{ mixBlendMode: "normal" }}
                                            >
                                                <path d="M0,192v-192h192v192z" fill="none"></path>
                                                <g fill="#ffffff">
                                                <g id="surface1">
                                                    <path d="M110.88,0c-0.075,0.03 -0.165,0.075 -0.24,0.12l-107.52,20.4c-1.83,0.345 -3.15,1.965 -3.12,3.84v143.28c-0.03,1.875 1.29,3.495 3.12,3.84l107.52,20.4c1.11,0.21 2.265,-0.075 3.15,-0.795c0.87,-0.72 1.395,-1.785 1.41,-2.925v-19.2h65.28c4.2,0 7.68,-3.48 7.68,-7.68v-130.56c0,-4.2 -3.48,-7.68 -7.68,-7.68h-65.28v-19.2c0.015,-1.11 -0.465,-2.16 -1.29,-2.895c-0.825,-0.735 -1.935,-1.08 -3.03,-0.945zM107.52,8.4v16.68c-0.51,1.065 -0.51,2.295 0,3.36v135.96c-0.105,0.51 -0.105,1.05 0,1.56v17.64l-99.84,-19.08v-137.04zM115.2,30.72h65.28v130.56h-65.28v-19.2h15.36v-7.68h-15.36v-23.04h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36v-19.2h15.36v-7.68h-15.36zM138.24,49.92v7.68h30.72v-7.68zM25.68,60.24l21,35.88l-22.92,35.88h19.2l12.48,-23.16c0.87,-2.235 1.44,-3.945 1.68,-5.04h0.12c0.495,2.34 0.975,3.93 1.44,4.8l12.48,23.4h19.08l-22.08,-36.24l21.48,-35.52h-18l-11.4,21.24c-1.095,2.775 -1.875,4.965 -2.28,6.36h-0.12c-0.63,-2.34 -1.35,-4.425 -2.16,-6.12l-10.32,-21.48zM138.24,76.8v7.68h30.72v-7.68zM138.24,103.68v7.68h30.72v-7.68zM138.24,134.4v7.68h30.72v-7.68z"></path>
                                                </g>
                                                </g>
                                            </g>
                                            </svg>
                                            Exportar
                                        </Button>
                                        }
                                    >
                                        <ExcelSheet
                                        data={() => this.selectTable.getResolvedState().sortedData}
                                        name="Avaliações"
                                        >
                                        <ExcelColumn
                                            label="CPF"
                                            value="cpf"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Nome"
                                            value="nome_usuario"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Avaliação"
                                            value="nome"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="% Completo"
                                            value="compleicao"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="Número de Perguntas"
                                            value="num_questoes"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        <ExcelColumn
                                            label="% de acerto"
                                            value="acerto"
                                            style={{ font: { sz: "24", bold: true } }}
                                        />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </Grid>
                                <Grid item>
                                    Avaliações completas: {parseInt(this.state.completition) ? parseInt(this.state.completition) : 0}%
                                </Grid>
                            </Grid>
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.table_data}
                        pageSize={20}
                        ref={r => {
                            this.selectTable = r;
                        }}
                        className="-striped -highlight"
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        defaultSorted={[{id: 'nome_usuario', desc: false}]}
                        columns={[
                            {
                                Header: "CPF",
                                accessor: "cpf_usuario",
                                id: 'cpf',
                                width: 200,
                                PivotValue: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Nome",
                                accessor: "nome_usuario",
                                id: 'nome_usuario',
                                width: 200,
                                PivotValue: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Avaliação",
                                accessor: "nome",
                                width: 200,
                                PivotValue: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "% de compleição",
                                width: 200,
                                id: 'compleicao',
                                accessor: this.completeAnswersAccessor,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > 
                                    {row.value} % </div> )
                            }, {
                                Header: "Total de Perguntas",
                                accessor: 'num_questoes',
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "% de Acerto",
                                width: 200,
                                id: 'acerto',
                                accessor: this.corectAnswersAccessor,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > 
                                    {row.value} % </div> )
                            }
                        ]}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default RelatorioAvaliacoes;