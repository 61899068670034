import React from 'react';
import ReactTable from 'react-table';
import { observer } from 'mobx-react';
import AddIcon from '@material-ui/icons/Add';
import EditICon from '@material-ui/icons/Edit';
import { Button, Grid, Switch } from '@material-ui/core/';
import AppState from '../../state';
import { browserHistory } from 'react-router';
import Form from './Form';

class Usuarios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usuarios: [],
      openDialog: false,
      edicao: false,
      cpf: '',
    };
  }

  componentDidMount() {
    this._carregaTabela();
  }

  _carregaTabela = () => {
    AppState.isLoading = true;
    fetch(
      `${AppState.serverAddr}/usuarios/GetUsers.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        AppState.isLoading = false;
        this.setState({
          usuarios: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret === '40001') {
            console.log('40001 Invalid JSON value found');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  _handleChangeActive = (cpf, ativo) => {
    if (ativo === 'INATIVO') {
      this.unblockUser(cpf);
    } else if (ativo === 'ATIVO') {
      this.blockUser(cpf);
    }
  };

  blockUser = (cpf) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf: cpf,
    });

    fetch(
      `${AppState.serverAddr}/usuarios/BlockUser.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this._carregaTabela();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (
            ret === '40001' ||
            ret === '40002' ||
            ret === '40003' ||
            ret === '40004'
          ) {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  unblockUser = (cpf) => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      cpf: cpf,
    });

    fetch(
      `${AppState.serverAddr}/usuarios/UnblockUser.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
      })
      .then((response) => {
        AppState.isLoading = false;
        this._carregaTabela();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() === '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status === '400') {
          var ret = error.response['status'].value.split(' ')[0];
          if (
            ret === '40001' ||
            ret === '40002' ||
            ret === '40003' ||
            ret === '40004'
          ) {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else alert('Dados inválidos');
        } else if (Err_status === '401') {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status === '500') {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  _editUsuario = (row) => {
    this.setState({ edicao: true, openDialog: true, cpf: row['cpf_usuario'] });
  };

  closeDialog = () => {
    this.setState({ openDialog: false, edicao: false });
  };

  render() {
    const { cpf, edicao, openDialog } = this.state;
    return (
      <div id={this.constructor.name} style={{ marginTop: '5em' }}>
        <h1>Cadastro de Usuário</h1>
        <Grid
          item
          style={{
            display: 'flex',
            gap: '24px',
            justifyContent: 'flex-end',
            padding: '16px 0'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ openDialog: true })}
          >
            <AddIcon />
            Novo usuário
          </Button>
        </Grid>

        <ReactTable
          filterable
          data={this.state.usuarios}
          defaultPageSize={10}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .startsWith(filter.value.toLowerCase())
          }
          className="-striped -highlight"
          columns={[
            {
              Header: 'Usuários',
              columns: [
                {
                  Header: 'Nome',
                  accessor: 'nome_usuario',
                  maxWidth: 350,
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'CPF',
                  accessor: 'cpf_usuario',
                  maxWidth: 120,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Perfil',
                  accessor: 'desc_perfil',
                  maxWidth: 150,
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'E-mail',
                  accessor: 'email_usuario',
                  maxWidth: 350,
                  Cell: (row) => (
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Celular',
                  accessor: 'celular',
                  maxWidth: 120,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Fornecedor',
                  accessor: 'cpf_cnpj_fornecedor',
                  maxWidth: 160,
                  Cell: (row) => (
                    <div
                      style={{
                        textAlign: 'center',
                        position: 'relative',
                        top: '30%',
                      }}
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Ação',
                  sortable: false,
                  maxWidth: 130,
                  filterable: false,
                  Cell: (row) => (
                    <div className="action-cell">
                      {
                        <Switch
                          onChange={() =>
                            this._handleChangeActive(
                              row.original['cpf_usuario'],
                              row.original['ativo'] === 't'
                                ? 'ATIVO'
                                : 'INATIVO'
                            )
                          }
                          checked={row.original['ativo'] === 't'}
                          color="primary"
                          disabled={
                            AppState.userInfo.cpf_usuario ===
                            row.original['cpf_usuario']
                          }
                          style={{
                            margin: '0px 30px 0px 0px',
                          }}
                        />
                      }

                      {
                        <EditICon
                          onClick={() => this._editUsuario(row.original)}
                          style={{
                            cursor: 'pointer',
                            width: '22px',
                            height: '22px',
                            minWidth: '22px',
                            minHeight: '22px',
                            marginLeft: '15px',
                          }}
                        />
                      }
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
        {openDialog && (
          <Form
            openDialog={openDialog}
            closeDialog={this.closeDialog}
            edicao={edicao}
            cpf={cpf}
            carregaTabela={this._carregaTabela}
          />
        )}
      </div>
    );
  }
}

export default observer(Usuarios);
