import React from "react";
import {Grid, 
        Paper,
        Button,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import ReactTable from "react-table";
import AppState from "../../../../state";
import {browserHistory} from "react-router";
import Form from "./Form";
import Themes from "./Themes";
import EditICon from "@material-ui/icons/Edit";
import readXlsxFile from "read-excel-file";

const schema = {
    Pergunta: {
        prop: "pergunta",
        type: String,
        required: true
    },
    Nível: {
        prop: "nivel",
        type: Number,
        required: true,
        parse(value) {
            return value === "Fácil" ? 1 : value === "Médio" ? 2 : value === "Difícil" ? 3 : "";
        }
    },
    Tema: {
        prop: "tema",
        type: String,
        required: true,
    },
    Seção: {
        prop: "secao",
        type: String,
        required: true,
    },
    Resposta: {
        prop: "resposta",
        type: String,
        required: true
    },
    "Opção Correta": {
        prop: "opcao_correta",
        type: String,
        required: true
    },
    "Opção Errada 1": {
        prop: "opcao_errada_1",
        type: String,
        required: true
    },
    "Opção Errada 2": {
        prop: "opcao_errada_2",
        type: String,
        required: true
    },
    "Opção Errada 3": {
        prop: "opcao_errada_3",
        type: String,
        required: true
    }
};

class Questoes extends React.Component {
    constructor(props) {
        super(props);

        this.reactTable = React.createRef();

        this.state = {
            questions: [],
            themes: [],
            selectedQuestionId: '',
            selectedQuestionData: [],
            selected: '',
            dialog: false,
            themesDialog: false,
            edicao: false
        };
    }

    componentDidMount() {
        this.getQuestions();
        this.getThemes();

        const input = document.getElementById("raised-button-file");
        let ref = this;
        input.addEventListener("change", () => {
            readXlsxFile(input.files[0], { schema }).then(({ rows }) => {
                let errors = [];
                let ret = [];
                rows.forEach(function(entry, index) {
                    if (!ref.state.themes.find(x => x.tema === entry.tema && x.secao === entry.secao)) {
                        errors.push("Tema / Seção inserido não existe na lista cadastrada " + (index + 2));
                    } else {
                        entry.tema = entry.tema.trim();
                        entry.secao = entry.secao.trim();
                        entry.id_tema_secao = ref.state.themes.find(x => x.tema === entry.tema && x.secao === entry.secao).id_tema_secao
                    }
                    if (entry.nivel === null || entry.nivel === "") {
                        errors.push("Nível não informado ou fora do formato na linha " + (index + 2));
                    }
                    if (!entry.pergunta) {
                        errors.push("Pergunta inválida/vazia na linha " + (index + 2));
                    }
                    if (!entry.resposta) {
                        errors.push("Resposta inválida/vazia na linha " + (index + 2));
                    }
                    if (!entry.opcao_correta) {
                        errors.push("Opção Correta inválida/vazia na linha " + (index + 2));
                    }
                    if (!entry.opcao_errada_1) {
                        errors.push("Opção Errada 1 inválida/vazia na linha " + (index + 2));
                    }
                    if (!entry.opcao_errada_2) {
                        errors.push("Opção Errada 2 inválida/vazia na linha " + (index + 2));
                    }
                    if (!entry.opcao_errada_3) {
                        errors.push("Opção Errada 3 inválida/vazia na linha " + (index + 2));
                    }
                    if (entry.opcao_correta && entry.opcao_errada_1 && entry.opcao_errada_2 && entry.opcao_errada_3) {
                        entry.opcoes = [{
                            "texto": entry.opcao_correta,
                            "correto": true
                        }, {
                            "texto": entry.opcao_errada_1,
                            "correto": false
                        }, {
                            "texto": entry.opcao_errada_2,
                            "correto": false
                        }, {
                            "texto": entry.opcao_errada_3,
                            "correto": false
                        }]
                    }
                    ret.push(entry);
                });

                input.value = null;

                if (errors.length > 0) {
                    let str = "";
                    errors.forEach(function(v) {
                        str += v + "\n";
                    });
                    input.value = "";
                    alert("Os seguintes erros foram encontrados:\n" + str);
                } 
                else {
                    this.processarQuestoesBatch(JSON.stringify(ret));
                }
            });
        });
    }

    processarQuestoesBatch = dados => {
        AppState.isLoading = true;
    
        fetch(
            `${AppState.serverAddr}/modulo-treinamento/questoes/AddQuestoes.php`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: AppState.token
                },
                body: dados
            }
        )
            .then(response => {
                if (!response.ok) {
                    return response.json().then(json => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                    });
                }
        
                const res = response.json();
                return res;
            })
            .then(response => {
                AppState.isLoading = false;
                alert("Importação realizada com sucesso!");
                this.getQuestions();
            })
            .catch(function(error) {
                AppState.isLoading = false;
                if (error.toString() === "" || error.toString().split(" ").length < 2) {
                    alert("Erro desconhecido, tente novamente");
                    return;
                }
                var Err_status = error.toString().split(" ")[1];
                if (Err_status === "400") {
                    const [ret, ...message] = error.response["status"].value.split(" ");
                if (ret === "40001") {
                    console.log("ERRO dados inválidos");
                    alert("Dados inválidos");
                } else if (ret === "40002") {
                    alert("Erro: Planilha sem dados");
                } else if (ret === "40003") {
                    alert(
                    "Erro ao realizar lançamentos.\nVerifique os dados e tente novamente."
                    );
                } else if (ret === "40005" || ret === "40004") {
                    alert(`Erro ao realizar lançamentos.\n${message.join(" ")}`);
                } else {
                    alert("Dados inválidos");
                }
                } else if (Err_status === "401") {
                    browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
                } else if (Err_status === "500") {
                    alert("Erro desconhecido, tente novamente");
                }
            });
    };

    closeDialog = () => {
        this.setState({ dialog: false, edicao: false, selectedQuestionData: [], selectedQuestionId: '', selected: '' }, () => this.getQuestions());
    };

    closeThemesDialog = () => {
        this.setState({ themesDialog: false, selectedQuestionData: [], selectedQuestionId: '', selected: '' }, () => this.getQuestions());
    };
    
    openDialog = (edicao = false) => {
        this.setState({
            edicao: edicao,
            dialog: true
        });
    };

    openThemesDialog = () => {
        this.setState({
            themesDialog: true
        });
    };

    getQuestions = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/questoes/GetQuestoes.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                questions: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getThemes = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/questoes/GetTemasSecoes.php`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                themes: response.request
            }, () => {  
                AppState.isLoading = false;
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    deleteQuestao = () =>{
        AppState.isLoading = true

        const data = JSON.stringify({
            id_questao: this.state.selectedQuestionId,
        })

        fetch(`${AppState.serverAddr}/modulo-treinamento/questoes/DeleteQuestao.php`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': AppState.token
            },
            body: data
        })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((json) => {
                    var error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            }

            const res = response.json()
            return res;
            AppState.isLoading = false
        })
        .then((response) => {
            AppState.isLoading = false;
            this.closeDialog()
        })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                console.log(ret)
                alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    getQuestionData = function () {
        AppState.isLoading = true;
        fetch(`${AppState.serverAddr}/modulo-treinamento/questoes/GetQuestao.php?json={"id_questao":${this.state.selectedQuestionId}}`,
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: AppState.token
                }
            }
        )
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    let error = new Error(response.status);
                    error.response = json;
                    throw error;
                });
            } else {
                const res = response.json()
                return res;
            }
        })
        .then((response) => {
            this.setState({
                selectedQuestionData: response.request
            }, () => {  
                AppState.isLoading = false;
                this.openDialog(true);
                });
            })
        .catch(function(error) {
            AppState.isLoading = false;
            if(error.toString() == "" || error.toString().split(' ').length < 2){
                alert("Erro desconhecido, tente novamente");
                return ;
            }
            var Err_status = error.toString().split(' ')[1];
            if(Err_status == 400) {
                var ret = error.response['status'].value.split(' ')[0];
                if(ret == '40001') {
                    console.log("40001 - Erro desconhecido");
                }
                else
                    alert("Dados inválidos");
            }
            else if(Err_status == 401) {
                browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
            }
            else if(Err_status == 500) {
                alert("Erro desconhecido, tente novamente");
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        return (
            <div id={this.constructor.name} style={{ marginTop: "5em" }}>
                <h1>Base de Questões</h1>

                <Grid 
                    container
                    justify="center"
                    style={{paddingTop: "4rem"}}
                >
                    <Grid 
                        item
                    >
                        <Grid 
                            container
                            justify="flex-end"
                            alignItems="flex-end"
                            style={{display: "flex", flexDirection: "row", paddingBottom: "1rem"}}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.openThemesDialog()}
                                style={{height: "50%", marginBottom: 5}}
                            >
                                Temas / Seções
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.openDialog()}
                                style={{marginLeft: 15, marginBottom: 5, height: "50%"}}
                            >
                                <AddIcon />
                                Pergunta
                            </Button>
                            <div style={{marginLeft: 15, height: "50%"}}>
                                <a
                                    href={`${process.env.PUBLIC_URL}/${AppState.serverAddr}/arquivos/Template Questoes.xlsx`}
                                    download
                                >
                                    Baixar Template
                                </a>
                                <div>
                                    <label
                                    htmlFor="raised-button-file"
                                    style={{ width: "100%"}}
                                    >
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        id="raised-button-file"
                                        type="file"
                                        ref={this.importInput}
                                        style={{ display: "none" }}
                                    />
                                    <Button
                                        variant="outlined"
                                        component="span"
                                        fullWidth={true}
                                        color={"primary"}
                                        style={{ height: "36px" }}
                                    >
                                        Importar Questões
                                    </Button>
                                    </label>
                                </div>
                            </div>
                        </Grid>
                        <ReactTable
                        filterable={true}
                        data={this.state.questions}
                        pageSize={50}
                        className="-striped -highlight"
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                        columns={[
                            {
                                Header: "Número",
                                accessor: "id_questao",
                                width: 70,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            },{
                                Header: "Tema",
                                accessor: "tema",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Seção",
                                accessor: "secao",
                                width: 200,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Nível",
                                accessor: "nivel_texto",
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Pergunta",
                                accessor: "pergunta",
                                width: 300,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Resposta",
                                accessor: "resposta",
                                width: 300,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Ativo",
                                accessor: "status_display",
                                width: 100,
                                Cell: row => (
                                    <div style={{
                                        height: "22px",
                                        verticalAlign: "middle",
                                        position: "relative",
                                        textTransform: "capitalize",
                                        textAlign: "center" }}
                                    > {row.value} </div> )
                            }, {
                                Header: "Ação",
                                Cell: row =>
                                    <div
                                        style={{
                                        height: "22px",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        position: "relative"
                                        }}
                                    >
                                        <EditICon
                                            onClick={() =>
                                                this.setState({
                                                    selectedQuestionId: row.original.id_questao
                                                }, () => {
                                                    this.getQuestionData();
                                                })
                                            }
                                            style={{
                                                cursor: "pointer",
                                                width: "22px",
                                                height: "22px",
                                                minWidth: "22px",
                                                minHeight: "22px",
                                                marginLeft: "5px"
                                            }}
                                        />
                                    </div>
                            }
                        ]}
                        />
                    </Grid>
                </Grid>
            <Form
                open={this.state.dialog}
                close={this.closeDialog}
                edicao={this.state.edicao}
                questao={this.state.selectedQuestionData}
                isLoading={true}
            />
            <Themes
                open={this.state.themesDialog}
                close={this.closeThemesDialog}
                temas={this.state.themes}
                isLoading={true}
            />
            </div>
        )
    }
}

export default Questoes;