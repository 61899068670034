import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ReactTable from 'react-table';
import AppState from '../../state';
import { browserHistory } from 'react-router';
import { Utils } from '../../services';

class FormTipoProjeto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipo: '',
      tiposDeProjeto: [],
      novosTipos: [],
    };
  }

  componentDidMount() {
    this.getTableData();
  }

  validaTipos = () => {
    if (this.state.novosTipos.length < 1) {
      alert('Nenhum tipo foi adicionado à tabela');
    } else {
      this.saveTypes();
    }
  };

  saveTypes = () => {
    AppState.isLoading = true;

    const data = JSON.stringify({
      tipos: this.state.novosTipos,
    });

    fetch(
      `${AppState.serverAddr}/projetos/AddProjectType.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
        this.closeDialog();
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else if (ret == '40002') {
            console.log(
              '40002 Um dos tipos que você tentou inserir já está cadastrado'
            );
            alert(
              'Um dos tipos que você tentou inserir já está cadastrado no sistema'
            );
          } else if (ret == '40003') {
            console.log('40003 Erro ao criar novos tipos.');
            alert('Erro ao criar novos tipos');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  closeDialog = () => {
    this.setState(
      {
        tipo: '',
        tiposDeProjeto: [],
        novosTipos: [],
        tipoProjetoErro: false,
      },
      () => {
        this.getTableData();
      }
    );

    this.props.close();
  };

  getTableData = () => {
    fetch(
      `${AppState.serverAddr}/projetos/GetAllProjectTypes.php`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            let error = new Error(response.status);
            error.response = json;
            throw error;
          });
        } else {
          const res = response.json();
          return res;
        }
      })
      .then((response) => {
        this.setState({
          tiposDeProjeto: response.request,
        });
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001') {
            console.log('40001 erro desconhecido');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
  };

  adicionaTipo = () => {
    const type = { tipo_projeto: this.state.tipo, ativo: 't', deletavel: true };

    this.setState({
      tiposDeProjeto: [type, ...this.state.tiposDeProjeto],
      novosTipos: [type, ...this.state.novosTipos],
      tipo: '',
    });
  };

  validaForm = () => {
    let formValido = true;

    let result = this.state.tiposDeProjeto.filter(
      (item) => item.tipo_projeto == this.state.tipo
    );

    console.log(result);

    if (result.length > 0) {
      alert('Tipo já cadastrado no sistema');
      formValido = false;
      this.setState({
        tipoProjetoErro: true,
      });
    }

    if (!this.state.tipo || this.state.tipo.length <= 0) {
      formValido = false;
      this.setState({
        tipoProjetoErro: true,
      });
    }

    if (formValido) {
      this.adicionaTipo();
    }
  };

  changeTypeStatus = (type, status) => {
    console.log(type, status);

    AppState.isLoading = true;

    const data = JSON.stringify({
      status: status === 'ATIVADO' ? false : true,
      nome_tipo: type,
    });

    fetch(
      `${AppState.serverAddr}/projetos/ChangeProjectTypeStatus.php`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: AppState.token,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            var error = new Error(response.status);
            error.response = json;
            throw error;
          });
        }

        const res = response.json();
        return res;
        AppState.isLoading = false;
      })
      .then((response) => {
        AppState.isLoading = false;
      })
      .catch(function (error) {
        AppState.isLoading = false;
        if (error.toString() == '' || error.toString().split(' ').length < 2) {
          alert('Erro desconhecido, tente novamente');
          return;
        }
        var Err_status = error.toString().split(' ')[1];
        if (Err_status == 400) {
          var ret = error.response['status'].value.split(' ')[0];
          if (ret == '40001' || ret == '40002' || ret == '40004') {
            console.log('ERRO dados inválidos');
            alert('Dados inválidos');
          } else {
            alert('Dados inválidos');
          }
        } else if (Err_status == 401) {
          browserHistory.push(`${process.env.PUBLIC_URL}/logout`);
        } else if (Err_status == 500) {
          alert('Erro desconhecido, tente novamente');
        }
      });
    this.getTableData();
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  deleteType = (tipo) => {
    const tiposDeProjetoResult = this.state.tiposDeProjeto.filter(
      (item) => item.tipo_projeto != tipo
    );
    const novosTiposResult = this.state.novosTipos.filter(
      (item) => item.tipo_projeto != tipo
    );

    this.setState({
      tiposDeProjeto: tiposDeProjetoResult,
      novosTipos: novosTiposResult,
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeDialog}
        disableBackdropClick={true}
        fullScreen
      >
        <Grid container style={{ padding: '16px 24px' }}>
          <Grid container>
            <Grid
              item
              sm={10}
              container
              direction="row"
              justify="space-between"
              style={{
                maxWidth: '100%',
                flexGrow: '1',
                flexBasis: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Typography variant="h4">Tipo de Projeto</Typography>
              <Icon onClick={() => this.closeDialog()}>close</Icon>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={'center'}
            style={{ marginTop: '16px' }}
            spacing={32}
          >
            <Grid item sm={8}>
              <TextField
                margin="dense"
                value={this.state.tipo}
                error={this.state.tipoProjetoErro}
                label="Tipo de Projeto"
                onChange={this.handleChange('tipo')}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item sm={4}>
              <Button
                style={{ marginTop: '16px' }}
                fullWidth
                color="primary"
                variant="outlined"
                onClick={this.validaForm}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>

          <ReactTable
            filterable
            fullWidth
            style={{ marginTop: '16px', width: '100%' }}
            data={this.state.tiposDeProjeto}
            defaultPageSize={5}
            showPageSizeOptions={false}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            }
            columns={[
              {
                columns: [
                  {
                    Header: 'Nome do Tipo',
                    accessor: 'tipo_projeto',
                    Cell: (row) => (
                      <div
                        style={{
                          top: '30%',
                          textAlign: 'center',
                          position: 'relative',
                        }}
                      >
                        {row.value}
                      </div>
                    ),
                  },

                  {
                    Header: 'Habilitado',
                    accessor: 'ativo',
                    width: 96,
                    filterable: false,
                    Cell: (row) => (
                      <div className="action-cell">
                        {
                          <Switch
                            onChange={() =>
                              this.changeTypeStatus(
                                row.original['tipo_projeto'],
                                row.value === 't' ? 'ATIVADO' : 'DESATIVADO'
                              )
                            }
                            checked={row.value === 't'}
                            color="primary"
                            disabled={row.original['deletavel']}
                          />
                        }
                      </div>
                    ),
                  },
                  {
                    Header: 'Deletar',
                    accessor: 'ativo',
                    width: 80,
                    filterable: false,
                    Cell: (row) => (
                      <div className="action-cell">
                        {row.original['deletavel'] === true && (
                          <DeleteIcon
                            onClick={() =>
                              this.deleteType(row.original['tipo_projeto'])
                            }
                            style={{
                              cursor: 'pointer',
                              width: '22px',
                              marginTop: '15%',
                              marginLeft: '30%',
                              AlignItems: 'center',
                            }}
                          />
                        )}
                      </div>
                    ),
                  },
                ],
              },
            ]}
          />

          <Grid container justify={'center'} style={{ margin: '16px 0 0 0' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={this.validaTipos}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

FormTipoProjeto.propTypes = {};

export default FormTipoProjeto;
